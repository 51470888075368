.address-book-container {
  background-color: #f0f3f8;

  .address-book-loading-circular {
    width: 80px !important;
    height: 80px !important;
    display: block;
    margin: 40px auto;
    svg {
      circle {
        stroke-width: 2;
        stroke: #143A72;
      }
    }
  }
  .address-book-header {
    display: flex;
    padding: 12px 12px;
    background-color: white;
    border-bottom: 2px solid #f0f3f8;
    img {
      width: 24px;
      height: 28px;
      margin-right: 12px;
    }
    p {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      color: #171a21;
    }
  }
  .address-book-main {
    padding: 0 12px;

    position: relative;

    .address-book-add-new-item {
      position: fixed;
      bottom: 16px;
      right: 16px;
      border-radius: 100%;
      overflow: hidden;
      width: 48px;
      height: 48px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .address-layout-5-middle {
    display: flex;
    padding: 12px 12px;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    margin-top: 8px;

    margin-left: -12px;
    margin-right: -12px;
    .address-layout-5-middle-button {
      background-color: white;
      outline: none;
      border: none;
      img {
        width: 24px;
        height: 24px;
      }
    }

    span {
      font-size: 14px;
      font-weight: 700;
      color: #464646;
    }
  }
  .address-layout-5-bottom {
    &:disabled {
      background-color: #d8d8d8;
    }
    background-color: #97144D;
    border-radius: 24px;
    text-align: center;
    width: 90%;
    padding: 12px 0;
    color: white;
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin: 24px auto;
  }
  .edit-address-no-add-layout {
    background-color: white;
    height: 100vh;
    button {
      background-color: #97144D;
      color: white;
      font-size: 20px;
      font-weight: 600;
      padding: 12px 0;
      width: 80%;
      border-radius: 25px;
      display: block;
      margin: 0 auto;
    }
    .edit-address-no-add-img {
      width: 100%;
      height: 400px;
      object-fit: contain;
      padding: 0 12px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #777;
      margin-top: 64px;
      margin-bottom: 8px;
      text-align: center;
    }
  }
}
.address-remove-dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  padding: 0 32px;

  background-color: rgba(38, 40, 77, 0.3);
  display: flex;
  align-items: center;
  .address-remove-dialog-content {
    background-color: white;
    border-radius: 8px;
    // margin-top: 200px;
    padding: 12px;
    .address-remove-dialog-top {
      font-size: 14px;
      font-weight: 600;
      color: #171a21;
    }
    .address-remove-dialog-mid {
      font-size: 14px;
      font-weight: 400;
      color: #171a21;
    }
    .address-remove-dialog-bottom {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .address-remove-dialog-bottom-cancel {
        outline: none;
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
        color: #777;
        margin-right: 8px;
      }
      .address-remove-dialog-bottom-remove {
        outline: none;
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
        color: #97144D;
        margin-right: 8px;
      }
    }
  }
}

.edit-address-container {
  width: 100%;
  position: relative;
  background-color: white;
  .edit-address-header {
    padding: 12px;
    border-bottom: 2px solid #d8d8d8;
    .edit-address-header-backIMG {
      width: 28px;
      height: 28px;
    }
  }
  .edit-address-layout-1-container {
    padding: 8px 12px;
    .edit-address-layout-1-section-1 {
      display: flex;
      justify-content: space-between;
      position: relative;
      .edit-address-layout-1-section-1-options {
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        div {
          width: 4px;
          height: 4px;
          border-radius: 100%;
          background-color: #0d1c2e;
        }
      }
      .edit-address-open-import {
        position: absolute;
        right: 6px;
        bottom: -30px;
        background-color: white;
        border: 1px solid #f0effe;
        padding: 10px 12px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        button {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          outline: none;
          border: none;
          background-color: transparent;
          color: #97144D;
        }
      }
    }
    .edit-address-layout-1-section-2 {
      label {
        font-size: 12px;
        font-weight: 400;
        color: #171a21;
      }
      .edit-address-section-2-options {
        display: flex;
        margin-bottom: 8px;
        margin-top: 8px;
        .edit-address-section-2-option-ele {
          border-radius: 25px;
          border: 1px solid #777;
          padding: 6px 10px;
          text-align: center;
          margin-right: 8px;
          flex-wrap: wrap;
          span {
            font-size: 18px;
            font-weight: 300;
            color: #777;
          }
        }
        .option-selected {
          background-color: #143A72 !important;
          border: none;
          span {
            color: white !important;
          }
        }
      }
    }
    .edit-address-general-section {
      display: flex;
      border-bottom: 1px solid #d8d8d8;
      flex-direction: column;
      margin-bottom: 12px;
      label {
        font-size: 12px;
        font-weight: 400;
        color: #171a21;
        margin-bottom: 8px;
        span {
          color: #97144D !important;
        }
      }
      input {
        border: none;
        outline: none;
        padding-bottom: 4px;
        width: 100%;
        font-size: 18px;
        font-weight: 400;
      }
      .edit-address-section-select {
        background-color: transparent !important;
        display: flex;
        align-items: center;
        .edit-address-section-select-country-code {
          margin: 0;
          font-size: 18px;
          font-weight: 400;
          padding-bottom: 4px;
        }
        // .MuiSelect-selectMenu {
        //   display: flex;
        //   align-items: center;
        //   margin-right: 8px;
        //   border-bottom: none;
        //   img {
        //     width: 34px;
        //     height: 22px;
        //     margin-right: 8px;
        //   }
        //   span {
        //     margin-right: 8px;
        //   }
        // }
        // .MuiInput-underline::after,
        // ::before {
        //   content: "";
        //   border-bottom: none;
        //   background-color: transparent;
        // }
        // .MuiInput-underline {
        //   .MuiSvgIcon-root {
        //     padding-left: 8px;
        //   }
        // }
        // .edit-address-section-select-mui .Mui-focused {
        //   border-bottom: none;
        //   background-color: transparent;
        // }
      }

      // .edit-address-section-select::before {
      //   border-bottom: none;
      //   background-color: transparent;
      // }
      // .edit-address-section-select::after {
      //   border-bottom: none;
      //   background-color: transparent;
      // }
    }
    .edit-address-general-section-grouped {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 12px;
    }
    .edit-address-save-button {
      background-color: #97144D;
      width: 90%;
      margin-top: 24px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      &:disabled {
        background-color: #d8d8d8 !important;
      }
    }
  }
  .edit-address-layout-2-container {
    background-color: #f0f3f8;
    height: 100%;
    overflow: scroll;
    .edit-address-layout-2-main {
      background-color: #f0f3f8;
      padding: 0 12px;
    }
    .edit-address-layout-2-button {
      background-color: #97144D;
      padding: 12px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: white;

      display: block;
      width: 90%;
      margin: 32px auto;
    }
  }
  .MuiCircularProgress-root {
    width: 80px !important;
    height: 80px !important;
    display: block;
    margin: 40px auto;
    svg {
      circle {
        stroke-width: 2;
        stroke: #143A72;
      }
    }
  }
}

.editAddressf16 {
  font-size: 16px;
  font-weight: 500;
  color: #171a21;
}

.address-book-element {
  padding: 12px 12px;
  margin-top: 8px;
  background-color: white;
  margin-left: -12px;
  margin-right: -12px;
  .address-book-element-top {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 8px;

    .address-book-element-top-type {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }

    .address-book-element-top-name {
      font-size: 14px;
      font-weight: 700;
      color: #171a21;
      flex: 1;
      margin: 0;
    }
    .address-book-element-top-threeIcon {
      display: flex;

      width: 24px;
      justify-content: space-around;
      cursor: pointer;
      .edit-address-import-checkobx-img {
        width: 24px;
        height: 24px;
      }
    }
    .address-book-element-top-threeIcon-layout-1 {
      display: flex;

      width: 24px;
      justify-content: space-around;
      cursor: pointer;
      div {
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: #0d1c2e;
      }
    }
    .address-book-open-edit-box {
      position: absolute;
      right: 4px;
      bottom: 0;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      background-color: white;
      display: flex;

      flex-direction: column;
      align-items: flex-start;
      padding: 8px 8px;
      z-index: 10;
      transform: translateY(100%);
      button {
        outline: none;
        border: none;
        background-color: transparent;
        color: #97144D;
        margin: 4px 0;
      }
    }
  }
  .address-book-element-bottom {
    padding-left: 32px;
    padding-right: 12px;
    p {
      margin: 8px 0 0 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #171a21;
    }
  }
}

.error-box-general {
  background-color: #97144D;
  padding: 4px;
  text-align: center;
  position: fixed;
  top: var(--ion-safe-area-top);
  width: 100%;
  max-width: 560px;
  span {
    color: white;
  }
}

.edit-add-address-overview-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  .family-profile-overview-circular-progress {
    width: 80px !important;
    height: 80px !important;
    display: flex !important;

    svg {
      circle {
        stroke-width: 2;
        stroke: #143A72;
      }
    }
  }
}

.address-book-ion-content-class {
  --background: #f0f3f8;
  height: 100%;
}
.required-mark-class {
  color: #97144D;
}
