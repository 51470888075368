div.common-message-content {
  text-align: center;
  background: var(--main-cornflower);
  color: var(--main-white);
  border-radius: 4px;

  transition: all .1s linear;
}

div.message-body {
  padding: 15px;
  border-bottom: 1px solid var(--main-white);
}

div.message-button {
  padding: 10px;
}

.hide-common-message {
  transition: all .1s linear;
  height: 0px !important;
}