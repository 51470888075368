.message-date {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .message-date-value {
    padding-left: 10px;
    padding-right: 10px;
  }

  .message-date-row {
    border-top: 1px solid gainsboro;
    flex: 1;
    display: flex;
  }
}

.message-load {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;

  button {
    border-radius: 10px;
    background-color: #ffffffcf;
    color: #143A72;
    font-weight: 500;
    border: 1px solid #143A72;
    font-size: 13px;
    outline: none;

    .ripple-wrapper {
      padding: 5px 20px;
    }
  }
}

.message {
  margin-bottom: 15px;
  width: 80%;
  max-width: 300px;
}

.message-left .message-text {
  background-color: white;
  a {
    color: var(--main-cornflower);
  }
}

.message-right .message-text {
  background-color: #143A72;
  border-radius: 16px 0px 16px 16px;

  color: var(--main-white);
  a {
    color: var(--main-white);
  }
}

div.bor.message-text {
  border-radius: 16px;
  color: var(--main-text-light);
}

.message .message-text {
  display: inline-block;
  // margin-left: 7px;
  white-space: pre-wrap;
}

.message-time {
  font-size: 9px;
  padding-left: 10px;
}

.break-line {
  line-height: 5px;
}

.message-from {
  min-width: 20px;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-left: 10px;
}

.message-text-wrapper {
  display: inline-flex;
  border: none;
  outline: none;
}

div.message-text {
  word-break: break-word;
  padding: 10px 12px;
  text-align: left;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}

img.zyla-logo-card {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  margin-left: 10px;
}

div.warning-message {
  display: flex;
  justify-content: center;
  width: 100%;

  div.msg-content {
    padding: 0px 5px;
  }
}

.fix-date {
  padding-top: 8px;
}

img.message-status-icon {
  height: 14px;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, -15px, 0);
  }
}

.button-bubble-bounce {
  animation: bounce 0.25s;
  animation-direction: alternate;
  animation-iteration-count: 6;
}

div.button-bubble {
  display: inline-flex;
  padding: 10px 15px;
  border: 1px solid var(--main-cornflower);
  border-radius: 35px;
  color: var(--main-text-light);
  margin: 5px;
  height: 39px;
  white-space: nowrap;
  line-height: 1rem;
  background: none;
}
// div.button-bubble:last-child:nth-child(odd) {
//   grid-column: auto / span 2;
// }

img.partial-menu {
  height: 32px;
}

.height-testing {
  height: 100% !important;
}

.height-testing-2 {
  height: calc(
    100% - 60px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
  ) !important;
}

.chat-input {
  height: 60px;
  width: 100%;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  background: #f0f3f8;
  // margin-top: 10px;
  // // border-top: 1px solid var(--grey-border);
}

.input-lock-wrapper {
  display: flex;
  height: 40px;
  flex: 1;
}

.input-lock-form {
  display: inherit;
  flex: 1;
  // border: 1px solid var(--grey-border);
  border-radius: 17.5px;
  background-color: var(--main-white);
  margin-left: 10px;
  margin-right: 0px;
  padding-left: 10px;
  padding-right: 10px;
}
.input-lock-form-disabled {
  background-color: #143A7236;
  border: 1px solid #143A7236;
}

.chat-input-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-input {
  textarea {
    outline: none;
    width: 100%;
    background-color: #f0f3f8;
    resize: none;
    border: none;
    padding-top: 7px;
    height: 32px;
    border-radius: 6px;
    @media (min-width: #{320px}) {
      font-size: 13px;
    }
  }

  textarea.extra-padding-lock {
    padding-left: 16px;
    background-color: white;
    font-size: 16px;
    padding-top: 10px;
  }

  textarea:disabled {
    background-color: #e2e4fe;
    outline: none;
    resize: none;
  }

  textarea:focus {
    outline: none;
  }
}

ion-header {
  height: 55px;
  width: 100%;
  display: flex;
  background: white;
  font-size: 18px;
  font-weight: 900;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
  .header-div {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    height: 100%;
    span#zyla-text {
      display: inline-flex;
      height: 28px;
      align-items: flex-end;
    }
  }
  .request-callback-div {
    display: flex;
    align-items: center;
    width: 30%;
    justify-content: flex-end;
    img {
      height: 28px;
      width: 28px;
      margin-right: 20px;
    }
    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #97144D;
    }
  }
}
.chatroom__ion__header.header-md::after {
  background-image: none !important;
}

.care-team-section {
  display: inline-flex;
  width: 135%;
  flex-direction: column;
  position: absolute;
  // left: 0;
  top: 55px;
  background: white;
  z-index: 100001;

  span {
    font-size: 16px;
    font-weight: 500;
    padding: 1rem;
    display: inline-flex;
    img {
      height: 20px;
      margin-left: 1rem;
    }
  }
  .doctor-card-wrapper {
    margin-top: 15px;

    padding: 0 0.5rem 1rem 0;
    display: inline-flex;
    flex-flow: row;
    overflow: scroll;
    width: 100vw;

    .doctor-cards {
      display: flex;
      flex-flow: column;
      min-width: 140px;
      background: #fff;
      color: #464646;
      background: #ffffff;
      border-radius: 4px;
      /* Inside Auto Layout */
      margin-left: 8px;
      margin-right: 8px;
      text-align: center;
      height: 185px;
      overflow: hidden;
      border: 1px solid #8276f4;
      padding: 7px 0;
      max-width: 140px;
      .dr-card-wrapper {
        padding: 5px 7px;
        overflow: hidden;
        border-bottom: 0.5rem solid white;
        div {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          img.doctor-image {
            height: 50px;
            width: 50px;
            display: inline-flex;
            border-radius: 10000px;
            margin-bottom: 3px;
            margin-left: 8px;
          }
          div {
            flex-direction: column;
            margin-left: 0.5rem;
            justify-content: center;
            h3,
            p {
              font-weight: 700;
              font-size: 12px;
              line-height: 12px;
              /* identical to box height */
              margin: 0;
            }
            h3 {
              padding: 6px;
            }
            p {
              font-size: 10px;
              line-height: 8px;
              font-weight: 600;
            }
          }
        }
        p {
          font-size: 6px;
          font-weight: 300;
          line-height: 8px;
          padding: 0 2px;
          color: #464646;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}
.fullscreen-dr-card {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: white;

  margin-top: calc(var(--ion-safe-area-top) + 10px) !important;
  .fs-doctor-cards {
    height: 100%;
    button {
      background-color: none;
      outline: none;
      img {
        height: 40px;
        position: absolute;
        left: 16px;
        top: 16px;
      }
    }
    .fs-dr-card-wrapper {
      margin-top: var(--ion-safe-area-top) !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* height: 100%; */
      text-align: center;
      padding: 15% 16px;
      div {
        // color: black;
        .fs-doctor-image {
          height: 140px;
          border-radius: 50%;
        }
        div {
          h3 {
            font-weight: 400;
            color: #4e4e4e;
          }
          p {
            color: var(--main-orange);
            font-weight: 600;
            font-size: 18px;
          }
        }
        p {
        }
      }
    }
  }
}
.height-100 {
  height: 100% !important;
}

.doc__img__wrapper {
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doc__img__wrapper img {
  height: 140px;
  width: 140px;
  border-radius: 100%;
  object-fit: cover;
}

.chat__room__opendoc__desc {
  padding: 10px;
  text-align: center;
}

.chat__room__opendoc__title {
  color: var(--main-orange) !important;
  font-weight: 600 !important;
}

.chat-window-error-message {
  margin-top: 55px;
  z-index: 10000001 !important;
  background-color: #d0021b !important;
  width: 100%;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;

  .MuiSnackbarContent-root {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    border: none;

    .MuiSnackbarContent-message {
      font-size: 12px;
      margin: 0 !important;
      padding: 0;
    }
  }
}

.chatRoom__drawer .MuiPaper-root {
  height: 100% !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.doctor-cards:last-child {
  margin-right: 9% !important;
}

.icon-z-index {
  z-index: 12 !important;
  width: 28px;
  transition: all;
  margin-left: 2px;
}

.rotate-icon {
  transform: rotate(180deg);
}

.hide {
  display: none !important;
  visibility: hidden !important;
}

.doc-info-flex-class {
  flex: 1.5;
}
@media (max-width: 320px) {
  .care-team-section {
    span {
      font-size: 14px;
    }
  }
}

@media (max-width: 280px) {
  .care-team-section {
    span {
      font-size: 12px;
    }
  }
}

.share-msg-spinner .MuiPaper-root {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: relative !important;
  display: block !important;
  ion-spinner {
    width: 100px;
    height: 100px;
    color: #143A72;
    margin: auto;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
}
.chat-room-tour {
  // -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  .MuiPaper-root {
    width: 100%;
    height: 100%;

    background-color: transparent;
    box-shadow: none;

    .chat-tour-stage-1 {
      width: 100%;
      height: 100%;
      background-image: url("../../assets/images/tourImages/left-semi.png");
      background-size: contain;
      background-position-y: bottom;
      position: relative;
      background-repeat: no-repeat;
      background-position-x: center;
      // bottom: -10px;
      .tour-chat-box-icon {
        width: 150px;
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      .tour-chat-box-icon-home {
        width: 150px;
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .tour-chat-arrow {
        position: absolute;
        left: 5%;
        bottom: calc(12% + 60px);
        height: 150px;
      }
      .tour-chat-box {
        position: absolute;
        bottom: calc(0% + 60px);
        left: 0%;
        display: flex;
        align-items: center;
        max-height: 76px;
        width: 100%;
        .tour-chat-box-left {
          width: 40px;
          height: 40px;
          margin: 5% 0 5% 5%;
        }
        .tour-chat-box-right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: white;
          border-radius: 25px;
          color: #d8d8d8;
          font-size: 12px;
          flex: 1;
          padding: 10px 15px;
          margin: 5px 15px 5px 5px;
          img {
            width: 26px;
            height: 24px;
            margin-left: 15px;
          }
        }
      }
      .chat-tour-content-box {
        color: white;
        position: absolute;
        right: 0;
        bottom: 136px;
        max-width: 65%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 15px 0 10px;
        .chat-tour-content-box-heading {
          display: block;
          font-weight: 700;
          font-size: 25px;
        }
        .chat-tour-content-body {
          font-size: 18px;
          word-wrap: break-word;
          line-height: 22px;
        }
        .chat-tour-next-button {
          font-size: 18px;
          font-weight: 700;
          color: white;
          border-radius: 4px;
          width: fit-content;
          padding: 10px 20px;
          text-align: center;
          margin-left: auto;
          margin-top: 10px;
          text-decoration: underline;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
      }
    }
  }
  .chat-tour-stage-2 {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/tourImages/right-semi.png");
    background-size: contain;
    background-position-y: bottom;
    position: relative;
    background-repeat: no-repeat;
    background-position-x: center;
    // bottom: -10px;
    .background-img {
      width: 100%;
      height: 80%;
    }
    .tour-chat-box-icon {
      width: 150px;
      height: 60px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .tour-chat-arrow {
      position: absolute;
      left: 31%;
      bottom: calc(0% + 83px);
      height: 118px;
    }

    .chat-tour-content-box {
      color: white;
      position: absolute;
      left: 20px;
      bottom: 160px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 15px 0 10px;
      .chat-tour-content-box-heading {
        display: block;
        font-weight: 700;
        font-size: 25px;
        margin-bottom: 10px;
      }
      .chat-tour-content-body {
        font-size: 18px;
        word-wrap: break-word;
        max-width: 80%;
        line-height: 30px;
      }
      .chat-tour-next-button-home {
        padding-top: 10px;
        width: 100%;
        display: flex;

        .chat-tour-next-button-retour {
          background-color: transparent;
          padding: 10px 20px 10px 0;
          text-decoration: underline;
          font-size: 18px;
          color: white;
          margin-left: auto;
          z-index: 1000;
        }
        .chat-tour-next-button {
          background-color: transparent;
          padding: 10px 20px 10px 0;
          text-decoration: underline;
          font-size: 18px;
          color: white;
          margin-left: 40px;
          z-index: 1000;
        }
      }
    }
  }
  .chat-tour-stage-3 {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/tourImages/left-semi.png");
    background-size: contain;
    background-position-y: bottom;
    position: relative;
    background-repeat: no-repeat;
    background-position-x: center;
    // bottom: -10px;

    .tour-chat-box-icon {
      width: 150px;
      height: 60px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .tour-chat-box-icon-home {
      width: 150px;
      height: 60px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .tour-chat-arrow {
      position: absolute;
      left: 7%;
      bottom: calc(10% + 60px);
      height: 185px;
    }
    .tour-chat-box {
      position: absolute;
      bottom: calc(0% + 60px);
      left: 0%;
      display: flex;
      align-items: center;
      max-height: 76px;
      width: 100%;
      .tour-chat-box-left {
        width: 40px;
        height: 40px;
        margin: 5%;
      }
      .tour-chat-box-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        border-radius: 25px;
        color: #d8d8d8;
        font-size: 12px;
        flex: 1;
        padding: 10px 15px;
        margin: 5px 15px;
        img {
          width: 30px;
          height: 30px;
          margin-left: 15px;
        }
      }
    }
    .chat-tour-content-box {
      color: white;
      position: absolute;
      right: 0;
      bottom: 136px;
      max-width: 65%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 15px 0 10px;
      .chat-tour-content-box-heading {
        display: block;
        font-weight: 700;
        font-size: 25px;
        margin-bottom: 10px;
      }
      .chat-tour-content-body {
        font-size: 18px;
        word-wrap: break-word;
        line-height: 21px;
      }
      .chat-tour-next-button-home {
        padding-top: 10px;
        width: 100%;
        display: flex;
      }
      .chat-tour-next-button {
        background-color: transparent;
        padding: 10px 20px 10px 0;
        text-decoration: underline;
        text-align: right;
        font-size: 18px;
        color: white;
        margin-left: auto;
      }
    }
  }
  .show-tour-slide-main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    .show-tour-slide-container {
      background-color: #143A72;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 10px;
      text-align: center;
      padding: 25px 15px;
      color: white;
      .show-tour-slide-container-box-1 {
        min-height: 75px;
        .first-yellow {
          width: 55px;
          height: 55px;
          position: absolute;
          top: 5%;
          left: 10%;
        }
        .second-yellow {
          width: 45px;
          height: 45px;
          position: absolute;
          top: 2%;
          right: 5%;
        }
      }
      .show-tour-slide-container-box-4 {
        text-decoration: underline;
        line-height: 24px;
        margin-top: 10px;
      }
      .show-tour-slide-container-box-2 {
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
        p {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        span {
          font-size: 18px;
          font-weight: 400;
          padding-bottom: 15px;
        }
      }
      .show-tour-slide-container-box-3 {
        display: flex;
        align-items: center;
        background-color: #97144D;
        border-radius: 25px;
        padding: 10px 15px;
        margin: 10px 0;
        span {
          flex: 1;
          font-size: 20px;
          font-weight: 700;
          color: white;
        }
        img {
          padding: 0 0 0 15px;
        }
      }
    }
  }
}

.backdrop-white-class {
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }
}

@media (max-width: 320px) {
  .chat-tour-summary {
    font-size: 12px !important;
  }
  .chat-tour-heading-320 {
    margin-bottom: 0 !important;
    font-size: 22px !important;
  }
  .chat-tour-stage-2 {
    .tour-chat-arrow {
      left: 10% !important;
    }
  }
}

.document-choose-upload-class {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  h3 {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 4px;
  }
}

.custom-card-error-message {
  bottom: 72px !important;
}
