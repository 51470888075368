.diabetes-container {
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(243, 244, 246, 1);
  width: 100%;
  .diabetes-container-box-1 {
    background-color: white;
    .diabetes-container-box-1-top {
      .main-img {
        width: 100%;
        height: 225px;
      }
    }
    .diabetes-container-box-1-middle {
      padding: 15px;
      .diabetes-container-box-1-top-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        img {
          height: 44px;
          width: 44px;
        }
        span {
          color: #143A72;
          font-size: 18px;
          font-weight: 700;
          margin-left: 15px;
        }
      }
      .diabetes-container-box-1-middle-ddp {
        h2 {
          font-weight: 600;
          font-size: 1.875rem;
          line-height: 2.25rem;
        }
        p {
          font-size: 16px;
          color: rgba(75, 85, 99, 1);
          font-weight: 500;
        }
      }
      .diabetes-container-box-1-middle-list {
        h2 {
          font-size: 1.25rem;
          font-weight: 600;
          padding: 15px 0 5px 0;
        }
        .diabetes-container-box-1-middle-list-conatiner {
          margin-left: 15px;
          font-weight: 600;
          font-size: 16px;
          padding: 10px 0;
          ol {
            padding: 0;
            margin: 0;
            li {
              padding: 0px 0px 10px 0;
            }
          }
        }
      }
      .diabetes-container-box-1-button {
        font-size: 16px;
        font-weight: 600;
        color: white;
        width: 100%;
        padding: 15px;
        margin: auto;
        background-color: #97144D;
        text-align: center;
        border-radius: 5px;
      }
      .diabetes-container-box-1-button-2 {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid gray;
        border-radius: 5px;
        img {
          margin-right: 15px;
          width: 16px;
          height: 16px;
        }
        span {
          font-weight: 600;
          color: black;
          font-size: 16px;
          padding: 15px;
        }
      }
    }
  }
  .diabetes-container-box-2 {
    background-color: white;
    margin: 10px 0;
    padding: 5px 10px 30px 20px;
    .diabetes-container-box-2-heading {
      font-weight: 700;

      font-size: 1.25rem;
      line-height: 1.75rem;
      padding: 1rem 0;
      margin: 0;
    }
    .diabetes-container-box-2-doctor {
      display: flex !important;
      margin: 0 15px;
      .diabetes-container-box-2-doctor-img {
        margin-right: 10px;
        display: flex;
        align-items: flex-start;

        img {
          width: 64px !important;
          min-width: 64px;
          height: 64px !important;
          border-radius: 5px;
        }
      }
      .diabetes-container-box-2-doctor-desc {
        display: flex !important;
        flex-direction: column;
        flex: 0.7;
        .doctor-desc-name {
          font-weight: 700;
          display: inline;
          font-size: 14px;
        }
        .doctor-desc-meta {
          font-size: 0.875rem;
          line-height: 1.25rem;
          display: inline;
        }
        .doctor-desc-exp {
          font-weight: 700;
          display: inline;
          font-size: 12px;
        }
      }
    }
    .doctor-slider-box-2 {
      .slick-next {
        right: -5px !important;
      }
      .slick-prev {
        left: -10px !important;
      }
      .slick-next::before {
        color: #97144D;
      }
      .slick-prev::before {
        color: #97144D;
      }
    }
  }
  .diabetes-container-box-3 {
    background-color: white;
    padding: 15px 15px 20px 15px;
  }
  .diabetes-container-box-5 {
    padding: 15px 15px 30px 15px;
    .diabetes-container-box-5-heading {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 700;
    }
    .patient-slider-box-5 {
      .slick-prev::before {
        color: #97144D;
      }
      .slick-next::before {
        color: #97144D;
      }
      .slick-next {
        right: -5px !important;
      }
      .slick-prev {
        left: -10px !important;
      }
      .patient-slide-container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        .patient-slide-top {
          line-height: 1.75rem;
          font-size: 16px;
          color: rgb(107, 114, 128);
        }
        .patient-slide-container-bottom {
          display: flex;
          margin: 10px 0;
          align-items: center;
          img {
            width: 64px;
            height: 64px;
            min-width: 64px;
            border-radius: 5px;
          }
          span {
            font-size: 14px;
            font-weight: 700;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .diabetes-container-box-4 {
    background-color: white;
    padding: 15px 15px 30px 15px;
    color: black;
    .diabetes-container-box-4-heading {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2rem;
      padding-top: 1.5rem;
      padding-bottom: 0.75rem;
      margin: 0;
      color: black;
    }
    .diabetes-container-box-4-inner {
      padding: 15px;
      box-shadow: 0px 9px 19px -14px grey;

      border: 1px solid rgba(229, 231, 235, 1);
      .diabetes-container-box-4-inner-heading-1 {
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.75rem;
      }
      .diabetes-container-box-4-inner-heading-2 {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.75rem;
        margin: 0.5rem 0;
      }
      .diabetes-container-box-4-inner-button {
        background-color: #97144D;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        .inner-button-span-1 {
          font-weight: 700;
        }
        .inner-button-span-2 {
          font-weight: 300;
          font-size: 8px;
          color: #f8cb99;
        }
      }
      .diabetes-container-box-4-inner-list {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .diabetes-container-box-4-inner-list-list {
          ul {
            list-style: none;
            margin-left: 10px;
            margin-bottom: 4px;
            li {
              margin-bottom: 5px;
              p {
                margin: 0;
                font-size: 16px;
                color: rgba(31, 41, 55, 1);
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
  .diabetes-container-box-7 {
    background-color: rgb(31, 41, 55);
    padding: 2rem 1rem;
    p {
      font-size: 1.25rem;
      font-weight: 700;
      color: white;
      line-height: 1.75rem;
    }
    .diabetes-container-box-7-button {
      font-size: 16px;
      font-weight: 600;
      color: white;
      width: 100%;
      padding: 15px;
      margin: auto;
      background-color: #97144D;
      text-align: center;
      border-radius: 5px;
    }
    .diabetes-container-box-7-button-2 {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid gray;
      border-radius: 5px;
      img {
        margin-right: 15px;
        width: 16px;
        height: 16px;
      }
      span {
        font-weight: 600;
        color: black;
        font-size: 16px;
        padding: 15px;
      }
    }
  }
  .diabetes-container-covid-special-msg {
    background-color: rgba(31, 41, 55, 1);
    color: white;
    padding: 2rem 1rem 4rem 1rem;
    .diabetes-container-covid-special-msg-heading {
      font-weight: 700;
      font-size: 1.25rem;
    }
    .diabetes-container-covid-special-msg-desc {
      font-weight: 500;
      font-size: 1.125rem;
      color: rgba(188, 190, 195, 1);
    }
    .diabetes-container-covid-special-msg-phone {
      line-height: 2rem;
      a {
        background-color: rgba(243, 244, 246, 1);
        color: black;
        text-decoration: none;
        padding: 0.25rem;
      }
    }
  }
  .diabetes-conatiner-box-6 {
    background-color: white;
    padding: 15px 15px 30px 15px;
    color: black;
    .diabetes-conatiner-box-6-heading {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.75rem;
      padding: 0.5rem 0;
    }
    .diabetes-conatiner-box-6-main {
      display: flex;
      overflow-x: scroll;

      .blog-container {
        text-decoration: none;
        color: black;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 16rem;
        min-width: 16rem;
        margin-right: 20px;
        img {
          border-radius: 8px;
        }
        h2 {
          font-size: 18px;
          margin: 0;
          padding: 10px 0;
        }
        p {
          font-size: 16px;
          margin: 0;
          line-height: 1.25rem;
          color: rgba(75, 85, 99, 1) !important;
          font-weight: 500;
        }
      }
    }
  }
}

.diabetes-faq-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .diabetes-faq-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;

    h2 {
      font-size: 14px;
      font-weight: 600;
    }
    .down-chevron {
      width: 18px;
      height: 20px;
      transform: rotate(180deg);
    }
  }
  .diabetes-faq-content {
    ul {
      list-style: none;
      padding: 0;
    }
  }
}

.check-container-css {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  p {
    margin: 0;
    font-size: 16px;
    color: rgba(31, 41, 55, 1);
    font-weight: 500;
  }
  img {
    margin-right: 10px;
  }
}

.diabetes-payment-modal {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;

  justify-content: center;
  align-items: center;
  .paymentDialog-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px;
    img {
      width: 48px;
      height: 48px;
    }
    .paymentDialog-container-span-1 {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
    .paymentDialog-container-span-2 {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      text-align: center;

      color: #464646;
    }
    .paymentDialog-container-span-3 {
      font-size: 14px;
      line-height: 20px;

      text-align: center;

      color: #464646;
    }
    .paymentDialog-container-button {
      position: absolute;
      bottom: 0;
      background-color: #97144D;
      color: white;
      font-weight: 600;
      text-align: center;
      padding: 15px;
      width: 100%;
    }
  }
}

.women-health-page-container {
  .women-health-container-box-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    img {
      height: 44px;
      width: 44px;
    }
    span {
      color: #143A72;
      font-size: 18px;
      font-weight: 700;
      margin-left: 15px;
    }
  }
  .women-health-container-box-2 {
    background-color: #e4e3e1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    .women-health-container-box-2-top {
      padding: 1rem;
      font-size: 3rem;
      font-weight: 700;
      color: rgba(94, 56, 243, 1);
      text-align: center;
      margin: 0;
    }
    .women-health-container-box-2-bottom {
      background-color: #f64c71;
      color: white;
      text-align: center;
      padding: 10px 0;
      border-radius: 0.5rem;
      margin-bottom: 20px;
      width: 100%;
      font-size: 1.5rem;
    }
  }
  .women-health-container-box-3 {
    img {
      object-fit: cover;
      height: 500px;
      width: 100%;
    }
  }
  .women-health-container-box-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    .women-health-container-box-4-heading {
      color: #f64c71;
      font-weight: 700;
      font-size: 2.25rem;

      text-align: center;
    }
    .women-health-container-box-4-desc {
      text-align: center;
      margin-top: 1rem;
      line-height: 2rem;
      font-size: 1rem;
      color: rgba(107, 114, 128, 1);
      font-weight: 500;
    }
    .women-health-container-box-4-price {
      border: 1px solid #f64c71;
      color: #f64c71;

      text-align: center;
      width: 100%;
      border-radius: 0.5rem;
      font-size: 1.5rem;
    }
    .womens-health-container-box-4-payment {
      background-color: #5a41bb;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      padding: 15px 20px;
      width: 50%;

      margin-top: 1.5rem;
      .inner-button-span-1 {
        font-weight: 700;
        font-style: italic;
      }
      .inner-button-span-2 {
        font-weight: 300;
        font-size: 8px;
        color: #c0b7e5;
      }
    }
  }
  .womens-health-container-box-5 {
    background-color: #ffc7d6;
    padding: 10px 15px;
    .womens-health-container-box-5-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .womens-health-container-box-5-inner-heading {
        text-align: center;
        color: #f64c71;
        font-weight: 700;
        font-size: 2.25rem;
      }
      .womens-health-container-box-5-inner-boxes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        .womens-health-container-box-5-inner-boxes-single {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: white;
          width: 100%;
          margin: 15px 0;
          padding: 20px 0;
          border-radius: 0.25rem;
          h2 {
            color: #f64c71;
            font-weight: 700;
            font-size: 1.125rem;
          }
        }
      }
      .womens-health-container-box-5-link {
        background-color: #f64c71;
        text-decoration: none;
        color: white;
        cursor: pointer;
        font-size: 1.5rem;
        padding: 10px;
        width: 100%;
        text-align: center;
        border-radius: 0.5rem;
        margin: 20px 0;
      }
    }
  }
  .womens-health-container-box-6 {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .womens-health-container-box-6-heading {
      color: #f64c71;
      font-size: 2.25rem;
      font-weight: 700;
    }
    .womens-health-container-box-6-content {
      .womens-health-pillar-box {
        margin: 20px auto;
        width: 90%;
        border-bottom-right-radius: 1.5rem;
        .womens-health-pillar-box-top {
          background-color: #f64c71;
          padding-top: 1rem;
          padding-bottom: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .womens-health-pillar-box-bottom {
          padding: 15px;
          h3 {
            color: #f64c71;
            font-size: 1.25rem;
            font-weight: 700;
          }
          ul {
            li {
              color: rgba(75, 85, 99, 1);
              padding: 10px 0;
            }
          }
        }
      }
    }
  }
  .womens-health-box-7-container {
    background-color: #eceae9;
    padding: 10px;
    .womens-health-box-7-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .womens-health-box-7-inner-heading {
        color: #f64c71;
        font-size: 2.25rem;
        font-weight: 700;
        text-align: center;
      }
      .womens-health-box-7-inner-content {
        .womens-health-container-box-7-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 1rem;
          .womens-health-container-box-7-card-img {
            object-fit: cover;
            width: 120px;
            height: 120px;
            border-radius: 100%;
          }
          .womens-health-container-box-7-card-heading {
            color: #f64c71;
            font-weight: 700;
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 0;
          }
          .womens-health-container-box-7-card-subheading {
            color: rgba(107, 114, 128, 1);
            font-size: 1.125rem;
            margin-top: 5px;
            margin-bottom: 0.5rem;
            text-align: center;
          }
          .womens-health-container-box-7-card-desc {
            color: rgba(31, 41, 55, 1);
            font-weight: 500;
            font-size: 14px;
            margin-top: 0.1em;
            text-align: center;
            padding: 0 10px;
          }
        }
      }
    }
  }
  .womens-health-faq {
    margin-bottom: 20px;
    .womens-health-faq-heading {
      background-color: #f64c71;
      color: white;
      padding: 30px 0;
      text-align: center;
    }
    .womens-health-faq-inner {
      padding: 10px 15px;
    }
  }
  .womens-health-box-8 {
    padding: 25px;
    .womens-health-box-8-inner {
      background-color: #f64c71;
      width: 100%;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 15px;
      height: 250px;
      .womens-health-box-8-inner-iframe {
        width: 100%;
        height: 100%;
      }
    }
    .womens-health-box-8-inner-callback {
      margin: 25px 0 0 0;
      .womens-health-callback-button {
        font-size: 16px;
        font-weight: 600;
        color: white;
        width: 100%;
        padding: 15px;
        margin: auto;
        background-color: #f64c71;
        text-align: center;
        border-radius: 5px;
      }
      .womens-health-callback-button-2 {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid gray;
        border-radius: 5px;
        img {
          margin-right: 15px;
          width: 16px;
          height: 16px;
        }
        span {
          font-weight: 600;
          color: black;
          font-size: 16px;
          padding: 15px;
        }
      }
    }
  }
}
