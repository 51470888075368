body {
  .error_div {
    text-align: center;
    display: flex;
    flex-flow: column;
    place-items: center;
    height: inherit;
    margin-top: 40%;
    padding: 10%;
    .error_heading {
      font-size: 30px;
      text-align: center;
      font-weight: 700;
      margin-top: 10%;
    }

    .error_text {
      font-size: 20px;
      text-align: center;
      margin-top: 7%;
      font-weight: 500;
    }

    .homepage {
      display: flex;
      font-size: 21px;
      padding: 10px 20px;
      background: #4caf50;
      border-radius: 15px/15px;
      font-weight: 700;
      color: white;
      margin-top: 20%;
    }
  }
}
