.message-paytm {
  position: absolute;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  //   top: 70%;
  text-align: center;

  color: #000000;
}
.alert-box > div.modal-wrapper {
  position: absolute;
  width: 20rem;
  height: 12rem;
  background: #ffffff;
  border-radius: 16px;
}
