/* *{
  outline: 1px red solid;
} */
div.health-plan-container {
  height: calc(100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
}

div.hp-layout-head {
  display: flex;
  justify-content: space-between;
  color: var(--main-white);
  padding: 20px 20px 0px 20px;
}

div.hp-head-content {
  display: flex;
  background: var(--main-cornflower);
  height: 100%;
  flex-flow: column;
}

div.hp-smaller-header {
  display: none;
  background: var(--main-cornflower);
  place-items: center;
  position: fixed;
  top: 0;
  // top: var(--ion-safe-area-top);
  width: 100%;
  z-index: 10;
}

.fix-category {
}

.header-text {
  font-weight: 600;
  display: inline-flex;
  color: white;
  font-size: 18px;
}

p.header-text {
  margin-left: 1%;
}

span.header-text {
  margin: auto 0;
}

img.hp-back-arrow {
  height: 38px;
  width: 45px;
  padding: 10px;
}

img.hp-layout-logo {
  height: 130px;
  width: auto;
}

.item-hp-head {
  margin-bottom: 5px;
  margin-left: -2px;
}

div.current-hp-level {
  border-radius: 4px;
  position: relative;
  top: 5%;
  // box-shadow: 0px 10px 20px 0px var(--grey-border);
  padding: 10px;
  background: var(--main-white);
  margin: 20px 25px;
  .current-hp-level-top {
    display: flex;
    .current-hp-level-top-first {
      font-size: 20px;
      font-weight: 600;
      flex: 1;
    }
    .healthplan-guideline-button-level-3 {
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #97144D;
      color: white;
      border-radius: 25px;
      span {
        font-size: 10px;
        font-weight: 700;
      }
      img {
        width: 10px;
        height: 10px;
        margin-right: 4px;
      }
    }
  }
  .current-hp-plan-child {
    display: flex;
    align-items: flex-start;
  }
}

div.hp-body {
  padding: 10px 20px 20px 20px;
  background: var(--secondary-grey);
  width: 100%;

  ion-slides {
    min-height: calc(100vh - 290px);
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

div.category-slider {
  background: var(--main-white);
  border-radius: 4px;
  height: 100%;
  width: 93%;
  box-shadow: 0px 7px 5px 0px var(--grey-border);
  margin: 8px;
  overflow: hidden;
  margin-left: 0;
  border: 0.5px solid #c4c4c447;

  .source-container-div {
    display: flex;
    padding: 10px;
    align-items: baseline;

    .source-container-heading {
      font-size: 12px;
      font-weight: 400;
      margin-right: 3px;
    }
    .source-container-links {
      text-align: left;
      line-height: 12px;
      a {
        text-decoration: underline;
        color: skyblue;
        margin-right: 4px;
        font-size: 9px;
      }
      span {
        font-size: 9px;
        font-weight: 400;
      }
      .exercise-text {
        margin-left: -3px;
      }
    }
  }
}

.category-head {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey-border);
  cursor: pointer;
  .category-head-left {
    display: flex;
    align-items: center;
    padding: 10px 15px;

    width: 100%;
    background: white;
    flex: 1;
  }
  .category-head-right {
    background-color: #97144D;
    margin-right: 12px;
    border-radius: 12px;
    padding: 4px 8px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 10px;
      font-weight: 700;
    }
    img {
      margin-right: 2px;
      width: 12px;
      height: 12px;
    }
  }
}

img.hp-icon {
  height: 40px;
  margin-right: 10px;
}

div.hp-category-name {
  text-transform: capitalize;
}

div.hp-body-slider {
  padding: 10px;
  overflow: auto;
  padding-bottom: 20px;
  max-height: 100vh;
}

div.hp-category-fields {
  text-align: left;
}

div.hp-category-options {
  display: flex;
}

div.see-all {
  color: var(--main-orange);
}

img.hp-options-img {
  height: 100px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  object-fit: cover;
}

div.hp-options-card {
  margin: 0px 10px 10px 0px;
  width: 100px;
  max-width: 100px;
  box-shadow: 0px 10px 20px 0px var(--grey-border);
  border-radius: 4px;
}

div.hp-option-name {
  padding: 10px 0px;
}

div.hp-option-time {
  font-size: 12px;
  margin-left: 0px;
  background-color: #143A72;
  display: inline-block;
  padding: 3px 7px;
  color: white;
  margin-bottom: 10px;
  border-radius: 2px;
  font-weight: 500;
}

.hp-option-time > img {
  width: 10px;
}

.hp-image-desc {
  padding: 5px;
  // height: 38px;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  overflow: hidden;
}

div.see-all-hp-options {
  margin: auto;
}

div.slide-hp {
  // height: calc(100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
  width: 100%;
  .slide-hp-inner-div {
    .slide-hp-info-box {
      display: flex;
      .slide-hp-info-box-time {
        padding: 4px 6px;
        margin-right: 4px;
        margin-bottom: 0 !important;
        height: fit-content;
        font-size: 16px !important;
        vertical-align: middle;
        background-color: #143A72;
        font-weight: 500;
        color: white;
        display: flex;
        align-items: center;
        border-radius: 4px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }
      }
    }
  }
}

img.option-hp-img {
  max-height: 250px;
}

div.hp-data-option {
  text-align: left;
  width: 100%;
  padding: 10px;
  .slide-hp-recipe-container {
    overflow-x: scroll;
    display: flex;
    margin-top: 12px;
    .slide-hp-recipe-box {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.107478);
      border-radius: 4px;

      display: flex;
      flex-direction: column;
      background-color: white;
      margin: 4px 4px;
      max-width: 80px;
      img {
        width: 100%;
        height: 72px;
        width: 80px;
        max-height: 72px;
        object-fit: cover;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #464646;
        padding: 2px 4px 8px 2px;
      }
    }
  }
}

div.hp-description {
  max-height: 50vh;
  overflow: auto;
}

div.hp-slide-head {
  opacity: 0.9;
  position: absolute;
  z-index: 3;
  width: 100%;
  padding: 10px;
  color: var(--main-white);
  background-image: linear-gradient(black, transparent);
  /* background-image: linear-gradient(black 1%,transparent 99%) !important; */
}

div.slide-category-head {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  margin-right: -6px;
}

img.slide-hp-Icon {
  height: 30px;
  margin-right: 10px;
}

div.slide-data {
  display: flex;
  align-items: center;
}

img.close-hp-icon {
  height: 45px;
  padding: 10px;
}

div.image-slide-body {
  height: calc(100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
  display: flex;
  justify-content: center;
  flex-direction: column;
}

div.img-tag {
  height: 0px;
  width: fit-content;
  text-align: left;
  margin-left: 10px;
  position: relative;
  bottom: 50px;
}

div.tag-name-hp {
  background: #97144D;
  color: var(--main-white);
  border-radius: 4px;
  padding: 3px 6px;
  height: fit-content;
}

div.indicators-hp {
  display: flex;
  justify-content: space-between;
}

div.indicate-hp {
  flex: 1;
  background: var(--grey-border);
  height: 6px;
  margin: 0px 4px;
  border-radius: 4px;
  opacity: 0.3;
}

div.indicate-hp.activated-hp {
  background: var(--main-white);
  opacity: 1;
}

div.complete-task-btn {
  color: var(--main-orange);
  text-align: center;
}

div.complete-container {
  position: fixed;
  bottom: var(--ion-safe-area-bottom);
  left: 0;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: var(--main-white);
  width: 100%;
}

div.swipe-screen {
  z-index: 200;
}

img.common-img {
  height: 50px;
  max-width: 50px;
  position: relative;
  bottom: 50px;
  float: right;
}

img.common-img-small {
  height: 65px;
}

div#hp-unavailable {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  height: 70vh;
  padding: 15% 10% 0;
}

.health-plan-level-container {
  background-color: var(--main-cornflower);
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  .health-plan-level-header {
    padding: 15px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: var(--main-cornflower);

    img {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
    span {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .health-plan-level-details {
    background-image: url("../../assets//images/hplevelback.png");
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity 0.3s ease-out;
    transition: height 4s ease-out;
    transition: flex 2s ease-out;
    flex: 1;
  }

  .health-plan-level-detail-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;

    span {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }

    .level {
      font-size: 32px;
      font-weight: 500;
    }
    .number {
      font-size: 125px;

      font-weight: 500;
    }
    .level-message {
      font-size: 16px;
      text-align: center;
      padding: 0 10px;
    }
    .next-level-button {
      padding: 10px 15px;
      background-color: #e57c01;
      font-weight: 600;
      text-align: center;
      border-radius: 25px;
      margin: 15px auto;
      width: 60%;
    }
  }
}
.health-plan-level-list {
  background-color: white;
  display: flex;
  flex-direction: column;

  .health-plan-level-list-container {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    border-bottom: 1px solid #d8d8d8;
    padding: 5px;
    .health-plan-level-list-container-top {
      display: flex;
      margin-bottom: 5px;
      img {
        width: 20px;
        height: 20px;
      }

      .health-plan-level-list-container-top-left {
        display: flex;
        align-items: center;
        flex: 1;
        .premium-tag {
          color: white;
          background-color: #143A72;
          font-size: 8px;
          padding: 2px 4px;
          width: fit-content;
          margin-left: 5px;
          border-radius: 25px;
        }
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .health-plan-level-list-container-bottom {
    font-size: 14px;
    color: #d8d8d8;
    font-weight: 600;
  }
}

.health-plan-level-drawer .MuiDrawer-paperAnchorBottom {
  height: 100% !important;
}

.health-plan-header-dynamic {
  flex-direction: column !important;
  align-items: flex-start !important;

  margin-top: 15px;
}

.minor-div {
  width: 50px;
  min-height: 3px;
  background-color: #d8d8d8;
  border-radius: 25px;
  margin: 0 auto;
}
.wrapper {
  display: flex;
  flex-direction: column;
  .hp-body {
    flex: 1;
  }
}

.healthplan-foodlist-container {
  background-color: #f6f6f6;
  height: 100%;
  overflow: scroll;
  .healthplan-foodlist-container-header-1 {
    position: relative;
    background-color: #143A72;
    display: flex;
    flex-direction: column;
    color: white;
    padding: 12px;
    z-index: 5;
    // position: sticky;
    top: 0;
    .healthplan-foodlist-container-header-1-top {
      display: flex;
      flex-direction: column;
      .healthplan-foodlist-header-back-img {
        width: 28px;
        height: 28px;
        margin-bottom: 10px;
      }
      .healthplan-foodlist-header-1-span-1 {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .healthplan-foodlist-container-header-1-top-2 {
      flex-direction: row;
      align-items: center;
      .healthplan-foodlist-header-back-img {
        margin-right: 10px;
      }
    }
    .healthplan-foodlist-header-1-span-2 {
      max-width: 50%;
      margin-bottom: 10px;
    }
    .healthplan-foodlist-header-search-bar {
      display: flex;
      align-items: center;
      background-color: white;
      width: 100%;
      margin: 0 auto;
      border-radius: 4px;
      padding: 2px 8px;
      input {
        outline: none;
        border: none;
        flex: 1;
        color: black;
        margin-left: 8px;
      }
      .healthplan-foodlist-header-search-bar-icon-1 {
        width: 28px;
        height: 28px;
      }
      .healthplan-foodlist-header-search-bar-icon-2 {
        width: 14px;
        height: 14px;
      }
    }
    .healthplan-foodlist-header-1-food-img {
      position: absolute;
      top: 13%;
      right: 5%;
      width: 128px;
      height: 128px;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .healthplan-foodlist-header-buttons {
      margin-top: 2px;
      .foodlist-header-buttons-box {
        .foodlist-ion-segment {
          .foodlist-ion-segment-tab {
            color: #ffffff;
            border: none;
            font-size: 16px;
            border-radius: 5px;
            font-weight: 500;

            min-height: fit-content !important;
            --background-checked: #97144D;
            --color-checked: transparent;
            --color-hover: white;
            // --padding-start: 16px;
            // --padding-end: 16px;
            // --margin-start: 8px;
            // --margin-end: 8px;
            text-transform: none;
            .foodlist-ion-segment-label {
              margin: 8px 0;
            }
          }
          .foodlist-ion-segment-tab:hover {
            ion-label {
              color: white !important;
            }
          }
        }
      }
    }
  }

  .healthplan-foodlist-no-content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 30px auto;
    text-align: center;
    img {
      width: 150px;
      height: 150px;
    }
    .foodlist-search-no-food-found {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .foodlist-search-no-food-found-desc {
      font-size: 14px;
      font-weight: 400;
      max-width: 60%;
    }
  }
  .foodlist-main-content {
    .food-list-item-container {
      margin-top: 8px;
      .food-list-item-box {
        background-color: white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 12px;
        .food-list-item-box-left {
          flex: 1;
          display: flex;
          flex-direction: column;
          .food-list-item-box-left-top {
            margin-bottom: 8px;

            span {
              font-size: 16px;
              font-weight: 700;
              color: #464646;
            }
          }
          .food-list-item-box-left-bottom {
            display: flex;
            align-items: center;
            img {
              margin-right: 8px;
              width: 24px;
              height: 24px;
            }
            span {
              font-size: 12px;
              font-weight: 500;
              color: #464646;
            }
          }
        }
        .food-list-item-box-right {
          font-size: 14px;
          font-weight: 700;
          color: #143A72;
        }
      }
    }
    .foodlist-main-content-spinner-class {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding-top: 56px;
      p {
        color: #d8d8d8;
        text-align: center;
        font-size: 28px;
        margin-top: 0;
      }
      .MuiCircularProgress-root {
        width: 80px !important;
        height: 80px !important;
        svg {
          circle {
            stroke-width: 2;
            stroke: #143A72;
          }
        }
      }
    }
  }
}
.healthplan-guideline-container {
  position: relative;
  // top: var(--ion-safe-area-top);
  .healthplan-guideline-header {
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .healthplan-guideline-header-right {
      flex: 1;
      display: flex;
      padding-left: 20px;
      align-items: center;
      span {
        flex: 1;
        font-size: 20px;
        font-weight: 600;
        color: #464646;
      }
      img {
        width: 28px;
        height: 28px;
      }
    }
    .healthplan-guideline-back-button {
      width: 28px;
      height: 28px;
    }
  }
  .healthplan-guideline-main-content {
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 16px 12px;
    gap: 16px;
    .healthplan-guideline-main-content-element {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      border: 0.5px solid rgba(70, 70, 70, 0.2);
      span {
        color: #464646;
        font-size: 18px;
        font-weight: 700;
        padding: 10px;
      }
      img {
        object-fit: cover;
        max-height: 150px;
      }
    }
    .no-guidelines-available-section {
      display: flex;
      align-items: center;
      text-align: center;
      grid-column-start: 1;
      grid-column-end: 3;
      justify-content: center;
      p {
        color: #d8d8d8;
        font-size: 28px;
      }
    }
  }
  .guideline-swipe-sheet {
    position: fixed;
    top: 0px;
    bottom: var(--ion-safe-area-bottom);
    width: 100%;
    height: 100%;
    z-index: 6;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column-reverse;
    max-width: 560px;

    .guideline-sheet-bottom {
      padding: 28px 24px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: white;
      display: flex;
      flex-direction: column;
      .guideline-sheet-bottom-heading {
        font-size: 20px;
        font-weight: 600;
        color: #464646;
        margin: 0px 4px 0 0;
      }
      .guideline-sheet-bottom-desc {
        font-size: 16px;
        font-weight: 400;
        color: #464646;
        margin: 4px 0;
      }
      .guideline-sheet-bottom-button {
        margin-top: 10px;
        margin-left: auto;
        button {
          background-color: #97144D;
          color: white;
          border-radius: 16px;
          padding: 8px 9px;
          width: fit-content;
          outline: none;
          border: none;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .open-guidelines-slide-container {
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: left 0.3s linear 0s;
  }

  .guidelines_drawer_ion_slides {
    width: 100% !important;
    height: 100vh;
    .swiper-pagination {
      position: absolute;
      top: 10px;
      bottom: 0 !important;
      height: fit-content !important;
      display: flex;
      justify-content: space-between;
      .swiper-pagination-bullet {
        flex: 1;
        height: 4px;
        border-radius: 2px;
      }
      .swiper-pagination-bullet-active {
        background: white;
      }
    }
  }
  .guidelines_drawer_container {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    text-align: left;
    // &::-webkit-scrollbar {
    //   width: 10px;
    //   height: 0px;
    // }
    .guidelines_drawer_header {
      position: sticky;
      left: 0;
      width: 100%;
      top: 0;
      height: 330px;
      z-index: 50;
      background-color: white;
      .guidelines_drawer_header_img {
        width: 100%;
        height: 330px;
        min-height: 330px;
        max-height: 330px;
      }
      .guidelines-close-icon {
        position: absolute;
        top: 20px;
        right: 10px;
        width: 26px;
        height: 26px;
        z-index: 100;
      }
      .guidelines_drawer_header_timing {
        position: absolute;
        bottom: 20%;
        left: 10px;
        color: white;
        background-color: #143A72;
        width: fit-content;
        padding: 5px 10px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        img {
          margin-right: 7px;
        }
      }
    }

    .guidelines_drawer_main {
      padding: 20px 16px 40px 16px;
      margin-bottom: 30px;
      // height: 100vh;
      // overflow-y:auto;
      background-color: white;
      position: relative;
      .guidelines_name_div {
        background-color: white;
        height: 55px;

        .guidelines_name_div_top {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          div {
            font-size: 20px;
            font-weight: 600;
            margin-right: 15px;
          }
        }
        .guidelines_name_div_bottom {
          width: 50px;
          height: 5px;
          border-radius: 22px;
          margin: 15px 0 0 0;
          background-color: #97144D;
        }
      }

      .guidelines_drawer_main_ingredients {
        .guidelines_drawer_main_ingredients_header {
          display: flex;
          flex-direction: column;
          justify-content: left;
          .guidelines_drawer_main_ingredients_header_top {
            color: #464646;
            font-weight: 500;
            b {
              font-weight: 600;
            }
          }
        }
        .guidelines_drawer_list {
          margin-top: 2px;

          padding-top: 4px;
          padding-left: 10px;
          ol {
            margin-top: 0;
            padding-left: 12px;
            li {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: #464646;
            }
          }

          span {
            margin: 4px 0;
            font-size: 16px;
            color: #464646;
            font-weight: 500;
          }
        }
      }
    }
    .guidelines_drawer_main_methods {
      .method_drawer_list {
        font-size: 16px;
        font-weight: 300;
        color: #464646;
        margin-top: 2px;
      }
      li {
        margin-bottom: 2px;
      }
    }
  }
}
.healthplan-recipe-slider-second-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: white;

  .healthplan-recipe-slider-second {
    background-color: white;

    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
    .healthplan-slider-2-ion-slides {
      width: 100%;
      height: 100%;
      .healthplan-slider-2-ion-slide {
        width: 100%;
        height: 100%;
        .healthpan-2-slider-container {
          width: 100%;
          height: 100%;
          position: relative;
          .healthplan-2-slider-close-parent {
            position: absolute;
            top: 5%;
            right: 5%;
            z-index: 41;
            .healthplan-2-slider-close {
              width: 24px;
              height: 24px;
            }
          }
          .healthplan-2-slider-info-box {
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;
            padding: 28px 0;
            width: 100%;
            z-index: 41;
            .healthplan-2-slider-info-top {
              font-size: 32px;
              font-weight: 700;
              padding: 0 12px;
              margin: 0 0 12px 0;
            }
            .healthplan-2-slider-info-box-mid {
              display: flex;
              justify-content: space-between;
              margin-bottom: 12px;
              span {
                font-size: 14px;
                font-weight: 500;
                padding: 0 12px;
              }
              .healthplan-2-slider-info-box-mid-span-2 {
                border-left: 1px solid rgba(255, 255, 255, 0.3);
                border-right: 1px solid rgba(255, 255, 255, 0.3);
              }
            }
            .healthplan-2-slider-info-box-bottom {
              color: #97144D;
              display: flex;
              align-items: center;
              .healthplan-2-slider-info-box-bottom-span1 {
                font-size: 16px;
                font-weight: 700;
              }
              .healthplan-2-slider-info-box-bottom-span2 {
                // font-size: 16px;
                // transform: rotate(90deg);
                height: 24px;
                width: 30px;
                font-weight: 700;
              }
            }
          }
          .healthpan-2-slider-container-top {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            .healthpan-2-slider-container-top-main-img {
              width: 100%;
              height: 100%;
              object-fit: contain;

              z-index: 35;
            }
            .healthplan-2-slider-container-top-back-img {
              object-fit: cover;
              position: absolute;
              top: 0;
              left: 0;
              isolation: isolate;
              height: 100%;
              z-index: -1;
              filter: blur(11px);
            }
          }
        }
      }
      .swiper-pagination.swiper-pagination-bullets {
        top: 10px;
        height: fit-content;
        display: flex;

        align-items: center;
        justify-content: center;
        z-index: 30;
        .swiper-pagination-bullet {
          flex: 1;
          border-radius: 8px;
          height: 4px;
        }
        .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background-color: white;
        }
      }
    }
    .health-current-slide-details {
      background-color: red;
      position: absolute;
      top: 0;
      z-index: 40;
      width: 100%;
      height: 100%;
    }
    .healthplan-slider-2-details-container {
      position: absolute;
      bottom: -100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.3);
      z-index: 45;
      height: 100%;
      transition: bottom 0.3s linear 0s;

      .healthplan-slider-2-details-main-box {
        position: absolute;
        bottom: 0;
        background-color: white;
        height: 95%;
        width: 100%;
        z-index: 50;
        padding: 16px 16px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        overflow-y: scroll;
        .healthplan-slider-2-top {
          .healthplan-slider-2-details-main-box-header {
            display: flex;
            align-items: center;
            .healthplan-slider-2-details-main-box-header-left {
              display: flex;
              align-items: center;
              flex: 1;
              span {
                margin-right: 12px;
                color: #464646;
                font-size: 20px;
                font-weight: 600;
              }
              img {
                width: 20px;
                height: 19px;
                margin-right: 10px;
              }
            }
            .healthplan-slider-2-details-main-box-header-right {
              padding-right: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 14px;
                height: 14px;
              }
            }
          }
          .healthplan-slider-2-details-divider {
            width: 40px;
            height: 4px;
            border-radius: 8px;
            background-color: #97144D;
            margin-left: 0;
          }
        }
        .healthplan-slider-2-mid {
          display: flex;
          .healthplan-slider-2-details-mid-section-1 {
            display: flex;
            flex-direction: column;
            .healthplan-slider-2-details-mid-section-1-heading {
              margin-top: 0;
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 5px;
            }

            .healthplan-slider-2-details-mid-section-1-content {
              display: flex;
              overflow-x: scroll;
              .healthplan-slider-2-details-midsection-1-content-box {
                display: flex;
                flex-direction: column;
                margin: 5px 10px 5px 0;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.107478);
                border-radius: 4px;
                max-width: 100px;
                // word-break: ;
                span {
                  padding: 10px;
                  font-size: 12px;
                }
                img {
                  object-fit: cover;
                  height: 72px;
                  width: 80px;
                  max-height: 72px;
                  width: 100%;
                }
              }
            }
          }
        }
        .healthplan-slider-2-bottom {
          .healthplan-slider-2-bottom-first {
            color: #464646;
            display: flex;
            flex-direction: column;
            margin-top: 12px;
            .healthplan-slider-2-bottom-first-first {
              font-weight: 700;
              font-size: 16px;
              margin-bottom: 8px;
            }
            .healthplan-slider-2-bottom-first-last {
              font-size: 16px;
              font-weight: 500;
              span {
                line-height: 26px;
              }
            }
          }
          .healthplan-slider-2-bottom-last {
            display: flex;
            flex-direction: column;
            color: #464646;
            margin-top: 12px;
            .healthplan-slider-2-bottom-last-first {
              font-size: 16px;
              font-weight: 700;
            }
            .healthplan-slider-2-bottom-last-last {
              display: flex;
              flex-direction: column;
              ol {
                padding-left: 20px;
                margin-top: 6px;
                li {
                  color: #464646;
                  font-weight: 500;
                  line-height: 26px;
                }
              }
              .healthplan-slider-2-bottom-last-last-ele {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                font-size: 16px;
                font-weight: 500;
                color: #464646;
                margin: 2px 0;
              }
            }
          }
        }
      }
    }
    .healthplan-slider-2-backdrop {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 34;
      background-color: rgba(255, 255, 255, 0.3);
    }
    .bring-slider-up {
      bottom: 0;
    }
  }
}

.healthplan-recipe-slider-third {
  background-color: white;

  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
  .healthplan-slider-3-ion-slides {
    width: 100%;
    height: 100%;
    .healthplan-slider-3-ion-slide {
      width: 100%;
      height: 100%;
      .healthpan-3-slider-container {
        width: 100%;
        height: 100%;
        position: relative;
        .healthplan-3-slider-close-parent {
          position: absolute;
          top: 5%;
          right: 5%;
          z-index: 41;
          .healthplan-3-slider-close {
            width: 24px;
            height: 24px;
          }
        }
        .healthplan-3-slider-info-box {
          position: absolute;
          bottom: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: white;
          padding: 28px 0;
          width: 100%;
          z-index: 41;
          .healthplan-3-slider-info-top {
            font-size: 32px;
            font-weight: 700;
            padding: 0 12px;
            margin: 0 0 12px 0;
          }
          .healthplan-3-slider-info-box-mid {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            span {
              font-size: 14px;
              font-weight: 500;
              padding: 0 12px;
            }
            .healthplan-3-slider-info-box-mid-span-2 {
              border-left: 1px solid rgba(255, 255, 255, 0.3);
              border-right: 1px solid rgba(255, 255, 255, 0.3);
            }
          }
          .healthplan-3-slider-info-box-bottom {
            color: #97144D;
            display: flex;
            align-items: center;
            .healthplan-3-slider-info-box-bottom-span1 {
              font-size: 16px;
              font-weight: 700;
            }
            .healthplan-3-slider-info-box-bottom-span2 {
              // font-size: 16px;
              // transform: rotate(90deg);
              height: 24px;
              width: 30px;
              font-weight: 700;
            }
          }
        }
        .healthpan-3-slider-container-top {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;
          .healthpan-3-slider-container-top-main-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            /* filter: blur(10px); */
            z-index: 35;
          }
          .healthplan-3-slider-container-top-back-img {
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            isolation: isolate;
            height: 100%;
            z-index: -1;
            filter: blur(11px);
          }
        }
        .healthplan-slider-3-bottom {
          .healthplan-slider-3-bottom-first {
            color: #464646;
            display: flex;
            flex-direction: column;
            margin-top: 12px;
            margin-bottom: 8px;
            .healthplan-slider-3-bottom-first-first {
              font-weight: 700;
              font-size: 16px;
              margin-bottom: 8px;
            }
            .healthplan-slider-3-bottom-first-last {
              font-size: 16px;
              font-weight: 500;
            }
          }
          .healthplan-slider-3-bottom-last {
            display: flex;
            flex-direction: column;
            color: #464646;
            margin-top: 12px;
            .healthplan-slider-3-bottom-last-first {
              font-size: 16px;
              font-weight: 700;
            }
            .healthplan-slider-3-bottom-last-last {
              display: flex;
              flex-direction: column;
              .healthplan-slider-3-bottom-last-last-ele {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                font-size: 16px;
                font-weight: 500;
                color: #464646;
                margin: 2px 0;
              }
            }
          }
        }
      }
    }
    .swiper-pagination.swiper-pagination-bullets {
      top: 10px;
      height: fit-content;
      display: flex;

      align-items: center;
      justify-content: center;
      z-index: 30;
      .swiper-pagination-bullet {
        flex: 1;
        border-radius: 8px;
        height: 4px;
      }
      .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: white;
      }
    }
  }
  .health-current-slide-details {
    background-color: red;
    position: absolute;
    top: 0;
    z-index: 40;
    width: 100%;
    height: 100%;
  }
  .healthplan-slider-3-details-container {
    position: absolute;
    bottom: -100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 45;
    height: 100%;
    transition: bottom 0.3s linear 0s;

    .healthplan-slider-3-details-main-box {
      position: absolute;
      bottom: 0;
      background-color: white;
      height: 95%;
      width: 100%;
      z-index: 50;
      padding: 16px 16px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow-y: scroll;
      .healthplan-slider-3-top {
        .healthplan-slider-3-details-main-box-header {
          display: flex;
          align-items: flex-start;
          .healthplan-slider-3-details-main-box-header-left {
            display: flex;
            align-items: center;
            flex: 1;
            span {
              margin-right: 12px;
              color: #464646;
              font-size: 20px;
              font-weight: 600;
            }
            img {
              width: 20px;
              height: 19px;
              margin-right: 10px;
            }
          }
          .healthplan-slider-3-details-main-box-header-right {
            padding-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 14px;
              height: 14px;
            }
          }
        }
        .healthplan-slider-3-details-divider {
          width: 40px;
          height: 4px;
          border-radius: 8px;
          background-color: #97144D;
          margin-left: 0;
        }
      }
      .healthplan-slider-3-mid {
        .healthplan-slider-3-details-mid-section-1 {
          .healthplan-slider-3-details-midsection-1-content {
            display: flex;
            overflow-x: scroll;
            .healthplan-slider-3-details-midsection-1-content-box {
              display: flex;
              flex-direction: column;
              margin: 5px 10px;
              box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.107478);
              border-radius: 4px;
              span {
                padding: 10px;
              }
              img {
                object-fit: cover;
                max-height: 72px;
                width: 80px;
              }
            }
          }
        }
      }
      .healthplan-slider-3-bottom {
        .healthplan-slider-3-bottom-first {
          color: #464646;
          display: flex;
          flex-direction: column;
          margin-top: 12px;
          .healthplan-slider-3-bottom-first-first {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 8px;
          }
          .healthplan-slider-3-bottom-first-last {
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            color: #464646;
          }
        }
        .healthplan-slider-3-bottom-last {
          display: flex;
          flex-direction: column;
          color: #464646;
          margin-top: 12px;
          .healthplan-slider-3-bottom-last-first {
            font-size: 16px;
            font-weight: 700;
          }
          .healthplan-slider-3-bottom-last-last {
            display: flex;
            flex-direction: column;
            padding-top: 4px;
            ol {
              padding-left: 16px;
              margin-top: 0;
              li {
                font-size: 16px;
                font-weight: 500;
                color: #464646;
                line-height: 26px;
              }
            }
            .healthplan-slider-3-bottom-last-last-ele {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              font-size: 16px;
              font-weight: 500;
              color: #464646;
              margin: 2px 0;
            }
          }
        }
      }
    }
  }
  .healthplan-slider-3-backdrop {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 43;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .bring-slider-up {
    bottom: 0;
  }
}
.common-slider-bottom-gradient {
  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(0, 0, 0, 0) 14.97%,
    rgba(0, 0, 0, 0) 55.08%,
    rgba(0, 0, 0, 0.84) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
