.webview-container {
  display: flex;
  flex-direction: column;
  header {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;
    z-index: 9999;
    background: white;
    div.back-button-wrapper {
      min-width: 50px;
      height: 40px;
      padding: 10px;
      img.back-arrow {
        padding-left: 10px;
        margin-top: 10px;
        height: 18px;
      }
    }
    .view-title {
      overflow: hidden;
      display: flex;
      font-weight: 500;
      font-size: 1.1rem;
      text-overflow: ellipsis;
    }
  }
  main {
    height: calc(90vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: dimgrey;
    .react-transform-component {
      overflow: inherit !important;
      .TransformComponent-module_content__TZU5O {
        display: inherit !important;
      }
    }
    video {
      width: 100%;
    }
    iframe {
      width: 100%;
      height: calc(100vh - 60px - var(--ion-safe-area-bottom));
    }
  }
}

.ion-modal-top-bottom-class {
  top: var(--ion-safe-area-top) !important;
  bottom: var(--ion-safe-area-bottom) !important;
}

.download-button-icon {
  background-color: transparent;
  margin-left: auto;
  svg {
    width: 24px;
    height: 24px;
    color: var(--primary-black) !important;
  }
}

.webview-download-msg-div {
  padding: 4px;
  text-align: center;
  position: absolute;
  top: 62px;
  z-index: 9999999;
  min-height: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 5px;

  p {
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
}

.wave-animation-webview {
  position: relative;

  text-align: center;

  .wave-animation-dot {
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-right: 3px;
    background: white;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-10px);
  }
}
