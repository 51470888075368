div.detail-screen {
  padding: 20px 12px 0 12px;
  display: flex;
  flex-direction: column;
  background-color: #f0f3f8;
}

div.detail-head {
  margin-right: -15px;
  // margin-bottom: 120px;
}

div.detail-foot {
  padding: 0px 15px 0px 15px;
  position: fixed;
  bottom: 7%;
  left: 0;
  right: 0;
  --safe-area-inset-bottom: env(safe-area-inset-bottom);

  background-color: white;

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

div.register-bottom {
  text-align: center;
  margin-left: -10px;
  margin-right: -10px;
  padding-top: 10px;
  padding-bottom: 10px;

  border-bottom: 1px solid #f1f1f1;

  a {
    font-size: 11px;
    text-decoration: none;
    color: var(--main-text-light);
  }

  span {
    font-size: 11px;
  }

  span.mini-bold {
    font-weight: 600;
  }
}

img.item-icon {
  height: 20px;
}

img.next-item-icon {
  height: 25px;
  margin-right: 15px;
}

div.detail-item {
  display: flex;
  align-items: center;
  /* padding: 10px 0px; */
}

div.item-icon-image {
  min-width: 30px;
  display: flex;
  align-items: center;
}

div.menu-area {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
  padding: 14px 10px 14px 0px;
}

a.dt-foot,
div.dt-foot {
  padding: 10px 0px;
  text-align: center;
  flex: 1;
}

img.close-img {
  height: 15px;
}

div.name-details {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 16px;
}

div.details-plan {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

div.health-detail-content {
  padding: 12px;
  border-radius: 4px;
  width: 48%;
  background-color: white;
  .subheading {
    font-size: 0.6rem;
    color: #777777;
  }
}

.goal-color {
  color: var(--main-cornflower);
  color: #143A72;
}

.plan-color {
  color: #97144D;
}

div.head-det-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// ion-header {
//   background: white;
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 4.5rem;
// }

div.name-image {
  display: flex;
  align-items: center;
}

.profile-name {
  padding-left: 16px;
  text-transform: capitalize;
}

// .MuiDialog-root {
//   .MuiButton-textPrimary {
//     color: var(--main-orange);
//   }
// }

.golden-div {
  background-color: #ffffff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 700;
  padding: 0.5rem 1rem;
  display: flex;
  margin-top: 0.75rem;
  img {
    height: 30px;
    margin-left: auto;
  }
  span {
    font-size: 8px;
    height: 17px;
    display: flex;
    align-items: center;
  }
}
.premium-container-screen {
  display: flex;
  align-items: center;
}
.gradient-text {
  background: linear-gradient(
    94.33deg,
    #a6743b 19.66%,
    #cfa44f 66.8%,
    #e4c780 108.38%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: 22px;
}
.small-badge {
  padding: 0 0.3rem;
  width: max-content;
  padding: 0 0.3rem;
  width: max-content;
  display: inline-flex;
  margin-left: 2px;
  margin-top: 0;
}

.new-badge {
  background: var(--main-red);
  font-size: 10px;
  color: white;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 100px;
  display: flex;
  align-items: center;
}

// .logout__alert .alert-message.sc-ion-alert-ios,
// .alert-input-group.sc-ion-alert-ios {
//   padding-left: 16px;
//   padding-right: 16px;
//   padding-top: 0;
//   padding-bottom: 21px;
//   color: var(--ion-text-color, #000);
//   font-size: 13px;
//   text-align: unset !important;
// }

// .logout__alert .alert-button.sc-ion-alert-ios {
//   margin-left: 0;
//   margin-right: 0;
//   margin-top: 0;
//   margin-bottom: 0;
//   border-radius: 0;
//   -ms-flex: 1 1 auto;
//   flex: 1 1 auto;
//   min-width: 50%;
//   height: 44px;
//   // border-top: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
//   // border-right: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
//   background-color: transparent;
//   // color: cyan;
//   font-size: 17px;
//   overflow: hidden;
// }

// .my-custom-class-logout .modal-wrapper {
//   height: 17%;

//   position: absolute;

//   width: 84%;
//   border-radius: 3px;
// }

.logout__button__div {
  display: flex;
  color: #97144D;
  font-size: 16px;
  font-weight: 400;
  flex-direction: row-reverse;
  margin-top: -5px;
}

.logout__button__yes {
  padding-right: 9px;
  font-weight: 500;
  cursor: pointer;
}
.logout__button__cancel {
  padding-right: 6vw;
  font-weight: 500;
  cursor: pointer;
}
.logout__text {
  font-weight: 500;
  margin-bottom: 1rem;
}

.weight500 {
  font-weight: 500;
}
.detail-screen-logout {
  background-color: white;
    width: 80%;
    padding: 16px;
    border-radius: 4px;
}
