.terms-privacy-page-container {
  padding: 20px;
}

.zyla-terms-text {
  font-family: Montserrat !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 24px !important;
  /* identical to box height, or 171% */
  margin-top: 0;
  color: #464646;
}

.terms-title-mobile {
  margin: 0 0;
  padding: 16px 0 0 0;
  font-size: 24px;
  font-weight: 700;
  opacity: 1 !important;
}

.terms-sub-title-mobile {
  margin: 0 0;
  padding: 16px 0 16px 0;
  max-width: 1200px;
  font-size: 16px;
}

.terms-sub-heading {
  color: black;
  font-weight: 500;
}

.privacy-heading-style {
  font-weight: 700;
  font-size: 16px;
  margin: 17px 0;
}
