.slide-reward {
    display: flex !important;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    min-width: 100%;
    column-gap: 8px;
    padding: 6px 6px 6px 0;
  }
  
  .reward-mli-slider {
    .slick-next {
      right: -8px !important;
    }
    .slick-prev {
      left: -13px !important;
    }
    .slick-dots li button::before  {
        font-size: 9px;
    }
    .slick-dots li.slick-active button:before  {
      color: #97144D;
      font-size: 9px !important;
    }
    .slick-active[data-index="0"]  {
      width: 48% !important;
      
    }
    // .slick-slide {
    //   height: 240px;
    // }
    [data-index="1"] {
     
      .dynamic-content-container:first-of-type {
        margin-left: 8px;
      }
      
    }
    .slick-dots li {
      margin: 0 !important;
    }
   
  }

  .reward-point-level-tag {
    background-image: url("../../../../assets/images/reward-blade-bgzy.svg");
    background-color: transparent;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2px;
    .reward-point-level-top {
      font-size: 10px;
      font-weight: 500;
    }
    .reward-point-level-bottom {
      font-size: 10px;
      font-weight: 700;

    }
  }
  
  .level-progress-dots-container-element>:nth-child(4) {
    margin-right: 50px !important;
   
   
  }