.stepathon-tracker-chart-graph {
    height: 500px;
    .tracker-chart-graph-container {
        .tracker_chart_chart_box {
            height: 80% !important;
            margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    box-shadow: 0px 7.123015880584717px 9.497355461120605px 0px rgba(0, 0, 0, 0.05) !important;

           
          
         }
    }
}
