div.premium-wrapper {
  overflow-y: scroll;
  background: #f0f3f8;
  padding-bottom: 4rem;
  height: 100%;
  width: 100%;
  color: #000000;
  -webkit-overflow-scrolling: touch;
  @media screen and (min-width: 600px) {
    background: white !important;
  }
  .back-button-wrapper-premium {
    padding: 10px;
  }

  button.back-button-wrapper {
    display: flex;
    height: 50px;
    align-items: flex-end;
    background: none;
    border: none;
    outline: none;
    width: 100%;
  }
  .premium-doctor-section {
    background-color: #f0f3f8;
    padding: 30px 1rem !important;
  }
  .section-wrapper {
    position: relative;
    // color: #ffffff;
    padding: 0.5rem 1rem;

    h2 {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      margin-top: 0;
    }
    .premium-page-iframe-container {
      margin: 1rem -1rem;
      iframe {
        width: 100%;
      }
    }

    .scroll-div {
      display: flex;
      overflow-x: scroll;
      margin: 1rem -1rem 0 -1rem;
      .tct-cards {
        display: flex;
        flex-flow: column;
        height: 190px;
        min-width: 190px;
        margin: 1rem;
        background: linear-gradient(109.53deg, #143A72 0%, #5e38f3 99.4%),
          #ffffff;
        color: white;
        padding: 1rem;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.107478);
        border-radius: 4px;
        .tct-image-background-div {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background-color: #fff;
          min-width: 46px;
          min-height: 46px;
          max-width: 46px;
          max-height: 46px;
          img {
            height: 25px;
            width: 25px;
          }
        }

        h3 {
          font-weight: bold;
          font-size: 16px;
          line-height: 140%;
          margin: 0;
          margin-top: 12px;
          white-space: pre-line;
        }
        p {
          font-size: 12px;
          line-height: 140%;
        }
      }
      .doctor-cards {
        display: flex;
        flex-flow: column;
        min-width: 231px;
        background: #fff;
        // color: #464646;

        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.107478);
        /* Inside Auto Layout */
        margin: 0px 16px;

        height: 126px;
        overflow: hidden;

        .dr-card-wrapper {
          padding: 0.5rem;
          overflow: hidden;
          border-bottom: 0.5rem solid white;
          div {
            display: inline-flex;
            flex-direction: row;
            img.doctor-image {
              height: 32px;
              width: 32px;
              display: inline-flex;
              border-radius: 10000px;
              object-fit: cover;
            }
            div {
              flex-direction: column;
              margin-left: 0.5rem;
              justify-content: center;
              h3,
              p {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                /* identical to box height */
                letter-spacing: -0.03em;
                margin: 0;
              }
              p {
                font-size: 8px;
                line-height: 10px;
              }
            }
          }
          p {
            font-size: 12px;

            /* text-overflow: ellipsis; */
            display: -webkit-box;
            /* max-width: 200px; */
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      .patient-card {
        display: flex;
        flex-direction: column;
        background: white;
        min-width: 231px;
        // color: #464646;
        padding: 1rem;
        margin: 0 1rem;
        border-radius: 4px;
        // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.107478);
        border: 1px solid #e8e8e8;

        .quotes {
          height: 28px;
          width: 28px;
        }
        h3 {
          font-weight: bold;
          font-size: 14px;
          line-height: 23px;
          /* or 164% */
          letter-spacing: -0.03em;

          color: #7478fb;
        }
        p {
          font-size: 12px;
          line-height: 150%;
          /* or 18px */
          letter-spacing: -0.02em;

          color: #464646;
        }
        div {
          display: flex;
          margin-top: auto;
          img.patient-photo {
            height: 32px;
            width: 32px;
            margin-right: 8px;
            border-radius: 10000px;
            object-fit: cover;
          }
          div {
            flex-direction: column;
            display: inline-flex;
            width: max-content;
            margin: 0;
            height: 100%;
            justify-content: center;
            p {
              margin: 0;
              font-weight: 600;
              font-size: 10px;
            }
            span {
              font-weight: 600;
              font-size: 8px;
              /* identical to box height */
              letter-spacing: -0.03em;

              // color: #464646;
            }
          }
        }
      }
    }
    .benefits-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row: auto auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      /* padding: 0 1rem; */
      /* align-items: center; */
      justify-content: center;

      .benefits-div {
        display: flex;
        flex-direction: column;
        background: white;
        align-items: center;
        justify-content: center;
        /* font-size: 16px; */
        padding: 1rem;
        border-radius: 4px;

        img {
          width: 34px;
          height: auto;
        }
        p {
          font-weight: bold;
          font-size: 14px;
          text-align: center;

          color: #000000;
        }
      }
    }
    .faq-wrapper {
      p {
        font-weight: 700;
        font-size: 16px;
        img {
          display: inline;
          height: 16px;
        }
      }
      hr {
        border-top: 1px solid #ffffff33;
        background-color: #f0f3f8;
      }
    }
    button.faq-button {
      width: 100%;
      height: 39px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 4px;

      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      /* identical to box height, or 164% */
      letter-spacing: -0.03em;
      color: white;
      background-color: #7967f9;

      margin-bottom: calc(var(--ion-safe-area-bottom) + 16px);
    }
  }
  .premium-div-parent {
    background-color: #f0f3f8;
    padding-top: 15px;
    padding-bottom: 15px;

    .premium-div {
      margin: 1rem;
      background: white;
      padding: 1rem;
      border-radius: 8px;
      h3 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 1 rem;
        background: linear-gradient(
          94.33deg,
          #a6743b 19.66%,
          #cfa44f 66.8%,
          #e4c780 108.38%
        );
        text-align: center;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      ul {
        padding: 0;
        list-style-type: none;
      }
      li {
        background: url("../../assets/icons/check-mark.png") no-repeat left top;
        // height: 50px;
        /* padding-top: 7px; */
        padding-left: 28px;
        background-size: 20px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 15px;
      }
      .gold-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 41px;

        /* Linear */
        background: linear-gradient(
          94.33deg,
          #a6743b 19.66%,
          #cfa44f 66.8%,
          #e4c780 108.38%
        );
        box-shadow: 2px 2px 20px -6px rgba(254, 219, 111, 0.2);
        border-radius: 8px;
        img {
          height: 42px;
          width: 42px;
        }
        p {
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */
          letter-spacing: -0.03em;
          margin: 1rem;
          // color: #ffffff;
          text-align: center;
        }
        p#pricing-text {
          margin-top: 0;
          font-size: 12px;
        }
        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 40px;

          width: 115%;
          height: 31px;
          background: #ffffff;
          border-radius: 100px;

          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height */
          letter-spacing: -0.03em;
          text-transform: uppercase;

          color: #000000;
        }
      }
    }
  }
}
.request-callback {
  position: fixed;
  left: 0%;
  right: 0%;
  bottom: var(--ion-safe-area-bottom);
  width: 100%;
  height: 4rem;
  background: #97144D;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.157467);
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  text-align: center;
  color: #ffffff;

  z-index: 100;
}
button {
  outline: none;
}
.request-sent-wrapper {
  height: 100%;
  width: 100%;
  // margin-bottom: var(--ion-safe-area-bottom);
  .request-sent-completed {
    height: 50%;
    top: 20%;
    position: relative;
    display: flex;
    flex-direction: column;
    div.rc-image-wrapper {
      margin: 0 10%;
      img {
        height: 50px;
      }
    }
  }
  .back-to-chat {
    color: var(--main-white);
    padding: 10px 15px;
    /* border-radius: 16px; */
    background: var(--main-orange);
    font-weight: 600;
    height: 64px;
    outline: none;
    width: 100%;
    position: fixed;
    bottom: var(--ion-safe-area-bottom);
    transform: translate3d(0, 0, 0);
  }
}
.orange-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 133%;
  /* or 133% */

  color: #97144D;
}

// .premium-container::-webkit-scrollbar,
// ::-webkit-scrollbar {
//   display: none;
// }

*,
.premium-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  // --offset-top: var(--ion-safe-area-top);
  // --offset-bottom: var(--ion-safe-area-bottom);
  // margin-top: var(--ion-safe-area-top);
  // margin-bottom: var(--ion-safe-area-bottom);
  .premium-error-handling {
    position: fixed;
    top: 0;
    text-align: center;
    width: 100%;
    background-color: #d0021b;
    color: white;
    font-size: 12px;
    padding: 2px 0;
    z-index: 100000;
  }
}
#callback-sent {
  background-color: white;
  color: #464646;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: inline;
    height: 24px;
    margin-right: 1rem;
  }
}

.background-color-gray-class {
  background-color: #f0f3f8 !important ;
}

.background-color-white-class {
  background-color: #fff !important ;
}

.razor-pay-payment-modal {
  width: 100%;
  background-color: white;
  display: flex;

  justify-content: center;
  align-items: center;
  .paymentDialog-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 30px;
    img {
      width: 48px;
      height: 48px;
    }
    .paymentDialog-container-span-1 {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
    .paymentDialog-container-span-2 {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      text-align: center;

      color: #464646;
    }
    .paymentDialog-container-span-3 {
      font-size: 14px;
      line-height: 20px;

      text-align: center;

      color: #464646;
    }
    .paymentDialog-container-button {
      position: absolute;
      bottom: 0;
      background-color: #97144D;
      color: white;
      font-weight: 600;
      text-align: center;
      padding: 15px;
      width: 100%;
    }
  }
}
.premium-payment-sucessfull-modal {
}
