.pa-container {
  &::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  background-image: linear-gradient(
    var(--primary-violet),
    var(--secondary-violet)
  );
  height: calc(100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 25px;
  div.pa-header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    color: var(--main-white);
    padding: 10px 20px 0px 20px;
  }
  img.doc-img-pa {
    // width: 80px;
    height: 255px;
  }
  div.pa-question-container {
    position: absolute;
    top: 145px;
    height: 70%;
    width: 80%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pa-questions-body {
      background: var(--main-white);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 93%;
      .current-question {
        padding: 15px 15px 0px 15px;
        min-height: 250px;
        overflow: auto;
        height: 100%;
        .question-text {
          color: var(--main-text-light);
          padding-bottom: 20px;
          text-align: left;
        }
        .multi-options-content {
          overflow: auto;
          display: flex;
          flex-direction: column;
          padding-bottom: 20px;
        }
        .single-options-content {
          .select-pa {
            padding: 3px 13px;
            border-radius: 16px;
            background: var(--grey-shade);
            margin-bottom: 10px;
            span {
              padding-left: 20px;
              padding-right: 20px;
              display: inline-block;
              color: var(--main-text-light);
            }
          }
          .selected-pa {
            background: #97144D;
            span {
              color: var(--main-white);
            }
          }
        }
        .select-pa {
          padding-bottom: 10px;
          font-size: 13px;
          span {
            display: inline-block;
            border-radius: 16px;
            padding: 7px 13px;
            background: var(--grey-shade);
          }
        }
        .selected-pa {
          span {
            display: inline-block;
            color: var(--main-white);
            background: #97144D;
          }
        }
        .question-field-sugar {
          align-items: flex-start !important;
        }
        .question-field {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 10px;
          padding-bottom: 10px;
          ion-datetime {
            background-color: var(--grey-shade);
            border-radius: 20px;
            font-size: 13px;
            /* padding-left: 2px; */
            width: 170px;
          }
          .weight-input-data {
            padding: 30px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            input {
              width: 100px;
              text-align: center;
            }
          }
          .height-input-data {
            padding: 5px 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            input {
              width: 100px;
              text-align: center;
            }
            .height-content {
              display: flex;
              padding: 5px 0px;
              align-items: center;
            }
            img {
              height: 80px;
              margin-top: 17px;
              margin-left: -15px;
            }
          }

          .bmi-container {
            display: flex;
            justify-content: center;
            margin-top: -30px;
            .bmi-content {
              width: 140px;
              .bmi-scale {
                position: relative;
                margin-top: 30px;
                height: 5px;
                border-radius: 10px;
                left: 50px;
                width: 55%;
                background: var(--main-text-light);
                .details-bmi {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  position: relative;
                  bottom: 10px;
                  left: 85px;
                  text-align: initial;
                }
              }
            }
          }
          .sugar-level-content {
            padding: 0px 30px;
            input {
              padding: 6px 10px 6px 20px;
              font-size: 13px;
              margin-top: 5px;
            }
            .sugar-text {
              color: var(--grey-light);
              text-align: left;
              padding-top: 15px;
            }
          }
          .single-input-type-4 {
            display: flex;
            flex-flow: row-reverse;
            .sugar-text {
              margin-left: 0.5rem;
            }
          }
        }
      }
      .current-question::-webkit-scrollbar {
        width: 5px;
      }
    }
  }
  div.next-btn {
    background: var(--main-orange);
    padding: 10px;
    text-align: center;
    color: var(--main-white);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .prev-btn {
    color: var(--main-white);
    text-align: center;
  }

  div.single-options-content {
    text-align: center;
  }

  img.dob-image {
    height: 110px;
  }
  .text-center {
    text-align: center !important;
  }
  input.common-field {
    width: 100%;
    background: var(--grey-shade);
    box-shadow: none;
    border-radius: 16px;
    outline: none;
    border: none;
    padding: 5px 10px;
  }
  input.common-field::placeholder {
    color: #949090;
  }

  .holder-text {
    padding: 0px 10px;
    min-width: 45px;
    color: var(--main-text-light);
  }
  img.height-image {
    height: 100px;
  }

  div.total-progress-possible {
    width: 100%;
    background: var(--secondary-violet);
  }
  div.current-progress {
    height: 4px;
    border-radius: 4px;
    filter: blur(0px) !important;
    background: var(--main-white);
    transition: all 0.6s linear;
  }
  div.progress-container {
    padding-top: 13px;
    border-radius: 4px;
    .pa-heading {
      font-size: 12px;
    }
  }
  .error-box {
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #e64545;
    color: white;
    font-size: 14px;
    margin-top: -30px;
    justify-content: center;
    width: 120%;
    animation: FadeAnimation 1s ease-in 3s forwards;
  }
  @keyframes FadeAnimation {
    0% {
      opacity: 1;
      visibility: visible;
    }

    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
}
img.pa-done {
  height: 250px;
}
div.pa-done-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: var(--main-text-light);
  margin: auto;
  width: 80%;
  button {
    border-radius: 25px;
  }
}
div.pa-text {
  margin-top: 10px;
  color: var(--grey-light);
}

div.pa-completed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
