.pres-upload {
  .uploading-text {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.address-book-element-medicine{
  padding: 10px;
  border: 1px solid #f3f4f6;
  margin-top: 32px;
  color: #171a21;
  line-height: 22.4px;
  border-radius: 0.375rem;
  p{
    font-size: 12px;
    line-height: 16.8px;
  }
  h6{
    margin-top: 8px;
  }
}