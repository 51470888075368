.heading {
  padding-left: 18px;
  padding-right: 22px;
  position: unset;
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.alert-chat > div.alert-wrapper {
  width: 260px;
}
.modal-alert {
  .ion-page {
    bottom: 0px !important;
    top: 0px !important;
  }
}
.modal-alert > div.modal-wrapper {
  position: absolute;
  width: 260px;
  height: 493px;
  background: #ffffff;
  border-radius: 16px;
}
.list {
  //   width: 180px;
  //   height: 48px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.list-p {
  padding-right: 14px;
  margin-block-end: 0;
  margin-block-start: 0;
}
.list-ul {
  margin-block-end: 0;
}
.list-callback {
  padding-left: 22px;
  padding-right: 14px;
  margin-block-end: 0;
  //   margin-block-start: 0;
}
.close-img {
  position: absolute;
  right: 0;
  height: 29px !important;
}
.button-callback {
  margin: 11px;
  background: var(--main-orange);
  text-align: center;
  font-weight: 600;
  color: white;
  padding: 10px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 14px;
  padding-right: 16px;
  padding-left: 16px;
  /* width: 329px; */
  height: 40px;
  background: #97144D;
  border-radius: 4px;
  bottom: 14px;
}
.button-subs {
  margin: 11px;
  margin-bottom: 16px;
  background: var(--main-orange);
  text-align: center;
  font-weight: 600;
  color: #97144D;
  padding: 10px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 14px;
  padding-right: 16px;
  padding-left: 16px;
  /* width: 329px; */
  height: 42px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #97144D;
  box-sizing: border-box;
  position: relative;
  bottom: 14px;
}
.green {
  margin-left: -47px;
  margin-top: -24px;
}

.payment-screen {
  height: 100vh;
}
.alert-img {
  background: rgba(239, 124, 1, 0.1);
  width: 100%;
  text-align: center;
}
.alert-img-payment {
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(239, 124, 1, 0.1);
  width: 100%;
  text-align: center;
}
.button-pay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--main-orange);
  text-align: center;
  font-weight: 600;
  color: white;
  padding: 10px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 14px;
  padding-right: 16px;
  padding-left: 16px;
  height: 40px;
  border-radius: 4px;
}
.heading-payment {
  width: 100%;
  position: absolute !important;
  top: 41%;
  padding-left: 18px;
  padding-right: 22px;
  //   position: unset;
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}
.list-payment {
  width: 100%;
  text-align: center;

  width: 180px;
  height: 48px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.list-p-payment {
  width: 100%;
  //   text-align: center;
  position: relative;
  top: 57%;
  padding-left: 23px;
  margin-block-end: 0;

  //   font-family: Montserrat;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 14px;
  //   line-height: 24px;
  /* identical to box height, or 171% */

  color: #000000;
}
.sub-heading-payment {
  //     position: absolute;
  // width: 217px;
  // height: 28px;
  // left: 15px;
  // bottom: 218px;
  position: absolute;
  top: 50%;
  width: 100%;
  //   text-align: center;
  //   margin-block-start: 0;
  //   margin-block-end: 0;
  padding-left: 18px;
  padding-right: 22px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.loader {
  color: var(--main-orange);
}
.loading-text {
  position: absolute;
  width: 217px;
  height: 56px;
  top: 55%;
  left: -5rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}
.loading-text-small {
  position: absolute;
  width: 217px;
  top: 280%;
  left: -5rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
.pDone {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 30%;
}
.payment-done {
  position: absolute;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  //   top: 70%;
  text-align: center;

  color: #000000;
}
.payment-fail {
  position: absolute;
  top: 46%;
  left: 1%;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}
.retry-button {
  position: absolute;
  bottom: 0;
  //   margin-left: 16px;
  //   margin-right: 16px;
  //   margin-top: 53px;
  //   margin-bottom: 22px;
  width: 100%;
  background: var(--main-orange);
  text-align: center;
  font-weight: 600;
  color: white;
  padding: 10px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 14px;
  //   padding-right: 16px;
  //   padding-left: 16px;
  /* width: 329px; */
  height: 40px;
  background: #97144D;
  border-radius: 4px;
}
.backImage {
  position: relative;
  top: 7px;
  left: 6px;
}

.backBackground {
  z-index: 2;

  top: 16px;
  left: 16px;
  height: 36px;
  width: 36px;
  background-color: #ffffff;
  border-radius: 25px;
  position: absolute;
}
.reload {
  position: absolute;
  top: 7px;
  left: 8px;
}
.relaodBackground {
  position: absolute;
  top: 40%;
  left: 45%;
  text-align: center;
  height: 44px;
  width: 44px;
  border-radius: 35px;
  background-color: #d0021b;
}
//////////////////////////////////////////////
.backBackground-1 {
  z-index: 2;
  top: 16px;
  left: 16px;
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 25px;
  position: absolute;
}
.backImage-1 {
  padding-left: 2px;
  position: absolute;
  top: 5px;
}
.alert-img-payment-1 {
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  background: rgba(239, 124, 1, 0.1);
  width: 100%;
  text-align: center;
}

.heading-payment-1 {
  width: 100%;
  // position: absolute !important;
  // top: 41%;
  padding-left: 18px;
  padding-right: 22px;
  //   position: unset;
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}
.list-payment-1 {
  width: 100%;
  text-align: center;

  width: 180px;
  height: 48px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.list-p-payment-1 {
  width: 100%;
  //   text-align: center;
  // position: relative;
  // top: 57%;
  padding-left: 23px;
  margin-block-end: 0;

  //   font-family: Montserrat;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 14px;
  //   line-height: 24px;
  /* identical to box height, or 171% */

  color: #000000;
}
.sub-heading-payment-1 {
  //     position: absolute;
  // width: 217px;
  // height: 28px;
  // left: 15px;
  // bottom: 218px;
  // position: absolute;
  // top: 50%;
  // width: 100%;
  //   text-align: center;
  //   margin-block-start: 0;
  //   margin-block-end: 0;
  padding-left: 18px;
  padding-right: 22px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.button-pay-1 {
  background: var(--main-orange);
  text-align: center;
  font-weight: 600;
  color: white;
  padding: 10px 0px;
  font-size: 14px;
  height: 40px;
  border-radius: 4px;
  margin: l;
  margin-left: 20px;
  margin-right: 20px;
}
.button-cover {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.retry-cover {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.retry-button-1 {
  background: var(--main-orange);
  text-align: center;
  font-weight: 600;
  color: white;
  padding: 10px 0px;
  font-size: 14px;
  height: 40px;
  border-radius: 4px;
  margin: l;
  margin-left: 20px;
  margin-right: 20px;
}
.green-img {
  position: absolute;
  top: 35%;
  left: 45%;
}

.error-message {
  .MuiSnackbarContent-root {
    background-color: rgb(230, 69, 69);
  }
}
