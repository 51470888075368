.zyla-wallet-faq-container {
  background-color: white;
  padding: 16px;
  .zyla-wallet-faq-header {
    img {
      width: 24px;
    }
  }
  .zyla-wallet-faq-mid {
    h1 {
      color: #26284d;
      font-size: 20px;
      font-weight: 700;
      margin-top: 16px;
    }
  }
  .zyla-wallet-faq-accordian-question {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #26284d;
    line-height: 15px;
    font-family: Montserrat;
  }
  .zyla-wallet-faq-accordian-answer {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #26284d;
    line-height: 130%;
    font-family: Montserrat;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordion-root:before {
    background-color: white;
  }
  .MuiAccordionSummary-root {
    padding: 0 8px 0 0 !important;
  }
  .MuiAccordionDetails-root {
    padding: 0 !important;
  }
}

.zyla-wallet-faq-chevron-down {
  color: black !important;
  width: 12px !important;
}

.zyla-wallet-transaction-container {
  padding: 16px;
  position: relative;
  .zyla-wallet-transaction-header {
    img {
      width: 24px;
    }
  }
  .zyla-wallet-transaction-section-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 32px;
    p {
      font-size: 20px;
      color: #26284d;
      margin: 0;
    }
    img {
      width: 62px;
      height: 62px;
    }
    .zyla-wallet-transaction-section-1-amount {
      font-weight: 700;
      margin-bottom: 4px;
    }
    .zyla-wallet-transaction-section-1-desc {
      font-weight: 500;
    }
  }
  .zyla-wallet-transaction-section-2 {
    display: flex;
    justify-content: space-between;
    margin: 18px 0;
    .zyla-wallet-transaction-filter-select-wrapper {
      border: 0.2px solid #1bc47d;
      border-radius: 8px;
      padding: 10px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      min-width: 132px;
      height: fit-content;
      cursor: pointer;
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
      }
      .zyla-wallet-transaction-filter-select-button {
        outline: none;
        background-color: transparent;
        border: none;
        font-size: 12px;
        font-weight: 600;

        color: #26284d;
      }
      svg {
        max-width: 16px;
        max-height: 16px;
        vertical-align: middle;
      }
    }
    .zyla-wallet-transaction-filter-select {
      &::after,
      &::before,
      &:hover {
        border-bottom: none;
        background-color: transparent;
        content: "";
      }
      .MuiSelect-root {
        font-size: 12px;
        color: #26284d;
        font-weight: 600;
        font-family: Montserrat;
      }
      .MuiSvgIcon-root {
        background-image: url("../../assets/icons/chevron-black-down.svg");

        background-repeat: no-repeat;
        fill: none;
        color: white;
        top: calc(50% - 10px);

        right: -2px;
      }
    }
  }
  .zyla-wallet-transaction-section-3 {
    display: grid;
    grid-template-columns: 1fr;
  }
  .zyla-wallet-transaction-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column-reverse;
    .zyla-wallet-transaction-content {
      background-color: white;
      padding: 16px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .zyla-wallet-transaction-content-heading {
        font-size: 12px;
        font-weight: 600;
        color: #26284d;
        margin-bottom: 16px;
      }
      button {
        outline: none;
        border: none;
        background-color: transparent;
        padding: 12px 0;
        width: 100%;
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        color: #26284d;
      }
    }
  }
}
.zyla-wallet-transaction-section-3-ele {
  display: grid;
  grid-template-columns: calc(100% - 90px) 90px;
  margin-bottom: 12px;
  .zyla-wallet-transaction-section-3-ele-left {
    display: flex;
    align-items: flex-start;
    .zyla-wallet-transaction-section-3-ele-left-left {
      margin-right: 12px;
      margin-top: -3px;
      .zyla-wallet-transaction-debit-icon {
        color: #e44437 !important;
        width: 12px !important;
        height: 12px !important;
      }
      .zyla-wallet-transaction-credit-icon {
        color: #1bc47d !important;
        width: 12px !important;
        height: 12px !important;
      }
    }
    .zyla-wallet-transaction-section-3-ele-left-right {
      padding-right: 24px;
      .zyla-wallet-transaction-section-3-ele-left-right-top {
        margin: 0 0 6px 0;
      }
      .zyla-wallet-transaction-section-3-ele-left-right-bottom {
        font-size: 10px;
        font-weight: 400;
        color: #26284d;
        margin: 0;
        word-break: break-word;
        display: -webkit-box;

        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .zyla-wallet-transaction-section-3-ele-right {
    font-size: 10px;
    font-weight: 400;
    color: #26284d;
  }
}

.zyla-wallet-home-container {
  padding: 16px;
  .zyla-wallet-home-container-header {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    .zyla-wallet-home-container-header-left {
      img {
        width: 24px;
      }
    }
    .zyla-wallet-home-container-header-mid {
      flex: 1;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      color: #26284d;
      line-height: 28px;
      margin: 0;
    }
    .zyla-wallet-home-container-header-right {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
  .zyla-wallet-home-card {
  }
  .zyla-wallet-home-rct-container {
    .zyla-wallet-home-rct-heading {
    }
    .zyla-wallet-home-rct-table {
      background-color: white;
      overflow: hidden;
      border: 1px solid #f0effe;
      border-radius: 8px;
      .zyla-wallet-home-rct-table-header {
        display: grid;
        grid-template-columns: calc(100% - 90px) 90px;
        background-color: #f0effe;
        padding: 10px 10px 10px 36px;
        p {
          margin: 0;
          font-size: 12px;
          font-weight: 600;
          color: #26284d;
        }
      }
      .zyla-wallet-home-rct-table-body {
        padding: 16px 12px 0 12px;
      }
    }
  }

  .zyla-wallet-home-lfa-link-wrapper {
    display: flex;
    flex-direction: column;
    .zyla-wallet-home-lfa-link-ele {
      display: flex;
      align-items: flex-start;
      margin-bottom: 12px;
      cursor: pointer;
      .zyla-wallet-home-lfa-link-ele-top {
        margin-right: 8px;
        img {
          width: 12px;
          height: 12px;
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
      .zyla-wallet-home-lfa-link-ele-mid {
        flex: 1;
        p {
          margin: 0;
        }
        .zyla-wallet-home-lfa-link-ele-mid-top {
          line-height: 20px;
          font-size: 12px;
          font-weight: 700;
          color: #26284d;
        }
        .zyla-wallet-home-lfa-link-ele-mid-bottom {
          font-size: 10px;
          font-weight: 500;
          color: #26284d;
          line-height: 14px;
        }
      }
      .zyla-wallet-home-lfa-link-ele-bottom {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .zyla-wallet-home-general-section-heading {
    font-size: 16px;
    font-weight: 700;
    color: #26284d;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .zyla-wallet-home-quick-link-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    margin-bottom: 16px;
  }
}

.font-12px-zy-wallet {
  font-size: 12px;
  color: #26284d;
}
.font-14px-zy-wallet {
  font-size: 14px;
  color: #26284d;
}

.font-weight-400-zy-wallet {
  font-weight: 400;
}
.font-weight-600-zy-wallet {
  font-weight: 600;
}
.font-weight-700-zy-wallet {
  font-weight: 700;
}

.level-progress {
  ion-progress-bar {
    border: 1px solid #143a72;
    --buffer-background: white;
    --progress-background: #143a72;
  }
}
.level-progress-dots-container {
  position: relative;
  display: flex;
  padding: 0 1px;
  width: 100%;
  align-items: center;
  left: 0;

  .level-progress-dots {
    background-color: white;
    width: 6.5px !important;
    height: 6.5px !important;
    min-height: 6.5px;
    min-width: 6.5px;
    border: 2px solid #97144D;
    border-radius: 100%;
    position: absolute;
    top: -7.5px;
  }
}

.border-mliblue-class {
  border: 0.2px solid #143a72;
}
