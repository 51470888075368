.settings-page-container {
  .settings-page-header {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 16px 12px;
    .settings-page-header-left {
      align-self: flex-end;
      margin-right: 12px;
      padding-top: 20px;
    }
    .settings-page-header-right {
      display: flex;
      flex-direction: column;
      .settings-page-header-right-top {
        font-size: 12px;
        font-weight: 400;
        color: #171a21;
      }
      .settings-page-header-right-bottom {
        font-size: 20px;
        font-weight: 600;
        color: #171a21;
      }
    }
  }
  .settings-page-content {
    .settings-page-content-top {
      .setting-element-container {
        background-color: white;
        margin: 12px 0;
        .settings-element-box {
          display: flex;
          align-items: center;
          padding: 16px 14px;
          .settings-elements-box-left {
            display: flex;
            padding-bottom: 10px;
            margin-right: 12px;
            justify-content: center;
          }
          .settings-elements-box-mid {
            flex: 1;
            display: flex;
            flex-direction: column;
            .settings-elements-box-mid-title {
              font-size: 16px;
              font-weight: 600;
              color: #171a21;
            }
            .settings-elements-box-mid-subtitle {
              font-size: 12px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.4);
            }
          }
          .settings-elements-box-right {
            .MuiButtonBase-root.Mui-checked {
              color: #97144D;
            }
          }
        }
      }
    }
    .settings-page-content-bottom {
      margin: 16px;
      text-align: center;
      span {
        font-size: 12px;
        font-weight: 500;
        //color: rgba(0, 0, 0, 0.4);
        color: #171a21;
      }
    }
  }
  .error-message-container {
    position: fixed;
    top: var(--ion-safe-area-top);
    padding: 2px 0;
    text-align: center;
    background-color: #d0021b;
    width: 100%;
    span {
      color: white;
      font-size: 12px;
    }
  }
}

.settings-page-spinner {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  ion-spinner {
    width: 100px;
    height: 100px;
    color: var(--main-cornflower);
  }
}
