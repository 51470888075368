.camera-preview-global-content {
}
.myIonModalClass {
  #video {
    transform: translateX(-50%) !important;
    position: absolute;
    height: 100%;
    left: 50%;
  }
  .camera-preview-button-container {
    position: absolute;
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: space-around;
    bottom: 60px;
    width: 100%;
  }
  .camera-preview-captured-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      flex: 1;
    }
    .camera-preview-captured-image-container-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;
      button {
        flex: 1;
      }
    }
  }
  .camera-preview-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    font-weight: 500;
  }
  .modal-wrapper {
    background-color: black !important;
  }
}
