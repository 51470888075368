div.health-goal-content {
  height: 100%;
  width: 100%;

  padding: 15px 10px;
}

div.short-term-content,
div.health-goal-content,
div.empty-long-term-goals {
  max-width: 560px;
  margin: 0 auto;
}

.h-goal {
  color: var(--main-text-light);
  height: 28px;
}

.m-15 {
  margin-left: 10px;
}

.extra-padding {
  padding-bottom: 14px;
}

div.details-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--main-orange);
  padding-bottom: 5px;
  position: fixed;
  bottom: var(--ion-safe-area-bottom);
  // bottom: ;
  left: 0;
  right: 0;
  background-color: white;
  height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  /* text-align: center; */
  border-top: 1px solid;
}

div.details-button-two {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c4c4c4;
  border-top: 3px solid #c4c4c4c4;
  height: 60px;
  bottom: var(--ion-safe-area-bottom);
  // bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: white;
}

div#details-button-two-active {
  color: var(--main-orange);
  border-top: 3px solid var(--main-orange);
}

div.content-health-goals {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.dash-container {
  width: 45px;
  padding: 5px 0px;
}

img.detail-icon {
  height: 30px;
}

.arw {
  transition: all 0.1s linear;
  width: 41px;
}

.zeroWidth {
  width: 0px;
  transition: all 0.1s linear;
}

img.back-arw {
  height: 35px;

  padding: 10px;
}

div.head-goals {
  display: flex;
  align-items: center;
}

div.slider-container {
  height: 100%;
}

div.empty-long-term-goals {
  // height: calc(100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
  padding: 25px;
}

div.fitness-hp {
  counter-reset: var(--main-text-light);
}

div.fitness-head {
  padding: 17px 0px;
}

div.btn-content {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  width: 60%;
}

div.cont-btn-container {
  display: flex;
  justify-content: center;
}

div.long-term-goal-list {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 15px; */
}

div.card-long-term {
  position: relative;
  padding: 0px 5px 5px 5px;
  @media screen and (min-width: 560px) {
    flex-basis: unset;
  }
}

div.long-goal-name {
  position: absolute;
  padding: 18px 10px;
  color: var(--main-white);
}

div.slider-goal-name {
  position: absolute;
  padding: 0px 15px;
  color: var(--main-white);
  top: 120px;
  width: 50%;
  text-align: left;
}

div.slider-data {
  overflow: hidden;
  width: 95%;
  border: 1px solid var(--grey-border);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
}

div.slider-desc {
  text-align: left;
  padding: 25px 20px;

  overflow: auto;
}

div.slider-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem 0 0;
  margin-bottom: 70px;
}

div.message-term {
  padding-bottom: 5px;
}

div.long-term-goal-data {
  margin: 10px 0px;
  margin-bottom: 60px;
  /* to change */
  overflow: auto;
}

.ht-msg {
}

div.short-term-content {
  // height: calc(100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

img.st-img {
  margin-bottom: 10px;
  width: 100%;
}

div.short-term-data {
  margin-bottom: 60px;
}

div.short-term-card {
  position: relative;
}

div.short-term-details {
  position: absolute;
  padding: 15px;
  width: 100%;
  height: 100%;
}

div.short-data {
  color: var(--main-white);
  align-items: flex-start;
  height: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.shot-name {
  max-width: 80%;
  text-align: left;
}

div.upper-info-short {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.short-goal-progress {
  --progress-background: var(--main-white);
  height: 7px;
  border-radius: 15px;

  .progress {
    z-index: 0;
  }
}

.progress-goal-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
}

.short-goal-desc h2.alert-title.sc-ion-alert-md {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-text-light);
}

img.info-icon {
  height: 25px;
}

div.sheet-text {
  padding: 30px;
  text-align: center;
}

.error-box {
  height: 25px;
  display: flex;
  align-items: center;
  background-color: #e64545;
  color: white;
  font-size: 14px;
  justify-content: center;
  width: 100%;
  left: 0;
  right: 0;
  top: var(--ion-safe-area-top);
  animation: FadeAnimation 1s ease-in 3s forwards;
}

div.sheet-btn-short {
  padding: 25px 0px;
  align-items: center;
  display: flex;

  justify-content: center;
}

div.sheet-btn-short > button {
  width: 130px;
  font-size: 17px;
  margin-bottom: 20px;
}

div.hg-screen-container {
  /* padding: 10px; */
  // height: calc(100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
  background: var(--secondary-grey);
}

div.hg-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
}

div.hg-section {
  background: var(--main-white);
  padding: 10px 10px 0px 10px;
}

.hg-tabs {
  display: flex;
  font-size: 14px;
}

div.hg-tab {
  margin-right: 15px;
  border-bottom: 2px solid var(--main-white);
  padding-bottom: 8px;
}

div.hg-tab.hg-selected-tab {
  border-bottom: 2px solid var(--secondary-color);
}

div.hg-body {
  padding: 10px;
  // height: calc(
  //   100vh - 81px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
  // );
  // max-height: calc(
  //   100vh - 81px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
  // );
  background: var(--main-white);
}
div.hg-long-term-content {
  width: 100%;
  font-size: 20px;
}

div.hg-short-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.short-t-p {
  color: var(--main-cornflower);
}

div.st-goals-list {
  overflow: auto;
  // height: calc(
  //   100vh - 185px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
  // );
  // max-height: calc(
  //   100vh - 185px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
  // );
}

div.hg-long-term-content {
  // max-height: calc(
  //   100vh - 115px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
  // );
  overflow: auto;
  width: 100%;
}

img.slider-pic {
  height: 200px;
  object-fit: cover;
  object-position: bottom;
}

/* .short-goal-desc div.alert-wrapper.sc-ion-alert-md{
  height: 55%;
}
.short-goal-desc div.alert-message.sc-ion-alert-md{
  font-family: 'Montserrat', sans-serif;
  color : var(--main-text-light);
  font-weight: 300;
}
.short-goal-desc div.alert-button-group.sc-ion-alert-md{
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
}
.short-goal-desc button.alert-button.ion-focusable.ion-activatable.sc-ion-alert-md{
  color: var(--main-orange);
  font-weight: 600;
} */
