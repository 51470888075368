.doc-container {
  .button-wrapper {
    margin-right: 16px;
    margin-left: 5px;
    .backImage-icon {
      height: 20px;
    }
  }
  ion-toolbar {
    --min-height: 60px;
    padding-top: 0.5rem !important;
  }

  .col-6 {
    width: 50%;
  }
  .doc-folder {
    top: 5.5rem;
    min-height: 150px;
    display: flex;
    background-color: #e9f8ff;
    border-radius: 10px;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 96%;
    display: inline-flex;
  }
  .doc-mg-right {
    margin-right: -10px;
  }
  .doc-folder > .name {
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
  .doc-folder > .size {
    margin-bottom: 0px;
    font-size: 14px;
    margin-top: 5px;
  }
  .doc-header {
    min-height: 70px;
    border-bottom: 1px solid grey;
    padding: 18px;
  }
  .no-doc {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    .no-doc-wrapper {
      margin: auto;

      .no-doc-text {
        padding-top: 20px;
        margin: auto;
        width: 200px;
        p {
          font-size: 20px;
          color: #767676;
        }
      }
      .no-doc-img {
        width: 52px;
        height: 48px;
      }
    }
    button {
      width: 155px;
      padding: 12px 15px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
  .document-header-upload-document-wrapper {
    display: flex;
    align-items: center;
    margin-top: -4px;
    p {
      font-weight: 600;
      font-size: 16px;
      color: #171A21;
      margin: 0;
    }
    button {
      font-size: 14px;
      color: white;
      font-weight: 600;
      outline: none;
      border: none;
      border-radius: 25px;
      background-color: #97144D;
      padding: 6px 10px;
      display: block;
      margin-left: auto;
      margin-right: 8px;
    }
  }
}
.document-view-container-wrapper {
  ion-toolbar {
    --min-height: 60px;
  }
  .button-wrapper {
    margin-right: 16px;
    margin-left: 5px;
    .backImage-icon {
      height: 20px;
    }
  }
  .document-view-container {
    // margin-top: 4.7rem;
    padding: 0px;
    .doc-tab {
      padding: 10px;
      color: #171A21;
      font-weight: 500;
      border-bottom: 2px solid rgb(231, 231, 231);
      width: 100%;
      text-align: center;
      margin: 0px;
      cursor: pointer;
    }
    .doc-tab-selected {
      border-bottom: 2px solid #97144D;
    }
  }
  .doc-preview {
    background-image: url("../images/bg_pattern.png");
    border: 1px solid var(--grey-border);
    height: 100%;
    border-radius: 3px;
    a {
      text-decoration: none !important;
    }
    .anchor-cls {
      .doc-preview-img {
        min-height: 100px;
        width: 100%;
        object-fit: cover;
        overflow: hidden;
        margin-bottom: -5px;
      }
    }
    footer {
      padding: 5px;
      height: 40px;
      display: flex;
      margin-top: 5px;
      flex-direction: column;
      border-top: 1px solid var(--grey-border);
      justify-content: flex-end;
      .name-title {
        font-weight: 600;

        font-size: xx-small;
      }
      .xs-footer {
        font-size: 7px;
      }
    }
  }
  .scrollable-doc-viewer {
    height: 90%;
    overflow: auto;
    .no-doc {
      text-align: center;
      padding-top: 45%;
      .no-doc-text {
        margin: auto;
        p {
          font-size: 20px;
          color: #767676;
        }
      }
    }
  }
}

.doc-info-text {
  padding: 2px;
  display: flex;
  flex-direction: column;
  .name-title {
    color: #171A21;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
  }
  .xs-footer {
    color: #171A21;
    font-size: 7px;
    text-decoration: none;
  }
}

.document-header-div {
  display: flex;
}
