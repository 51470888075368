.zyla-wallet-home-quick-link-ele {
  border: 2px solid #e9ecef;
  border-radius: 8px;
  flex: 1;
  overflow: hidden;
  background-color: white;
  .zyla-wallet-home-quick-link-ele-top {
    background-color: #f6f7f9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    position: relative;
    .zyla-wallet-home-quick-link-ele-img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      img {
        width: 28px;
        height: 28px;
      }
    }
    .zyla-wallet-home-quick-link-ele-coming-soon-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      z-index: 50;
      align-items: center;

      p {
        background-color: #ffbd2d;
        color: white;
        font-size: 8px;
        font-weight: 700;
        border-radius: 5px;
        padding: 4px 8px;
        text-align: center;
        width: fit-content;
      }
    }
  }
  .zyla-wallet-home-quick-link-ele-bottom {
    text-align: center;
    padding: 4px 0;
    p {
      font-size: 14px;
      font-weight: 500;
      color: #26284d;
      line-height: 20px;
      margin: 0;
    }
  }
}
