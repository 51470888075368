// .recommended.vital-submit-wrapper {
//   overflow-x: scroll;
//   margin-bottom: 1rem;
//   display: flex;
//   flex-flow: row;

// }

.my__new__class .reading-category-average {
  position: relative;
  display: flex;
  background-color: white;
  padding: 1rem;
  justify-content: space-between;
  border-radius: 8px;
  /* min-width: 330px; */
  margin-right: 4px;
  margin-bottom: 33px;
  width: 100%;
  margin-left: 4px;
  margin-top: 4px;
  img.BPMonitorIcon {
    height: 50px;
  }
  .reading-div {
    display: flex;
    flex-flow: column;
    position: relative;
    width: 70%;
    justify-content: center;
    margin-right: auto;
    margin-left: 1rem;
    .reading-type {
      font-size: 10px;
      text-align: left;
    }
    .reading {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      text-align: left;
    }
    .badge {
      &#out-of-range {
        background-color: #d0021b;
      }
      background-color: #7ac602;
      padding: 0.2rem 0.5rem;
      font-size: 10px;
      font-weight: 900;
      color: white;
      border-radius: 22px;
      position: absolute;
      bottom: 0;
      white-space: nowrap;
      left: 50%;
    }
  }

  .icon {
    height: 50px;
  }
}

.slides_wrapper .swiper-pagination-bullet {
  color: rgb(141, 148, 142) !important;
  width: 23px;
  height: 4px;
  display: inline-block;
  border-radius: 100px;
  background: #33143A72;

  // background: rgba(132, 142, 184, 0.5);
  // opacity: 0.2;
}

.slides_wrapper .swiper-pagination-bullet-active {
  color: rgb(77, 83, 78) !important;
  width: 23px;
  height: 4px;
  display: inline-block;
  border-radius: 100px;
  background: #143A72;

  // opacity: 0.2;
}

.slider_wrapper .swiper-pagination-bullets {
  bottom: -3px !important;
}
