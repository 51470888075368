.family-plan-container {
  .family-plan-header {
    display: flex;
    padding: 14px 8px;
    align-items: center;

    span {
      font-size: 20px;
      font-weight: 600;
      color: #171a21;
      margin-left: 10px;
    }
    img {
      width: 24px;
    }
  }
  .family-plan-loaded {
    height: 100%;
    .family-plan-member-container {
      display: flex;
      flex-direction: column;
      padding: 12px;
      height: 100%;
      .family-plan-member-top {
        font-size: 12px;
        font-weight: 400;
        color: #171a21;
        line-height: 20px;
      }
      .family-plan-member-mid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        .family-plan-member-grid-ele {
          background-color: #f5f5ff;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 12px;
          // max-height: 134px;
          cursor: pointer;
          img {
            width: 60px;
            height: 60px;
            border-radius: 100%;
            margin-bottom: 8px;
            object-fit: cover;
          }
          .family-plan-grid-ele-name {
            font-size: 16px;
            font-weight: 600;
            color: #171a21;
            margin-top: 0;
            margin-bottom: 6px;
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow-y: hidden;
            word-break: break-all;
          }
          .family-plan-grid-ele-relation {
            font-size: 14px;
            font-weight: 400;
            color: #171a21;
            margin: 0;
            text-align: center;
          }
        }
      }
      .family-plan-member-bottom {
        font-weight: 400;
        font-size: 10px;
        color: #171a21;
      }
    }
    .family-plan-no-member-container {
      height: 100%;
      margin-top: 20px;
      padding: 0 40px;
      .family-plan-no-member-top {
        .family-plan-no-member-top-img {
          max-height: 300px;
          width: 100%;
          object-fit: cover;
        }
      }
      .family-plan-no-member-bottom {
        text-align: center;
        padding: 12px 0;
        margin-top: 40px;
        .family-plan-no-member-bottom-top {
          p {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #777777;
          }
        }
        .family-plan-no-member-bottom-mid {
          width: 100%;
          button {
            outline: none;
            border: none;
            background-color: #97144D;
            border-radius: 24px;
            width: 100%;
            color: white;
            padding: 12px 0;
            font-size: 20px;
            font-weight: 600;
          }
        }
        .family-plan-no-member-bottom-bottom {
          margin: 25px -20px;
          p {
            font-size: 10px;
            font-weight: 400;
            color: #171a21;
          }
        }
      }
    }
  }
}
.family-plan-add-family-drawer {
  .MuiPaper-root {
    height: 100%;
  }
}
.add-family-member-container {
  padding: 12px 12px;
  .add-family-member-header {
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .add-family-member-body {
    .add-family-body-relation-section {
      .MuiInputBase-root {
        width: 100%;
        .MuiSvgIcon-root {
          background-image: url("../../assets/icons/chevron-down.svg");

          background-repeat: no-repeat;
          fill: none;
          color: white;
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #777;
        span {
          color: #e44437;
        }
      }
      .MuiInput-underline:after {
        border-bottom: none;
      }
      .MuiInput-underline:before {
        border-bottom: 1px solid #d8d8d8 !important;
      }
      .MuiSelect-select:focus {
        background-color: white !important;
      }
      .MuiInputBase-input {
        font-size: 18px !important;
        font-weight: 300 !important;
        color: black !important;
      }
    }
    .add-family-body-information-section-parent {
      .add-family-body-information-section-heading {
        font-size: 14px;
        font-weight: 400;
        color: #777;
      }
      .add-family-body-information-section {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d8d8d8;
        margin-top: 16px;
        padding-bottom: 6px;

        .add-family-body-information-section-select {
          background-color: transparent !important;
          .MuiSelect-select {
            display: flex;
            align-items: center;
            margin-right: 8px;
            img {
              width: 34px;
              height: 22px;
              margin-right: 12px;
            }
          }
          .MuiSvgIcon-root {
            background-image: url("../../assets/icons/chevron-down.svg");

            background-repeat: no-repeat;
            fill: none;
            color: white;
          }
        }
        .add-family-body-information-section-select::before {
          border-bottom: none;
          background-color: transparent;
        }
        .add-family-body-information-section-select::after {
          border-bottom: none;
          background-color: transparent;
        }
        .add-family-body-information-section-nextIMG {
          width: 24px;
          height: 24px;
        }
        .add-family-body-information-section-input {
          outline: none;
          border: none;
          flex: 1;
          min-width: 0;
          font-size: 18px;
          font-weight: 400;
          color: #464646;
          &:disabled {
            background-color: white;
          }
        }
      }
    }
    .add-family-body-otp-section {
      .add-family-body-otp-section-input {
        outline: none;
        border: none;
        border-bottom: 1px solid #d8d8d8;
        width: 100%;
        padding: 8px 0;
        font-size: 18px;
        font-weight: 400;
        color: #464646;
      }
      .add-family-body-otp-section-heading {
        font-size: 14px;
        font-weight: 400;
        color: #777;
        margin-bottom: 0;
      }
      .add-family-otp-msg {
        font-size: 14px;
        color: #777;
        margin-top: 12px;
        .add-family-otp-msg-2 {
          .otp-msg-resend-button {
            color: #97144D;
            font-weight: 700;
            padding-left: 5px;
          }
          .text-underline-class {
            text-decoration: underline;
          }
        }
      }
    }
    .add-family-last-section {
      text-align: center;
      margin-top: 40px;
      .add-family-last-section-continue {
        padding: 16px 0;
        width: 90%;
        background-color: #97144D;
        color: white;
        font-size: 20px;
        font-weight: 600;
        border-radius: 24px;
        outline: none;
        border: none;
      }
      .add-family-last-section-continue:disabled {
        background-color: #d8d8d8;
      }
      .add-family-last-section-desc {
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }
}

.country-menu-item-class {
  display: flex;
  align-items: center;
  margin-right: 8px;
  img {
    width: 34px;
    height: 22px;
    margin-right: 12px;
  }
}

.family-profile-overview-container {
  background-color: #f0f3f8;
  overflow: scroll;
  height: 100%;
  // transform: translateX(-100%);
  // -webkit-transform: translateX(-100%);
  // animation: slide-in 0.5s forwards;
  // -webkit-animation: slide-in 0.5s forwards;
  .family-profile-overview-header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 16px 12px;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .family-profile-overview-body-top {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 12px 16px 12px;
    overflow: hidden;

    .family-profile-overview-body-top-profilepic {
      border-radius: 50%;
      width: 96px;
      height: 96px;
      margin-bottom: 8px;
      object-fit: cover;
    }
    .family-profile-overview-body-top-name {
      font-weight: 600;
      font-size: 20px;
      color: #171a21;
      margin-top: 0;

      text-align: center;
      margin-bottom: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
    }
    .family-profile-overview-body-top-number {
      font-weight: 400;
      font-size: 16px;
      color: #171a21;
      margin: 0 0 8px 0;
    }
    .family-profile-overview-body-top-relation {
      margin: 0;
      color: #171a21;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .family-profile-body-mid {
    background-color: #6a5ef5;
    text-align: center;
    padding: 4px 0;
    span {
      color: white;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .family-profile-body-bottom {
    padding: 0 12px;

    .family-profile-body-bottom-items {
      display: flex;

      align-items: center;
      cursor: pointer;
      img {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
      p {
        font-size: 16px;
        line-height: 25px;
        font-weight: 600;
        color: #171a21;
      }
    }
  }
  .family-profile-overview-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    .family-profile-overview-circular-progress {
      width: 80px !important;
      height: 80px !important;
      display: flex !important;

      svg {
        circle {
          stroke-width: 2;
          stroke: #143A72;
        }
      }
    }
  }
}

.edit-family-profile-container {
  background-color: white;
  padding: 0 12px;
  animation-name: unseen-seen;
  animation-duration: 300ms;
  // animation-delay: 200ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  .edit-family-profile-header {
    padding: 16px 0;
    display: flex;
    align-items: center;
    width: 100%;
    img {
      width: 24px;
      height: 24px;
    }
    .edit-family-profile-heading {
      font-size: 20px;
      font-weight: 600;
      color: #464646;
      margin-left: 8px;
    }
    button {
      background-color: #97144D;
      color: white;
      font-weight: 600;
      font-size: 16px;
      outline: none;
      border: none;
      border-radius: 16px;
      display: block;
      margin-left: auto;
      padding: 6px 16px;
    }
  }
  .edit-family-profile-middle {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    .edit-family-profile-middle-profilepic {
      width: 92px;
      height: 92px;
      border-radius: 100%;
      background-color: #97144D;
      margin: 0 auto 8px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .edit-family-profile-image {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        button {
          position: absolute;
          bottom: 15px;
          font-size: 14px;
          font-weight: 500;
          color: white;
          left: 0;
          outline: none;
          border: none;
          background-color: rgba(0, 0, 0, 0.1);
          width: 100%;
          text-align: center;
        }
      }
      .edit-family-profile-no-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 100%;
        position: relative;
        margin: auto;
        button {
          outline: none;
          border: none;
          background-color: transparent;
          color: white;
          font-size: 12px;
          font-weight: 400;
          position: absolute;
          bottom: 10px;
        }

        .edit-family-profile-no-image-1 {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .edit-family-profile-no-image-2 {
          width: 32px !important;
          height: 32px !important;
          margin-bottom: 18px !important;
          object-fit: cover;
        }
      }
    }
    .edit-family-profile-middle-fname {
    }
    .edit-family-profile-middle-lname {
    }
    .edit-family-profile-middle-relation {
    }
    .edit-family-profile-middle-gender {
      display: flex;
      flex-direction: column;
      label {
        font-size: 14px;
        font-weight: 400;
        color: #464646;
        margin-bottom: 8px;
        padding-left: 2px;
        span {
          color: #97144D;
        }
      }

      .edit-profile-middle-gender-option-container {
        display: flex;
        margin-bottom: 8px;
        .edit-family-profile-middle-gender-option {
          margin-right: 8px;
          border: 1px solid #777;
          border-radius: 24px;
          background-color: white;
          padding: 4px 8px;
          width: fit-content;
          span {
            color: #777;
          }
        }

        .edit-family-profile-middle-gender-option-selected {
          background-color: #6a5ef5 !important;
          border: none;
          span {
            color: white !important;
          }
        }
      }
    }
    .edit-family-middle-dob {
      ion-datetime {
        padding: 0 0 0 2px;
        margin-bottom: 8px;
      }
    }
    .edit-family-middle-email {
    }
    .edit-profile-common-section {
      display: flex;
      flex-direction: column;
      margin-bottom: 6px;
      border-bottom: 1px solid #d8d8d8;
      width: 100%;
      label {
        font-size: 14px;
        font-weight: 400;
        color: #464646;
        margin-bottom: 8px;
        padding-left: 2px;
        span {
          color: #97144D;
        }
      }
      input {
        padding-bottom: 8px;
        outline: none;
        border: none;
      }
      input:disabled {
        background-color: white;
      }
      .edit-common-group-div {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
          margin-bottom: 4px;
        }
        input {
          flex: 1;
        }
        ion-datetime {
          flex: 1;
        }
      }
    }
  }
  .edit-family-last {
    margin: 16px 0;
    text-align: center;
    button {
      border: none;
      outline: none;
      color: white;
      background-color: #97144D;
      font-weight: 600;

      font-size: 20px;

      padding: 12px;
      border-radius: 25px;
      width: 100%;
    }
    button:disabled {
      background-color: #d8d8d8;
    }
    p {
      padding: 12px 24px;
      font-size: 10px;
      font-weight: 500;
      color: #464646;
    }
  }
}
.family-plan-error {
  background-color: #97144D;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  span {
    font-size: 14px;
    color: white;
    padding: 4px 0;
  }
}

.fp-member-grid-ele-white {
  background-color: white !important;
  border: 2px solid #f5f5ff;
  cursor: pointer;
}

@keyframes unseen-seen {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

.inverse-loader-anime-class {
  animation-name: inverse-loader;
  animation-duration: 5000ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  width: 100%;
  height: 4px;
  background-color: white;
}

@keyframes inverse-loader {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
.MuiSelect-select:focus {
  background-color: white !important;
}

.required-mark-class {
  color: #97144D;
}
