.home-page-wrapper {
  background-color: #f0f3f8;
  height: 100%;

  header {
    height: 64px;
    font-size: 30px;
    background-color: white;
    padding: 1rem;
    font-weight: 500;
    color: #000;
    span {
      font-weight: 900;
    }
  }
  .homepage-container-error-msg {
    position: fixed;
    top: var(--ion-safe-area-top);
    background-color: #d0021b;
    width: 100%;
    text-align: center;
    z-index: 999999;
    padding: 4px 0;
    span {
      font-size: 12px;

      color: white;
    }
  }
  .home-page-components {
    padding: 1rem 12px;
    overflow-y: scroll;

    h1#vitals-heading {
      color: #26284d;
      font-size: 16px;
      font-weight: 700;
      margin: 0;
    }
    .dyn-card {
      position: relative;
      padding: 0.75rem;
      background-color: #fff;
      margin: 1rem 0;
      border-radius: 8px;
      h6 {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
      }
      p {
        margin: 0.5rem 0;
        font-size: 14px;
      }
      img {
        position: absolute;
        top: 0.75rem;
        right: 1rem;
        height: 18px;
        width: 18px;
      }
    }
  }
}
.hidecard {
  display: none;
}
.offer-preview-card-box-heading {
  font-size: 16px;
  font-weight: 700;
  color: #26284d;
}
.offer-preview-card-box {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;

  .offer-preview-card {
    min-width: 96%;
    flex: 1;
    margin-right: 6px;

    .offer-preview-card-container {
      margin-bottom: 1em;
      // padding: 0.75em;

      display: flex;
      flex-direction: column;
      img {
        height: 100%;
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        object-position: top;
      }
      .preview-text {
        width: 50%;
        color: white;
        font-weight: 700;
        font-size: 1.5em;
      }
      .preview-btn {
        display: flex;
        justify-content: space-evenly;
        width: 45%;
        border: 1px solid white;
        border-radius: 100px;
        padding: 0.25em;
        margin-top: 1em;
        .preview-btn-text {
          font-weight: 600;
          color: white;
        }
      }
    }
  }
}

.feature-cards {
  // display: flex;
  // overflow-x: scroll;
  min-height: 180px;

  .swiper-wrapper {
    margin-bottom: 2em !important;
  }

  .feature-card {
    width: 100%;
    padding: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    // text-align: center;
    align-items: center;
    border-radius: 4px;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    min-height: 180px;
    img {
      height: 50px;
      width: 50px;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    .offer-text,
    .feat-subtitle {
      font-size: 12px;
    }
    .offer-text {
      color: #97144D;
    }
    .feat-title {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.meditation-heading-hp {
  display: flex;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #171a21;
  align-items: flex-start;

  button {
    width: 50%;
    outline: none;
    background: none;
    border: none;
    color: var(--main-orange);
    font-size: 16px;
    font-weight: 900;
    text-align: end;
    margin-left: auto;
  }
}
.meditation-container {
  width: 100%;
  height: 100%;

  .modal-wrapper {
    height: 100%;

    .ion-page {
      top: var(--ion-safe-area-top);
      bottom: var(--ion-safe-area-bottom);
    }
  }
}
.home-page-title {
  font-size: 16px;
  font-weight: 700;
  color: #26284d;
  align-items: flex-start;
}
.home-page-title-view-all {
  color: var(--main-orange);
  font-size: 16px;
  font-weight: 900;
  text-align: end;
  margin-left: auto;
}

.homepage-hp-wrapper {
  h1 {
    color: #171a21;
    font-weight: 700;
    font-size: 16px;
    margin-top: 0;
  }
  .homepage-hp-desc {
    font-size: 12px;
    font-weight: 500;
    color: #171a21;
  }
  div.homepage-hp-cards {
    display: flex;
    // display: grid;
    // grid-template-columns: 50% 50%;
    // flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;

    .home-page-hp-card {
      flex: 1;
      background: white;
      border-radius: 1rem;
      border: 2px solid #e9ecef;
      min-width: 45.6%;
      /* height: 45%; */
      padding: 0;
      margin: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      .home-page-hp-card-top {
        padding: 16px;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .home-page-hp-card-bottom {
        background-color: #e9ecef;
        padding: 12px 0;
        width: 100%;

        p {
          text-align: center;
          margin: 0;
          font-size: 12px;
          font-weight: 500;
          color: #171a21;
        }
      }
      h1 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
      }

      &:nth-child(odd) {
        margin-right: 0.25rem;
      }
      &:nth-child(even) {
        margin-left: 0.25rem;
      }
      &:first-child {
        margin-left: 0px !important;
      }
    }
  }
}
.home-page-banner {
  padding: 1rem;
  background: var(--main-cornflower);
  border-radius: 8px;
  color: white;
  position: relative;
  overflow: hidden;
  margin: 1rem 0;
  img.right-arrow {
    width: 36px;
    height: auto;
    position: absolute;
    right: 1rem;
    top: 1rem;
    transform: translateY(-6px);
  }
  h2 {
    width: 70%;
    font-weight: 800;
    margin: 0;
  }
  img.doctors-and-nurses {
    height: 95px;
    position: absolute;
    right: -16px;
    bottom: 0;
  }
  &#personal-hp {
    p {
      margin: 0.2rem;
      font-size: 14px;
    }
  }
  &#personalized-care {
    background: linear-gradient(
      94.33deg,
      #a6743b 19.66%,
      #cfa44f 66.8%,
      #e4c780 108.38%
    );
    p {
      width: 67%;
    }
  }
}

.zylatv-playing-video-backbtn{
  padding: 20px 20px 10px 20px;
}
.zylatv-playing-video-title{
  color: var(--primary-black);
  font-size: 16px;
  font-weight: 700;
  padding: 0px 20px 10px 20px;
}
.zylatv-playing-video {
  display: flex;
  justify-content: center;
}

div.zylatv-wrapper {
  display: flex;
  flex-direction: column;
  h1 {
    color: var(--primary-black);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }
  div.zylatv-thumbnails {
    position: relative;
    border-radius: 10px;
    margin-bottom: 1rem;
    width: 100%;
    overflow: hidden;
    height: 200px;
    .thumbnail {
      min-height: 200px;
      border-radius: 8px;
      min-width: 383px;
      width: 100%;
    }
    img.play-icon {
      position: absolute;
      left: 50%;
      height: 50px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  div.zylatv-videos-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    div.zylatv-video-container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .all-videos {
    background-color: var(--main-orange);
    border-radius: 24px !important;
    color: white;
    --box-shadow: none;
    font-weight: 900;
    letter-spacing: normal;
    text-transform: none;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
}
div.blogs-wrapper {
  display: flex;
  flex-direction: column;
  h1 {
    color: var(--primary-black);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }
  div.blog-thumbnails {
    position: relative;
    border-radius: 8px;
    margin-bottom: 1rem;
    background-color: white;
    overflow: hidden;
    box-shadow: 0px 6.193660259246826px 8.258214950561523px 0px #0000000d;

    img.thumbnail {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    div.wrapper {
      padding: 0rem 1rem 1rem 1rem;
      overflow: hidden;
      div.text-wrapper {
        overflow: hidden;
        h2 {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;

          /* Main */
          color: var(--primary-black);
        }
        p {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #979797;
          height: 22px;
          --webkit-line-clamp: 3;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .all-videos {
    background-color: var(--main-orange);
    border-radius: 24px !important;
    color: white;
    --box-shadow: none;
    font-weight: 900;
    letter-spacing: normal;
    text-transform: none;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
}
#banner-heading {
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  /* Main */
  font-weight: bold;
  color: #000000;
}

.vital-stats-wrapper {
  overflow-x: scroll;
  display: flex;
  flex-flow: row;

  .reading-category-average {
    position: relative;
    background: #6a5ef5;
    padding: 1rem;
    border-radius: 8px;
    margin: 1rem 0 2rem 0;
    min-width: 95%;
    margin-right: 0.5rem;
    cursor: pointer;

    .chevron-right {
      position: absolute;
      height: 18px;
      width: 12px;
      top: 1rem;
      right: 1rem;
    }
    .category {
      display: inline-flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      img {
        display: inline;
        height: 14px;
        &#Waistline {
          height: 8px;
        }
      }
      span {
        color: #7ee3ef;
        font-size: 12px;
        margin-left: 0.5rem;
        font-weight: 800;
        white-space: nowrap;
      }
    }
    .reading-div {
      display: flex;
      flex-flow: column;
      margin-top: 0.5rem;
      .average {
        font-size: 14px;
        font-weight: 500;
        color: white;
      }
      .average-reading {
        font-size: 16px;
        font-weight: 800;
        color: white;
      }
    }
    .date {
      font-size: 12px;
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      color: white;
    }
  }
}

.vital-submit-wrapper {
  overflow-x: scroll;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row;
  .reading-category-average {
    position: relative;
    display: flex;
    background-color: white;
    padding: 1rem;
    justify-content: space-between;
    border-radius: 8px;
    min-width: 330px;
    margin-right: 0.5rem;
    img.BPMonitorIcon {
      height: 50px;
    }
    .reading-div {
      display: flex;
      flex-flow: column;
      position: relative;
      width: 70%;
      justify-content: center;
      margin-right: auto;
      margin-left: 1rem;
      .reading-type {
        font-size: 10px;
      }
      .reading {
        font-size: 16px;
        color: #000;
        font-weight: 600;
      }
      .badge {
        &#out-of-range {
          background-color: #d0021b;
        }
        background-color: #7ac602;
        padding: 0.2rem 0.5rem;
        font-size: 10px;
        font-weight: 900;
        color: white;
        border-radius: 22px;
        position: absolute;
        bottom: 0;
        white-space: nowrap;
        left: 50%;
      }
    }

    .icon {
      height: 50px;
    }
  }
}

.homepage__slides {
  padding: 35px;
  word-wrap: break-word;
}

.home__testimony__slide {
  height: 300px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  display: flex;
  flex-direction: column;
}
.slide__img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px #97144D;
  margin: 10px;
  padding: 5px;
}

.slide__img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.img__wrapper {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.slide__review__text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.slide__review__name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

// ion-slides {
//   .swiper-pagination-bullet-active {
//     colore:blue !important;
//   }
// }

.homepage__slides .swiper-pagination-bullet {
  color: rgb(141, 148, 142) !important;
  width: 23px;
  height: 4px;
  display: inline-block;
  border-radius: 100px;
  background: #80828ea4;
  // background: rgba(132, 142, 184, 0.5);
  // opacity: 0.2;
}
.homepage__slides .swiper-pagination-bullets {
  bottom: 0 !important;
}

.homepage__slides .swiper-pagination-bullet-active {
  color: rgb(77, 83, 78) !important;
  width: 23px;
  height: 4px;
  display: inline-block;
  border-radius: 100px;
  background: #828ea4;
  // opacity: 0.2;
}
.homepage__slides .swiper-wrapper {
  margin-bottom: 15px !important;
}

.premium__text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.healthcard__text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.healthcard__title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}

.steps-tracker-wrapper {
  margin-bottom: 15px;
  box-shadow: 0px 7.123015880584717px 9.497355461120605px 0px #0000000d;

  .steps-tracker-detail {
    position: relative;
    display: flex;
    background-color: white;
    padding: 0.8rem;
    justify-content: space-between;
    border-radius: 8px;
    min-width: 330px;
    margin-right: 0.5rem;
    flex-direction: column;
    .steps {
      display: flex;
      margin-bottom: 10px;
      .icon__wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;
        img {
          width: 21px;
          height: 17px;
        }
        span {
          font-size: 12px;
          font-weight: 600;
          color: #97144D;
        }
      }
      img {
        height: 18px;
      }
    }
    .reading-div {
      display: flex;

      .reading-div-left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .message {
          font-size: 12px;
          font-weight: 400;
        }
        .steps-reading {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .progress-bar {
      }
    }
  }
}

.sleep-tracker-wrapper {
  position: relative;
  margin-bottom: 15px;
  .sleep-tracker-detail {
    position: relative;
    display: flex;
    background-color: white;
    padding: 0.8rem;
    justify-content: space-between;
    border-radius: 8px;
    min-width: 330px;
    margin-right: 0.5rem;
    flex-direction: column;
    .sleep {
      display: flex;
      margin-bottom: 10px;
      .icon__wrapper {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
        img {
          width: 21px;
          height: 17px;
        }
        span {
          font-size: 12px;
          font-weight: 600;
          color: #6a5ef5;
          margin-left: 3px;
        }
      }
      img {
        height: 18px;
      }
    }
    .reading-div {
      display: flex;
      align-items: flex-end;
      .reading-div-left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .message {
          font-size: 12px;
          font-weight: 400;
        }
        .sleep-reading {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .progress-bar {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.daily-target-wrapper {
  .daily-target-heading {
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
    span {
      font-size: 16px;
      font-weight: 700;
      color: #26284d;
    }
  }
  .daily-target-header {
    font-size: 16px;
    font-weight: 700;
    color: #26284d;
    margin: 0 0 10px 0;
  }
  .daily-target-msg {
    font-size: 14px;
    font-weight: 400;
    color: #26284d;
    margin: 8px 0;
  }
  .subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    button {
      outline: none;
      border-radius: 30px;
      border: 1px solid gray;
      background-color: white;
      padding: 1px 17px;
      font-size: 13px;
      width: fit-content;
      height: 24px;
      color: #26284d;
      font-weight: 500;
      word-break: keep-all;
    }
    .button-active {
      border: 1px solid #6a5ef5;
      background-color: #6a5ef5 !important;
      color: white;
    }
  }
}

.start-tracking {
  background-color: #97144D;
  color: white;
  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 47%;
  height: 22px;
  z-index: 100;
  p {
    text-align: center;
    color: white;
    font-size: 12px;
    font-weight: 700;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.daily_target_value_tag {
  &#out-of-range {
    background-color: #e44437;
  }
  background-color: #1bc47d;
  padding: 0.2rem 0.5rem;
  width: fit-content;
  text-align: center;
  height: fit-content;
  border-radius: 22px;
  color: white;

  font-size: 10px;
  font-weight: 900;

  margin-left: 5px;
}
.daily_target_value_wrapper {
  display: flex;
  align-items: center;
}

.daily-target-vital-wrapper {
  position: relative;
  margin-bottom: 15px;
  .daily-target-vital-submission {
    position: relative;
    display: flex;
    background-color: white;
    padding: 0.8rem 0.8rem 0.5rem 0.8rem;
    justify-content: space-between;
    border-radius: 8px;
    min-width: 330px;
    margin-right: 0.5rem;
    flex-direction: column;
    .vital-submission-upper-div {
      display: flex;
      align-items: center;
      .upper-div-left {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 13px;
          height: 11px;
        }
        span {
          padding-left: 5px;
          font-size: 12px;
          color: #7ee3ef;
        }
      }
      img {
        height: 18px;
      }
    }
    .vital-submission-lower-div {
      display: flex;
      align-items: center;
      padding-top: 5px;
      .lower-div-left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        .recommended-heading {
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
        }
        .vital-name {
          font-size: 18px;
          font-weight: 700;
          color: #000000;
        }
        .vital-name-container {
          .vital-name-span {
            font-size: 18px;
            font-weight: 700;
            color: #000000;
            position: relative;
            .daily-target-range-badges-container {
              position: absolute;
              right: -10%;
            }
          }
        }
      }
    }
    .lower-div-right {
      img {
        height: 40px;
      }
    }
  }
}

.daily-target-range-badges {
  &#out-of-range {
    background-color: #e44437;
  }
  background-color: #1bc47d;
  padding: 0.2rem 0.5rem;
  font-size: 10px;
  font-weight: 900;
  color: white;
  border-radius: 22px;
  position: absolute;
  bottom: 15%;
  white-space: nowrap;
}

.ask-zyla-wrapper {
  height: 250px;

  .ask-zyla-container {
    background-color: white;
    display: flex;
    height: 206px;
    .ask-zyla-left {
      position: relative;
      width: 40%;
      padding-top: 14px;
      text-align: center;
      img {
        height: 100%;
      }
      .ask-zyla-left-top {
        .ellipse {
          position: absolute;
          left: 17px;
          width: 93%;
          height: 87%;
          bottom: 20px;
        }
        .circle {
          position: absolute;
          bottom: 15px;
          left: 8px;
          width: 65px;
          height: 65px;
        }
      }
      .ask-zyla-hand {
        position: absolute;
        bottom: 0;

        width: 100%;
        height: 88%;
        left: 0;
      }
      .ask-zyla-chat {
        position: absolute;
        bottom: 0;

        width: 100%;
        height: 88%;
        left: 0;
      }
    }
    .ask-zyla-right {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      .ask-zyla-right-top {
        .top {
          font-weight: 700;
          font-size: 16px;
          color: #26284d;
          font-size: 16px;
          line-height: 28px;
          span {
            padding-right: 2px;
          }
        }
        .bottom {
          font-weight: 400;
          font-size: 14px;
          color: #26284d;
          line-height: 20px;
        }
      }
      .ask-zyla-right-bottom {
        .ask-zyla-button {
          background-color: #97144D;
          border-radius: 25px;
          text-align: center;
          font-weight: 600;
          color: white;
          padding: 5px 0 5px 0;
          margin-top: 15px;
        }
      }
    }
  }
}

.recipe_drawer_container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  text-align: left;
  .recipe_drawer_header {
    position: relative;
    position: sticky;
    width: 100%;
    top: 0;
    height: 330px;
    .recipe_drawer_header_img {
      width: 100%;
      height: 330px;
      min-height: 330px;
      max-height: 330px;
    }
    .recipe-close-icon {
      position: absolute;
      top: 25px;
      right: 5px;
      width: 26px;
      height: 26px;
    }
    .recipe_drawer_header_timing {
      position: absolute;
      bottom: 20%;
      left: 10px;
      color: white;
      background-color: #6a5ef5;
      width: fit-content;
      padding: 5px 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      img {
        margin-right: 7px;
      }
    }
    .recipe_drawer_header_cooking_tag {
      position: absolute;
      bottom: 5%;
      left: 10px;
      border-radius: 4px;
      padding: 10px 15px;
      background-color: #97144D;
      color: white;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .recipe_name_div {
    padding: 10px;
    position: sticky;
    top: 330px;
    background-color: white;

    .recipe_name_div_top {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      div {
        font-size: 20px;
        font-weight: 600;
        margin-right: 15px;
      }
    }
    .recipe_name_div_bottom {
      width: 50px;
      height: 5px;
      border-radius: 22px;
      margin: 15px 0 0 0;
      background-color: #97144D;
    }
  }
  .recipe_drawer_main {
    padding: 10px;

    .recipe_drawer_main_ingredients {
      .recipe_drawer_main_ingredients_header {
        display: flex;
        flex-direction: column;
        justify-content: left;
      }
      .recipe_drawer_list {
        font-size: 16px;
        font-weight: 300;
        color: #464646;
        margin-top: 2px;
        list-style: 1;
        li {
          margin-bottom: 2px;
        }
      }
    }
  }
  .recipe_drawer_main_methods {
    .method_drawer_list {
      font-size: 16px;
      font-weight: 300;
      color: #464646;
      margin-top: 2px;
    }
    li {
      margin-bottom: 2px;
    }
  }
}

.recipe-container {
  .recipe-container-header {
    font-weight: 700;
    font-size: 16px;
    color: #26284d;
    margin-top: 20px;
    img {
      height: 16px;
      width: 16px;
      margin-right: 10px;
    }
  }
  .recipe-card {
    display: flex;
    flex-direction: column;
    .recipe-card-image {
      border-radius: 8px 8px 0 0;
      max-height: 300px;
    }
    margin-top: 1em;
    margin-bottom: 2em;
  }
  .recipe-card-detail {
    display: flex;
    background: white;
    padding: 1em;
    border-radius: 0px 0px 8px 8px;
    margin-top: 0;
    align-items: flex-start;
    .recipe-card-detail-left {
      flex: 1;
    }
    .recipe-card-type-symbol {
      width: 20px;
      height: 20px;
    }
  }
  .recipe-name {
    font-weight: 700;
    font-size: 16px;
    color: #26284d;
  }
  .recipe-duration {
    display: flex;
    align-items: center;
    img {
      height: 20px;
      width: 20px;
    }
    .recipe-duration-text {
      margin-left: 0.25em;
      font-size: 1em;
    }
  }
}

.recipe__card__drawer {
  height: 100% !important;
  top: var(--ion-safe-area-top);
  .MuiBackdrop-root {
    top: var(--ion-safe-area-top);
    bottom: var(--ion-safe-area-bottom);
  }
  .MuiPaper-root {
    overflow-y: scroll;
    width: 100%;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    top: var(--ion-safe-area-top);
    bottom: var(--ion-safe-area-bottom);
    box-shadow: none;
  }
  .recipe-card-drawer-icon {
    position: absolute;
    top: 25px;
    left: 10px;
    display: flex;
    align-items: center;
    z-index: 10;
    img {
      margin-right: 10px;
      width: 36px;
      height: 36px;
    }
    span {
      color: white;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.recipe-card-horizontal-line {
  height: 1px;
  width: 100%;
  background-color: #d8d8d8;
  border-radius: 100%;
}
.recipe_drawer_ion_slides {
  width: 100% !important;
  .swiper-pagination {
    position: absolute;
    top: 10px;
    bottom: 0 !important;
    height: fit-content !important;
    display: flex;
    justify-content: space-between;
    .swiper-pagination-bullet {
      flex: 1;
      height: 4px;
      border-radius: 2px;
    }
    .swiper-pagination-bullet-active {
      background: white;
    }
  }
}

.recipe-image-drawer-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 20;
  align-items: center;
  .recipe-image-drawer-container-close-icon {
    position: absolute;
    top: 25px;
    right: 10px;
    width: 26px;
    height: 26px;
  }
  .recipe-image-drawer-container-main-img {
    z-index: 25;
    width: 100%;
  }
}
.no_data_alert {
  .no_data_alert_ok {
    color: #143A72;
  }
}

.home-yoga-page-modal {
  top: var(--ion-safe-area-top) !important;
  bottom: var(--ion-safe-area-bottom) !important;
  .modal-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.zyla-home-wallet-know-more-button {
  outline: none;
  background-color: white;
  border: 0.5px solid #6a5ef5;
  border-radius: 8px;
  width: 100%;
  padding: 12px 14px 12px 14px;
  font-size: 12px;
  font-weight: 700;
  color: #26284d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}
.zyla-home-wallet-redeem-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-bottom: 16px;
}
.zyla-offer-home-healthserviceTiles-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  .zyla-offer-home-healthserviceTiles-ele {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    cursor: pointer;
    .zyla-offer-home-healthserviceTiles-ele-top {
      margin-bottom: 12px;
      .zyla-offer-home-healthserviceTiles-ele-top-img-wrapper {
        border-radius: 50%;
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f3f5;
        img {
          width: 26px;
          height: 26px;
        }
      }
    }
    .zyla-offer-home-healthserviceTiles-ele-bottom {
      .zyla-offer-home-healthserviceTiles-ele-bottom-subtitle {
        font-size: 11px !important;
        font-weight: 400 !important;
        line-height: 12px;
      }
      p {
        margin: 0;
        color: #26284d;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
.zyla-home-wallet-redeem-heading {
  font-size: 16px;
  font-weight: 700;
  color: #26284d;
  line-height: 16px;
}

.start-tracking-android {
  display: flex;
  align-items: center;
  padding: 12px;
  width: fit-content;
  background-color: white;
  box-shadow: 1px 1px 0 #d8d8d8;
  img {
    margin-right: 12px;
    width: 16px;
    height: 16px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: gray;
  }
}

.homepage-pfizer-slider {
  .slick-next {
    right: -5px !important;
  }
  .slick-prev {
    left: -10px !important;
  }
  .slick-dots li button::before {
    font-size: 9px;
  }
  .slick-dots li.slick-active button:before {
    color: #97144D;
    font-size: 9px !important;
  }

  // [data-index="1"] {

  //   .dynamic-content-container:first-of-type {
  //     margin-left: 4px;
  //   }

  // }
  .slick-dots li {
    margin: 0 !important;
  }
}

.mli-doctor-card-class {
  background: linear-gradient(
    142.53deg,
    rgba(225, 223, 253, 0.5) 9.6%,
    rgba(255, 255, 255, 0.5) 114.93%
  );
  display: flex !important;
}
