.submitted-reporting {
  .heading {
    color: var(--main-text-light);
    font-weight: 600;
    .page-head {
      display: flex;
      align-items: center;
      // padding: 15px 18px;
      height: 10vh;

      div.reporting-header {
        width: 80%;
        margin-left: 20px;
        color: var(--primary-black);
      }
    }

    img.back-img {
      height: 13px;
      padding-right: 15px;
    }
    .reporting-date {
      display: flex;
      align-items: center;
      ion-datetime {
        --padding-start: 0px;
        --padding-top: 0px;
        --padding-bottom: 0px;
      }
      img.img-down {
        margin-top: -2px;
        margin-left: 9px;
        width: 25px;
        height: 25px;
      }
    }
  }
  .heading::after {
    background-image: none;
  }

  .reporting-detail {
    table {
      margin-top: 20px;
      width: 100%;

      .table-header {
        color: #97144D;
        font-weight: 500;
        td:first-child {
          width: 40%;
          height: 40px;
          padding-left: 20px;
        }
        td:last-child {
          width: 60%;
        }
      }
      .table-row {
        width: 60%;
        td {
          text-align: center;
        }
        td:last-child {
          text-align: left;
        }
      }
      td {
        padding: 2px;
      }
    }
  }
  .no-reporting-container {
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      width: 50px;
    }
    .display-message {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 21px;
      color: #171A21;
      width: 72%;
      text-align: center;
    }
    .button {
      outline: none;
      border: none;
      background-color: #97144D;
      color: white;
      border-radius: 20px;
      padding: 10px 20px;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;
    }
  }
}
.img-down-flex {
  display: flex;

}