.rating-star-rating-dialog-container {
  max-width: 500px;
  .rating-star-rating-dialog-screen-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    .rating-star-rating-dialog-screen-1-top {
      position: relative;
      // .rating-star-top-rating-screen-1-top-main-img {
      //   width: 100%;

      //   object-fit: cover;
      //   background-color: #f0effe;
      // }
      .rating-star-top-rating-screen-1-top-star-img {
        position: absolute;
        bottom: -36px;
        width: 72px;
        height: 72px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 50;
      }
    }
    .rating-star-rating-dialog-screen-1-bottom {
      padding: 42px 20px 24px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .rating-star-rating-dialog-screen-1-bottom-top {
        font-size: 18px;
        font-weight: 700;
        color: #464746;
        text-align: center;
        margin-top: 0;
      }
      .rating-star-rating-dialog-screen-1-bottom-mid {
        font-size: 16px;
        font-weight: 600;
        color: rgba(70, 71, 70, 0.6);
        text-align: center;
        margin-top: 0;
      }
    }
    .rating-star-rating-dialog-screen-1-bottom-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      .rating-star-rating-dialog-screen-1-bottom-remind {
        color: #909190;
        font-weight: 500;
        font-size: 16px;
        background-color: transparent;
        margin-top: 16px;
      }
    }
  }

  .rating-star-rating-dialog-screen-1-2-star-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .rating-star-rating-dialog-screen-1-2-star-list-ele {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 48px;
        height: 48px;
      }
      p {
        font-size: 10px;
        font-weight: 700;
        color: #26284d;
      }
    }
  }

  .rating-dialog-screen-2-mid {
    display: flex;
    align-items: center;
    img {
      margin-right: 6px;
      width: 24px;
      height: 24px;
    }
    p {
      font-size: 16px;
      font-weight: 700;
      color: #464746;
      text-align: center;
    }
  }
  .rating-dialog-screen-2-mid-text {
    font-size: 18px;
    font-weight: 600;
    color: rgba(70, 71, 70, 0.6);
    text-align: center;
  }
  .rating-dialog-screen-2-top-text {
    font-size: 22px;
    font-weight: 700;
    color: #464746;
    text-align: center;
    margin-bottom: 0;
    margin-top: 12px;
  }
  .rating-dialog-screen-2-bottom {
    border-bottom: 1px solid black;
    width: 100%;
    [contenteditable] {
      -webkit-user-select: text;
      user-select: text;
    }
    p:empty:not(:focus)::before {
      content: attr(data-placeholder);
      color: rgba(70, 71, 70, 0.6);
    }
    p:focus::before {
      content: "";
    }
    p {
      color: #464746;
      font-size: 16px;
      overflow-y: auto;
      max-height: 100px;
      font-weight: 500;
      outline: none;
      word-wrap: break-word;
      word-break: break-word;
      max-width: 100%;
      width: 100%;
      overflow-x: hidden;
      margin: 0 0 4px 0;
    }
  }

  .rating-star-rating-dialog-screen-1-bottom-submit {
    background-color: #97144D;
    color: white;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding: 6px 18px;
    border-radius: 16px;
    margin-top: 16px;
    width: fit-content;
  }
}

.rating-button-common-css {
  border: none;
  outline: none;
  cursor: pointer;
}
.rating-dialog-error {
  color: #d0021b;
  font-size: 12px;
  font-weight: 600;
}
