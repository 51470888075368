.menu-partial
  div.MuiPaper-root.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
  height: 70%;
}

.menu-partial-full-height
  div.MuiPaper-root.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
  height: 100%;
}

#partial-menu-modal {
  .modal-wrapper {
    .ion-page {
      overflow: scroll !important;
    }
  }
}

div.partial-menu-content {
  background-color: #fff;
  bottom: 0;
  height: 100%;
 
  max-height: 600px;
  overflow-y: scroll;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.menu-partial-medium {
  .modal-wrapper {
    position: absolute;
    bottom: 0px;
    max-width: 560px;

    background-color: transparent;
  }
  .ion-page {
  
    background-color: transparent;
  }
}

div.partial-menu-content a {
  text-decoration: none;
}

div.menu-item {
  border: 1px solid var(--grey-border);
  padding: 7px;
  border-radius: 38px;
  display: flex;
  align-items: center;
  height: 60px;
  margin: 10px 0px;
}

div.menu-item-text {
  width: 70%;
  padding: 12px 10px;
  color: #464646;
  height: 100%;
  font-weight: 600;
  .menu-sub-text {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0;
    color: #d0021b;
  }
}

.todo-text {
  padding: 5px 0px;
  color: var(--grey-light);
  font-weight: 600;
}

.todo-heading {
  color: #5d5d5d;
  font-weight: 600;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

div.partial-menu-open-state {
  display: flex;
  justify-content: center;
}

img.partial-menu-icon {
  height: 100%;
}

div.partial-icon-menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

img.partial-menu-header {
  height: 16px;
  float: right;
  margin-left: auto;
}
label.partial-menu-header {
  height: 16px;
  float: right;
  margin-right: 16px;
}
.mn-10 {
  margin-top: -10px;
}
