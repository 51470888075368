.meditation-chat-card {
  display: flex;
  position: relative;
  .meditation-container {
    margin-left: 10px;
    width: 100%;
    position: relative;
    .meditation-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(216, 216, 216, 0.56);
      border-radius: 10px;
      img {
        position: absolute;
        top: 10%;
        right: 5%;
      }
    }
    .media-container {
      margin: 0px !important;
      background: linear-gradient(
          rgba(186, 186, 186, 0.2),
          rgba(186, 186, 186, 0.2)
        ),
        url("../../assets/images/bg_pattern.png");
      width: 100%;
    }
  }
}
.meditation-container {
  position: relative;

  div.meditation-heading {
    color: var(--main-text-light);
    font-weight: 600;
    font-size: 18px;
    margin-left: 1rem;
    margin-bottom: 4px;
  }

  div.meditation-page-head {
    display: flex;
    align-items: center;
    padding: 15px 18px;
    height: 10vh;
  }

  img.back-img {
    height: 40px;
    padding: 10px 10px 10px 0px;
  }

  div.meditation-body {
    padding: 15px 18px;

    .meditaion-header {
      position: relative;
      background: #f0f0f0;
      border-radius: 8px;
      height: 10rem;
      overflow: hidden;
      z-index: 5;
      padding: 1rem;
      font-size: 1rem;
      line-height: 24px;
      button {
        background: #97144D;
        border-radius: 28px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
        padding: 0.5rem 1rem;
        margin-top: 2rem;
      }
      img {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 7rem;
        z-index: -1;
      }
    }
  }

  div.media-container {
    width: 100%;
    height: 11rem;
    margin: 15px 0px;
    border-radius: 10px;
    position: relative;
  }

  img.media-thumbnail {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }



    img.playing-current-media {
      height: 100%;
      object-fit: cover;
    }

  div.media-content-data {
    padding: 15px;
    width: 100%;
    height: 100%;
    position: absolute;
    padding-top: 16px;
    padding-bottom:16px;
    overflow: hidden;
    max-width: 560px;
  }

  img.back-icon {
    height: 40px;
    padding: 10px 10px 10px 0px;
  }

  div.media-list-data {
    position: absolute;
    // top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // padding-top: 90px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    padding-bottom: 11px;
  }

  div.content-data {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  img.common-img {
    height: 50px;
    max-width: 50px;
    bottom: 0;
  }

  img.common-img-small {
    height: 65px;
  }

  img.audio-option {
    height: 30px;
  }

  div.audio-controls {
    padding: 0px 45px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  div.controls-media {
    width: 100%;
    .audio-progress-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      ion-label {
        min-width: 50px;
      }
      .MuiSlider-colorSecondary {
        color: #97144D;
      }
    }
  }

  div.media-text-content {
    color: #ffffff;
  }

  div.media-title {
    /* font-weight: bold; */
    font-size: 20px;
  }

  div.paying-media-data {
    margin-top: 25px;
    color: #ffffff;
    width: 90%;
    background-color: gray;
    padding: 10px;
    border-radius: 5px;
    max-width: 460px;
    @media screen and (min-width: 600px) {
      padding: 24px;
    }
  }

  div.title-playing {
    font-size: 32px;
    font-weight: 500;
    padding-bottom: 5px;
  }

  div.description-playing {
    font-size: 17px;
  }

  div.playing-page-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 95%;

    .locked-meditation-container {
      padding: 15px;

      .meditation-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          #000000 2.28%,
          rgba(0, 0, 0, 0.71) 34.85%,
          rgba(255, 255, 255, 0.71) 102.28%
        );
        mix-blend-mode: multiply;
        opacity: 0.7;
        top: 0;
        left: 0;
        z-index: 10;
      }
      .locked-meditation-info {
        animation-duration: 2s;
        animation-name: slideUp;
        transition: transform ease-in;
        .locked-meditation-info-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #97144D;
          padding: 10px;
          border-radius: 25px;
          margin-bottom: 15px;
          z-index: 15;
          position: relative;
          img {
            margin-right: 10px;
          }
          span {
            color: white;
            font-weight: 700;
            font-size: 16px;
          }
        }
        .locked-meditation-info-desc {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          margin-bottom: 15px;
        }
      }
    }
  }
  ion-range {
    --bar-background: gray;
    --bar-background-active: rgb(255, 129, 48);
    --bar-height: 4px;
  }
  .med-loading-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-wrapper {
    width: 40px;
    .button-wrapper-ripple-button {
      z-index: 15;
    }
  }
}

.media__language {
  background-color: white;
  color: black;
  border-radius: 25px;
  font-weight: 700;
  font-size: 8px;
  padding: 8px 6px;
  width: fit-content;
  line-height: 0;
}

.recommended-tag {
  position: absolute;
  color: white;
  background-color: #d0021b;
  left: 3%;
  top: 12%;
  z-index: 99;
  width: max-content;

  border-radius: 4px;
  padding: 2px 3px;
}

.meditation-container-playing-drawer {
  .MuiPaper-root {
    box-shadow: none;
/* height: 100%; */
top: var(--ion-safe-area-top) ;
bottom: var(--ion-safe-area-bottom);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(200%);
  }

  to {
    transform: translateY(0);
  }
}
