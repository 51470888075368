.modal-page {
  background: #f0f3f8;
  height: 100%;
  overflow-y: scroll;
  .modal-page-header {
    position: sticky;
    top: 0;
    left: 0;
    height: 2.5em;
    background-color: white;
    font-weight: 600;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    z-index: 10;
    img {
      margin: 0 0.5em;
      height: 1em;
      width: 1em;
    }
  }
  .page-body {
    background: #f0f3f8;

    .date-btns {
      display: flex;
      .date-btn {
        background-color: white;
        font-weight: 700;
        padding: 0.75em 2em;
        margin: 0.75em;
        border-radius: 23px;
        font-size: 16px;
      }
      .disabled-button {
        background-color: #d8d8d8 !important;
        color: white;
      }
      .selected-opt {
        background-color: #97144D;
        color: white;
      }
    }
    .yoga-banner-inactive {
      visibility: hidden;
    }
    .yoga-banner {
      background: linear-gradient(142.53deg, #5c44b5 9.6%, #143A72 114.93%);
      border-radius: 8px;
      margin: 0.5em 1em;
      padding: 1em 1em;
      color: white;
      display: flex;
      .banner-head {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .banner-body {
        font-size: 12px;
        width: 80%;
        font-weight: 400;
      }
    }
    .yoga-cards {
      margin-bottom: 8em;
    }
    .yoga-option-card {
      margin: 1em 1em;
      display: flex;
      flex-direction: column;
      // box-shadow: 1px 1px 30px 0px rgba(50, 50, 50, 0.4);
      border-radius: 8px;
      position: relative;
      .yoga-option-card-img {
        object-fit: cover;
      }
      .yoga-card-toolbar {
        font-weight: 700;
        color: black;
        height: 3em;
        background-color: white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        border-radius: 0 0 8px 8px;
        .disabled-button {
          // background-color: #d8d8d8 !important;
          color: #d8d8d8;
        }
        .yoga-card-toolbar-div {
          flex: 0.5;
          text-align: center;
        }
        .yoga-card-toolbar-share-border {
          border-right: 1px solid #d8d8d8;
          height: 100%;
        }
      }
      .yoga-title {
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        padding: 10px 5px;
        background-color: white;
        border-top: 2px solid #f0f3f8;
        border-bottom: 2px solid #f0f3f8;
      }
      .yoga-live {
        position: absolute;
        top: 5%;
        left: 5%;
        background-color: #d0021b;
        color: white;
        font-size: 10px;
        text-align: center;
        height: fit-content;
        padding: 2px 10px;
        border-radius: 3px;
      }
    }
  }
}
