.booking-history-container {
  padding: 12px 12px;
  .booking-history-back-button {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 700;
      color: var(--ion-primary);
      margin-left: 8px;
      margin-top: 12px;
    }
  }
  .booking-history-card-container {
    display: flex;
    row-gap: 16px;
    flex-direction: column;
    .booking-history-card {
      padding: 16px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 8px;
      box-shadow: 0px 7.12302px 9.49736px rgba(0, 0, 0, 0.05);
      .booking-history-card-ele {
        display: grid;
        grid-template-columns: 70% 30%;
        p {
          color: var(--primary-black);
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
          margin: 0;
        }
        .status-completed {
          color: green !important;
        }
        .status-cancelled {
          color: red !important;
        }
        .status-ongoing {
          color: orange !important;
        }
        .booking-card-price {
          color: var(--primary-zburgundy) !important;
          font-size: 12px !important;
          font-weight: 700 !important;
        }
      }
    }
  }
}
