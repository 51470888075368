$small: 668px;
.tracker-container {
  .tracker_chart_blue_box {
    background: white;

    padding: 16px;

    > span {
      color: #171a21;
      font-size: 1.2rem;
      line-height: 28px;
      font-weight: 600;
    }

    .back_arrow {
      margin: -0.5% 1%;
      width: 30px;
      height: 24px;
    }
  }
  .tracker-chart-graph-container {
    height: 100%;
  }

  .tracker_chart_chart_box {
    height: 65%;
    width: 90%;
    color: black;

    background: white;
    border-radius: 2.5%;
    left: 5%;

    padding: 5% 0%;
    margin: 24px auto;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2) !important;

    @media only screen and (max-height: $small) {
      height: 55%;
    }
    .date_section {
      display: flex;
      justify-content: center;
      gap: 20px;
      padding-bottom: 15px;

      > p {
        width: max-content;
        margin: 0;
        font-size: 18px;
        display: inline-flex;
        font-weight: 600;
        place-self: center;
      }

      .date_button {
        height: 32px;
        width: 32px;
      }
    }

    .chart-tracker {
      height: 100% !important;
      width: 100% !important;
      padding-bottom: 40px;
    }
  }

  .tracker_chart_average_vitals {
    position: relative;

    display: flex;
    place-items: center;
    // bottom: 2%;
    left: 0;
    right: 0;

    hr {
      display: block;
      width: 1px;
      height: 50px;
      background: rgb(54, 54, 54);
      border: 0;
      border-top: 1px solid black;
      margin: 1em 0;
      padding: 0;
    }
  }

  .tracker_value {
    height: max-content;
    width: max-content;
    float: left;
    margin: 0 auto;
    
    > span {
      // text-transform: uppercase;
      font-weight: 400;
      font-size: 12px;

      > strong {
        text-transform: none;
      }
    }

    > p {
      font-size: 20px;
      margin: 0;
      font-weight: 600;
    }
  }

  .scroll_tab1 {
    color: #ffffff;
    border: none;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 700;
    padding: 2px;
    margin: 2px;
    --background-checked: #97144D;
    --color-checked: transparent;
    text-transform: none;
  }

  // scroll tab onclick text color change
  .orange_box {
    background: #97144D;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      color: white;
      font-weight: 900;
      font-size: 18px;
      height: max-content;
    }
  }

  .loading-wrapper {
    height: 100vh;
    width: 100vw;
    z-index: 100;
    position: absolute;
    background: white;

    ion-spinner {
      z-index: 100;
      position: absolute;
      top: 47%;
      left: 45%;
    }
  }

  .no-vital-box {
    height: 79%;
    width: 90%;
    color: black;
    position: absolute;
    background: white;
    border-radius: 2.5%;
    left: 5%;
    top: 17.5%;
    padding: 5% 0%;
    margin: 0 auto;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2) !important;

    @media only screen and (max-height: $small) {
      height: 71%;
      top: 25%;
    }
  }

  .no-vital-box-inner-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90%;
    padding: 40px;
    p {
      text-align: center;
      color: #777777;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
    }
  }

  .no-vital-main-img {
    height: 50%;
  }

  .no-vital-link-div {
    width: 100%;
    background-color: #97144D;
    color: white;
    font-size: 18px;
    font-weight: 6000;
    padding-left: 20px;
    padding-right: 20px;
    height: 39%;
    border-radius: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-decoration: none;
  }

  .vital-chart-span {
    color: #171a21;
    font-size: 20px;
    line-height: 28px;
    padding-left: 6px;
    font-weight: 600;
  }

  .stats__tracker {
    position: absolute;
    bottom: 0;
    display: flex;
  }
}
