.session-check-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 36px 20px;
  align-items: center;
  .session-check-heading {
    font-size: 20px;
    font-weight: 700;
    color: #26284d;
  }
  .session-check-footer {
    font-size: 20px;
    font-weight: 500;
    color: #26284d;
    text-align: center;
  }
}
