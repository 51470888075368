$small: 668px;

.blue_box {
  background: white;
  min-height: 40%;
  padding: 10px;
  padding-right: 0%;

  > span {
    color: white;
    font-size: 1.2rem;
    line-height: 28px;
    font-weight: 600;
  }

  .back_arrow {
    margin: -0.5% 1%;
    width: 30px;
    height: 24px;
  }
}

.chart_box {
  height: 65%;
  width: 90%;
  color: black;
  position: absolute;
  background: white;
  border-radius: 2.5%;
  left: 5%;
  top: 14.5%;
  padding: 5% 0%;
  margin: 0 auto;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2) !important;

  @media only screen and (max-height: $small) {
    height: 55%;
    top: 25%;
  }
  .date_section {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 15px;

    > p {
      width: max-content;
      margin: 0;
      font-size: 18px;
      display: inline-flex;
      font-weight: 600;
      place-self: center;
    }

    .date_button {
      height: 32px;
      width: 32px;
    }
  }

  .chart {
    height: 100%;
    width: 100%;
    padding-bottom: 40px;
  }
}
.average_vitals_container {
  position: relative;
  top: 45%;
  height: 20%;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  align-items: center;
  .average_vitals {
    // bottom: 2%;
    display: flex;
    width: 100%;
    align-items: center;
    hr {
      display: block;
      width: 1px;
      height: 50px;
      background: rgb(54, 54, 54);
      border: 0;
      border-top: 1px solid black;
      margin: 1em 0;
      padding: 0;
    }
  }
}

.vitals_value {
  height: max-content;
  width: max-content;
  float: left;
  margin: 0 auto;

  > span {
    text-transform: uppercase;
    font-size: 12px;

    > strong {
      text-transform: none;
    }
  }

  > p {
    font-size: 20px;
    margin: 0;
    font-weight: 600;
  }
}

.scroll_tab {
  font-size: 14px;
  border-radius: 5px;
  color: #171a21;
  font-weight: 500;
  padding: 2px;
  margin: 2px;

  --background-checked: #ffffff;
  --color-checked: #143a72;
  --border-color: #143a72;
  --border-style: solid;
  --border-width: 2px;
}
.segment-button-checked {
  border-color: #143a72;
  color: #143a72;
  font-weight: 700;
  .segment-button-indicator {
    border-color: #143a72;
  }
}
.scroll_tab:hover {
  border-color: #143a72;

  ion-label {
    color: #143a72 !important;
    //--color-checked: #143a72;
  }
}

// scroll tab onclick text color change
.orange_box {
  background: #97144D;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: white;
    font-weight: 900;
    font-size: 18px;
    height: max-content;
  }
}

.loading-wrapper {
  height: 100vh;
  width: 100vw;
  z-index: 100;
  position: absolute;
  background: white;

  ion-spinner {
    z-index: 100;
    position: absolute;
    top: 47%;
    left: 45%;
  }
}

.no-vital-box {
  height: 79%;
  width: 90%;
  color: black;
  position: absolute;
  background: white;
  left: 5%;
  top: 14.5%;
  padding: 5% 0%;
  margin: 0 auto;

  @media only screen and (max-height: $small) {
    height: 71%;
    top: 25%;
  }
}

.no-vital-box-inner-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  padding: 20px;
  p {
    text-align: center;
    color: #777777;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
  }
}

.no-vital-main-img {
  height: 50%;
}

.no-vital-link-div {
  width: 100%;
  background-color: #97144D;
  color: white;
  font-size: 18px;
  font-weight: 600;
  height: 39%;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-decoration: none;
  text-align: center;
}

.vital-chart-span {
  color: #171a21;
  font-size: 20px;
  line-height: 28px;
  padding-left: 6px;
  font-weight: 600;
}
.vital-share-container {
  position: sticky;
  background-color: #97144D;
  left: 0;
  bottom: 0;
  .vital-chart-share-progress {
    padding: 15px;
    text-align: center;
    font-size: 20px;
    color: white;
    font-weight: 600;
    width: 100%;
  }
}
