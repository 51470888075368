img.tick-icon-card {
  height: 50px;
  margin: 10px;
}

.message-content {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  background-color: var(--main-cornflower);
  color: white;
  font-weight: 800;
}

div.vitals-head-name {
  color: white;
  background: var(--main-cornflower);
  display: inline;
  padding: 5px;
  border-radius: 12px;
  font-weight: 600;
}

div.vitals-item {
  font-size: 0.9em;
  font-weight: 600;
  margin: 7px 0px;
}

.content-card {
  // border: 1px solid var(--secondary-color);
  background-color: white;
  border-radius: 16px;
  min-height: 100px;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.text-to-left {
  text-align: left;
}

.gap-below-sections {
  padding-bottom: 20px;
}

div.date-details {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 75%;
  .text-to-left {
    font-size: 14px;
  }
}

div.content-of-card {
  padding: 10px;
}

.tick-container {
  width: 25%;
  text-align: center;
}

//reports
.reporting-list {
  font-weight: 600;
  display: flex;
  font-weight: 600;
}

.time-report {
  min-width: 90px;
  width: 90px;
  text-align: left;
}

.info-report {
  text-align: left;
}

.partial-view {
  max-height: 145px;
  overflow: hidden;
}

// .card-radius-fix {
//   border-radius: 0px 0px 16px 16px;
//   border-bottom: 1px solid #143A72;
// }
.card-border-radius-fix {
  border-left: 1px solid #143A72;
  border-right: 1px solid #143A72;
  // border-bottom: 1px solid #143A72;
}

.card-border-fix {
  border-left: 1px solid #143A72;
  border-right: 1px solid #143A72;
  border-bottom: 1px solid #143A72;
}

.card-border-vital-fix {
  border-left: 1px solid #143A72;
  border-right: 1px solid #143A72;
}

.card-radius-fix {
  border-radius: 0px 0px 16px 16px;
}

div.show-options {
  width: 100%;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  color: var(--main-cornflower);
}

div.request-card-body {
  // margin-left: 10px;
  // border: 1px solid var(--grey-border);
  background-color: white;
  border-radius: 10px;
  min-height: 200px;
  &.custom-message {
    min-height: 35px !important;
    min-width: 240px;
    a.ancor-cls {
      border: 1px solid var(--secondary-color);
      border-radius: 15px;
      display: block;
      overflow: hidden;
      text-decoration: none;
      height: 98%;
    }
    .video-container {
      position: relative;
      video {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .video-mask {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 60px;
          opacity: 0.8;
        }
      }
    }
  }
}

.request-card-container {
  display: flex;
  min-height: 200px;
  &.custom-message {
    min-height: 0;
  }
}

div.request-text-card {
  font-size: 18px;
  text-align: center;
  padding: 10px 0px;
  color: var(--secondary-color);
}

div.request-message-content {
  padding: 7px;
  border-bottom: 1px solid var(--grey-border);
  white-space: pre-wrap;
  font-weight: 500;
  font-size: 16px;
  &#cm-body {
    border-bottom: none;
    word-break: break-word;
    font-weight: 500;
    font-size: 16px;
  }
}

img.assesment-completed-tick {
  height: 90px;
  display: flex;
  border: 3px solid var(--main-white);
  border-radius: 999px;
}

.assesment-completed-container {
  width: 13rem;
  float: right;
}

.assesment-completed-container-parent {
  // border: 1px solid var(--secondary-color);
  background-color: var(--main-cornflower);
  border-radius: 8px;
  padding: 20px;
}

.congratulations-text {
  text-align: left;
  font-weight: bold;
  padding: 5px 0px;
  font-size: 14px;
  color: var(--main-white);
}

.info-text-completeted {
  text-align: left;
  font-size: 13px;
  color: var(--main-white);
}

.assesment-image-text {
  position: relative;
  right: 50%;
  width: 125px;
  color: white;
  margin-top: 13px;
}

.start-button {
  font-weight: 600;
  background: var(--main-orange);
  width: 100%;
  text-align: center;
  color: white;
  padding: 10px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 18px;
  .btn-clz {
    color: white;
    word-break: break-all;
  }
}

.assesment-started-container {
  display: flex;
}

.assesment-start-text {
  // border-left: 1px solid var(--grey-border);
  // border-right: 1px solid var(--grey-border);
  background-color: white;
  padding: 7px;
  word-break: break-word;
  font-weight: 500;
  font-size: 16px;
}

.information-chat {
  border-radius: 8px;
  // border: 1px solid var(--grey-border);
  background-color: white;
  img.background {
    // width: 100%;
    max-height: 12rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    min-width: 100%;
    object-fit: cover;
  }
  .information-card-share {
    position: relative;
    .information-card-share-below {
      display: flex;
      padding: 20px 10px;
      align-items: center;
      height: 40px;
      span {
        font-size: 12px;
        color: #979797;
        font-weight: 400;
        flex: 1;
      }
      img {
        width: 60px;
        height: 25px;
      }
    }
    .information-card-share-above {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px 0;

      background-color: #97144D;
      color: white;
      width: 100%;
      z-index: 5;
      font-size: 18px;
      font-weight: 600;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      img {
        width: 16px;
        margin-right: 15px;
      }
    }
  }
}

// div.information-card-content {
//   // padding: 10px;
// }
div.information-chat-title {
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}

div.information-chat-summary {
  word-break: break-word;
  font-weight: 500;
  font-size: 16px;
}

div.share-whatsapp-button {
  color: var(--main-white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: var(--main-orange);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  a {
    color: var(--main-white);
    text-decoration: none;
  }
}

div.data-info {
  padding: 10px;
}

div.doctor-notes-content {
  position: relative;
  // left: 50px;
  // border: 1px solid var(--grey-border);
  background-color: white;
  border-radius: 8px;
  ul,
  p {
    margin-bottom: 15px;
  }
}

div.doctor-information {
  padding: 15px 15px 8px 8px;
  display: flex;
  align-items: flex-start;
  position: relative;
  // right: 50px;
  // width: 17rem;
}

#doctor-notes {
  justify-content: space-around;
  align-items: center;
  right: 30px;
  width: 15rem;
}

#doctor-notes-img {
  height: 60px;
  width: 60px;
  flex: 0.5;
  border: 1px solid var(--secondary-color);
}

.doc-width-fix {
  width: 150px !important;
}

div.doc-img {
  height: 50px;
  border: 1px solid var(--secondary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 3px;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  margin: -2px 20px 20px 5px;
}

div.notes-content {
  padding: 15px 15px 15px 15px;
  border-top: 1px solid var(--grey-border);
  color: var(--main-text-light);
  font-weight: 600;
  font-size: 16px;
  ul {
    padding-left: 15px;
    li {
      padding-top: 10px;
      span {
        font-size: 17px !important;
      }
      ins {
        text-decoration: none;
      }
    }
  }
}

img.image-doc {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  min-width: 100%;
  min-height: 100%;
}

div.doc-info {
  .fs12 {
    font-weight: 600;
  }
}

div.card-margin-fix {
  // margin-top: -2em;
}

div.call-notes-text {
  font-size: 15px;
  font-weight: 600;
  color: var(--secondary-color);
}

#blue-text {
  font-weight: 600;
  color: var(--secondary-color);
}

div.doc-desc {
  color: grey;
  font-size: 0.8em;
  word-break: break-word;
}

div.hp-card {
  display: flex;
  min-height: 150px;
}

img.zyla-logo {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  margin-left: 10px;
}

div.hp-head-card {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 10px 0px;
  background: var(--main-cornflower);
  display: flex;
  justify-content: space-between;
  height: 150px;
}

img.hp-card-img {
  height: 100%;
}

div.hp-detail {
  display: flex;
  flex-direction: column;
}

div.new-card {
  background: var(--main-orange);
  color: var(--main-white);
  padding: 1px 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

div.pl {
  padding-left: 20px;
  color: var(--main-white);
}

.level {
  padding-top: 25px;
  color: var(--main-white);
}

div.but-hp-card {
  padding: 10px 0px;
  text-align: center;
  color: var(--main-orange);
  // border: 1px solid var(--grey-border);
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

div.body-hp-card {
  // border-right: 1px solid var(--grey-border);
  // border-left: 1px solid var(--grey-border);
  background-color: white;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
}

img.icon-card {
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  &#custom-image {
    max-height: 300px;
  }
}

div.task-parent {
  display: flex;
  justify-content: flex-end;
  div.task-completion {
    text-align: center;
    padding: 35px 25px;
    border: 1px solid var(--secondary-color);
    width: 15rem;
    border-radius: 8px;
    img.task-completed-tick {
      height: 36px;
      margin-bottom: 25px;
    }
    div.task-completed-title {
      font-weight: 500;
    }
    div.task-completed-summary {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}

img.camera-icon {
  height: 30px;
}

img.send-key {
  height: 30px;
  width: 30px;
  margin-left: 10px;
}

div.document-card {
  min-height: 200px;
  .document-card-holder {
    display: flex;
    border-radius: 15px;
    overflow: hidden;
    .doc-preview {
      width: 100%;
      .pdf-border {
        background-color: #143A72;
      }
      .img-border {
        height: 105%;
      }
      .anchor-cls {
        text-decoration: none;
        // border: 1px solid var(--main-cornflower);
        display: block;
        text-decoration: none;

        .doc-info {
          width: 100%;
          padding: 10px 10px 10px 5px;
          color: white;
          font-size: 1.05rem;
          font-weight: 800;
          background-color: var(--main-cornflower);
        }
        .doc-preview-img {
          min-width: 80%;
          object-fit: cover;
          overflow: hidden;
          width: 100%;
          min-height: 300px;
          max-height: 300px;
        }
        .border-t {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          border: 1px solid var(--main-cornflower);
        }
        .border-all {
          border-radius: 15px;
          border: 1px solid var(--main-cornflower);
        }
      }
      .doc-preview-title {
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        .title {
          text-decoration: none;
          font-size: 12px;
          word-break: break-all;
          height: 45px;
          color: var(--main-text-light);
          text-align: left;
          padding-left: 10px;
        }
      }
    }
  }
}
.uploading-document-card-container {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  .uploading-doc-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;
    width: 100%;
    .uploading-doc-preview-img {
      width: 100%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-top: 1px solid var(--main-cornflower);
      border-left: 1px solid var(--main-cornflower);
      border-right: 1px solid var(--main-cornflower);
      .uploading-doc-preview-img-parent {
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 250px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        img {
          width: 120px;
          filter: opacity(0.5);
          padding: 10px;
        }
      }
    }
    .uploading-doc-preview-info {
      width: 100%;
      padding: 10px 10px 10px 5px;
      color: white;
      font-size: 1.05rem;
      font-weight: 800;
      display: flex;
      align-items: center;

      background: var(--main-cornflower);
      img {
        width: 15px;
        height: 18px;
        margin-right: 10px;
      }
      span {
        text-align: left;
      }
    }
  }
  .uploading-image-preview {
    min-width: 100%;
    max-height: 300px;

    img {
      width: 100%;

      filter: opacity(30%);
      border-radius: 15px;
      border: 1px solid var(--main-cornflower);
    }
  }
  .upload-doc-loading-spinner {
    .upload-doc-loading-spinner-active-container {
      background-color: transparent;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      min-height: 100px;
      .upload-doc-loading-spinner-active {
        position: absolute;
        background: rgba(70, 70, 70, 0.5);
        width: 80px;
        height: 80px;
        border-radius: 100%;
        img {
          width: 48px;
          height: 48px;
          margin: 0 auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .MuiCircularProgress-root {
          width: 80px !important;
          height: 80px !important;
          svg {
            circle {
              stroke-width: 2;
              stroke: white;
            }
          }
        }
      }
    }
    .upload-doc-loading-spinner-passive {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .failed-document-upload {
    display: flex;
    flex-direction: column;
    padding: 16px 5px 5px 5px;
    align-items: flex-end;
    .failed-document-upload-top {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .failed-document-upload-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 12px;
        position: relative;
        .failed-document-upload-left-top {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;

          padding: 20px;
          width: fit-content;
          border-radius: 8px;
          img {
            width: 32px;
            height: 32px;
          }
        }
        .failed-document-upload-left-top-img {
          width: 72px;
          height: 72px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }
        }
        .failed-document-upload-left-top-close {
          position: absolute;
          top: 0;
          left: 0;
          background-color: #d0021b;
          border-radius: 100%;
          text-align: center;
          padding: 4px;
          transform: translate(-30%, -30%);
          display: flex;
          img {
            width: 14px;
            height: 14px;
          }
        }
      }
      .failed-docunment-upload-right {
        display: flex;
        flex-direction: column;
        .failed-document-upload-right-top {
          margin-bottom: 8px;
          color: #000000;
          font-size: 12px;
          font-weight: 600;
        }
        .failed-document-upload-right-bottom {
          background-color: #97144D;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          border-radius: 4px;
          img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }
          span {
            color: white;
            font-weight: 500;
            font-size: 12px;
          }
        }
      }
    }
    .failed-document-upload-bottom {
      width: 100%;
      text-align: left;
      .failed-document-upload-left-bottom {
        color: #000;
        font-weight: 500;
        font-size: 8px;
      }
    }
  }
}

div.medicine-order-card {
  min-height: 200px;
  .medicine-order-card-holder {
    display: flex;
    #order-container {
      width: 100%;
      border-radius: 15px;
      overflow: hidden;
      border: 1px solid #0000001f;
      div {
        .doc-preview {
          width: 100%;
          a {
            display: block;
            overflow: hidden;
            text-decoration: none;
            height: 98%;
            .doc-preview-img {
              min-width: 80%;
              height: 100%;
              object-fit: cover;
              overflow: hidden;
              width: 100%;
              display: flex;
            }
          }
          .doc-preview-title {
            display: flex;
            padding-left: 10px;
            padding-right: 10px;
            .title {
              text-decoration: none;
              font-size: 12px;
              word-break: break-all;
              height: 45px;
              color: var(--main-text-light);
              text-align: left;
              padding-left: 10px;
            }
          }
        }
        #document-title {
          display: flex;
          padding: 10px;
          // border-left: 1px solid #0000001f;
          // border-right: 1px solid #0000001f;
          background-color: white;
          a {
            padding: 0 5px;
            font-size: 10px;
            overflow: hidden;
            text-decoration: none;
            color: black;
          }
          img {
            height: 20px;
          }
        }
        #disc-text {
          background: #51ac95;
          color: white;
          height: 54px;
          padding: 2.5%;
          font-weight: 700;
        }
        button {
          width: 100%;
          height: 36px;
          background: var(--main-orange);
          color: white;
          font-weight: 800;
          border: none;
          outline: none;
        }
      }
    }
  }
}

.error-message {
  .MuiSnackbarContent-root {
    background-color: rgb(230, 69, 69);
  }
}

.fix-border {
  a {
    background-color: #97144D;
  }
}

.my-blue-border-t {
  img {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  border: 1px solid var(--main-cornflower);
}

.message.message-left.document-card
  .document-card-holder
  .doc-preview.document-chat-card {
  .anchor-cls.pdf-border {
    background-color: white !important;
    border: none !important;
    .border-t {
      border: none !important;
    }
    .doc-info {
      background-color: inherit !important;
      border-top: 1px solid #d8d8d8;
      .doc-info-text {
        .name-title {
          color: #464646 !important;
        }
      }
    }
  }
}
