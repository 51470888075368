div.infotab-title {
  padding: 10px;
  border-bottom: 1px solid var(--secondary-grey);
}

div.infotab-data {
  margin-top: 8px;
  background: var(--main-white);
}

div.profile-page-container {
  div.back-button-wrapper {
    width: 70px;
    height: 60px;
    img {
      height: 43px !important;
      padding: 10px;
    }
  }
  header {
    padding-top: 1rem;
  }
}

img.icon-profile {
  height: 100%;
}

div.tab-fields-profile {
  display: flex;
  align-items: center;
}

div.icon-cont-profile {
  height: 25px;
  min-width: 40px;
}

div.placeholder-profile {
  color: cyan;
}

.separator {
  border-bottom: 1px solid var(--secondary-grey);
}

div.inner-tab-fields {
  display: flex;
  min-height: 50px;
  width: 100%;
  padding: 12px 0px 0px 10px;
}

div.profile-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 210px;
  padding: 0px 30px 30px 30px;
}

img.avatar-profile {
  height: 56px;
  width: 56px;
  object-fit: cover;
  border-radius: 50%;
}

div.tab-content {
  width: 100%;
  display: flex;
  align-items: center;
}

.placehol-profile {
  color: #3a3a3a;
  font-weight: 600;
}

input.data-profile {
  padding: 12px 0px;
  border: none;
  width: 100%;
  border-bottom: 1px solid var(--grey-border);
  outline: none;
  margin-bottom: 10px;
  color: var(--main-text-light);
  font-weight: 100;
}

div.edit-profile {
  padding: 10px 0px 10px 10px;
  margin-bottom: 100px;

  input::-webkit-input-placeholder,
  input::placeholder,
  input::-moz-placeholder,
  input:-moz-placeholder,
  input::-ms-input-placeholder {
    font-size: 14px;
    color: rgba(70, 70, 70, 0.3);
  }
}

div.edit-head {
  border-bottom: 1px solid var(--grey-border);
}

div.edit-save {
  width: 25%;
}

.bk-icon {
  height: 40px;
  width: 44px;
  margin-right: 10px;
  padding: 10px;
}

div.header-edit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-edit-title {
    width: 160px;
  }
}

.upload-img-avatar {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 50%;
}

div.img-backcover {
  position: relative;
  height: 150px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  overflow: hidden;
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
}

div.edit-image {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 32px;
  opacity: 1;
  border-radius: 4px;
  padding: 5px;
  color: var(--main-white);
  text-align: center;
  min-width: 50px;
  background-color: rgba(0, 0, 0, 0.1);
}

div.upload-img-content {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

div.profile-data-content {
  @media screen and (min-width: 600px) {
    padding: 20px;
  }
}

div.edit-profile-data {
  height: calc(100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
  max-height: calc(
    100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
  );

  .error-box {
    height: 25px;
    display: flex;
    align-items: center;
    background-color: #e64545;
    color: white;
    font-size: 14px;
    padding-left: 15px;
    position: fixed;
    width: 100%;
    justify-content: center;
    transition: opacity 4s ease-in-out;
    opacity: 1;
  }

  .loading-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

div.gender-tabs {
  display: flex;
  padding: 12px 0px;
}

.selected-gender-tab {
  color: var(--main-white);
  background: var(--secondary-color);
  background-color: #143A72;
  border: 1px solid #143A72 !important;
}

div.gender-tab {
  margin-right: 10px;
  border: 1px solid var(--grey-light);
  border-radius: 16px;
  padding: 4px 10px;
}

div.dob-info {
  border-bottom: 1px solid var(--grey-border);
  margin-bottom: 10px;
  font-weight: 700;
  color: #171a21;
}

.address-sub-head {
  margin-bottom: 10px;
}

div.profile-address {
  padding: 12px 0px;
}

div.address-add-text {
  color: var(--secondary-color);
}

div.district-state-info {
  display: flex;
}

img.lock-profile {
  height: 22px;
  margin-right: 10px;
}

div.contact-ip-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--grey-border);
}

input.data-profile.contact.fs18.fw-light {
  margin-bottom: 0px;
  border: none;
}

.edit-profile-loading-class {
  position: relative;
}
