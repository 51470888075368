.freemium-membership-banner {
  background-color: #143a72;
}

.premium-membership-banner {
  background: linear-gradient(to right, #a6743b, #cfa44f, #cfc4a6);
}

.membership-banner-common {
  color: white;

  font-size: 15px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zyla-offer-wrapper {
  h2 {
    font-size: 16px;
    font-weight: 700;
    color: #26284d;
  }
  .access-box-container {
    background-color: white;
    margin: 0 -1rem 0 -1rem;
    .access-box {
      padding: 20px;
      .access-code-heading {
        color: #000000;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
      }
      .label {
        font-size: 12px;
        font-weight: 400;
      }
      .access-box-input-div:focus {
        border-bottom: 1px solid #97144D !important;
      }

      .access-box-input-div {
        display: flex;
        margin-top: 12px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d8d8d8;
        input {
          outline: none;
          flex: 1;
          border: none;
        }
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .health-service-scroll-container {
    display: flex;
    overflow-x: scroll;
  }
  .cards {
    min-width: 155px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 0px;
    position: relative;
    flex: 1;
    .custom-color {
      color: #ffe370;
    }
    img {
      width: 75px;
      height: 75px;
      margin-bottom: 10px;
    }
    .name {
      font-size: 20px;
      font-weight: 600;
      color: white;
    }
    .save {
      font-size: 40px;
      font-weight: 600;
      margin: 0 0 -11px 0;
    }
    .discount {
      font-size: 52px;
      font-weight: 600;
      margin: 0;
    }
    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .overlay {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      background-color: rgba(216, 216, 216, 0.45);
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  .medicine-card {
  }
  .diagnostic-card {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .dental-card {
  }
  .eye-card {
  }
}

.expert-consultation {
  display: flex;
  flex-direction: column;
  .expert-consultation-heading {
    display: flex;
    .expert-consultation-heading-left {
      flex: 1;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
      p {
        font-size: 16px;
        font-weight: 700;
        color: #26284d;
      }
    }
    p {
      font-size: 16px;
      font-weight: 700;
      color: #97144D;
    }
  }
  .expert-consultation-doctor-list {
    display: flex;
    overflow-x: scroll;
    margin-bottom: 16px;
    .expert-consultatnt-cards {
      display: flex;
      flex-flow: column;
      min-width: 231px;
      background: #fff;
      color: #464646;

      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.107478);
      /* Inside Auto Layout */
      margin-right: 16px;

      height: 126px;
      overflow: hidden;
      .expert-consultatnt-card-wrapper {
        padding: 0.5rem;
        overflow: hidden;
        border-bottom: 0.5rem solid white;
        div {
          display: inline-flex;
          flex-direction: row;
          img.expert-consultant-doctor-image {
            height: 32px;
            width: 32px;
            display: inline-flex;
            border-radius: 10000px;
            object-fit: cover;
          }
          div {
            flex-direction: column;
            margin-left: 0.5rem;
            justify-content: center;
            h3,
            p {
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              /* identical to box height */
              letter-spacing: -0.03em;
              margin: 0;
            }
            p {
              font-size: 8px;
              line-height: 10px;
            }
          }
        }
        p {
          font-size: 12px;
          display: -webkit-box;
          /* max-width: 200px; */
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}

.health-program-card {
  display: flex;
  flex-direction: column;
  margin: 10px 7px 10px 0;
  min-width: 96%;
  .health-program-upper {
    display: flex;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
    height: 59px;
    align-items: center;
    .health-program-img-wrapper {
      background-color: #fff;
      min-width: 83px;
      min-height: 83px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin-right: 12px;
      margin-left: 18px;
      z-index: 5;
      box-shadow: 0px 0px 10px #b9d8da;
    }
    .health-program-upper-right {
      display: flex;
      align-items: center;
      flex: 1;
      p {
        font-size: 16px;
        color: #324667;
        font-weight: 700;
        margin-right: 19px;
        text-align: left;
        flex: 1;
      }
      .health-program-expand-icon {
        width: 36px;
        height: 36px;
      }
    }
  }
  .health-program-middle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: height 2s ease-in-out;
    span {
      color: #324667;
      font-weight: 700;
    }
    .health-program-middle-list-container {
      ul {
        padding: 0 0 0 20px;
        font-size: 14px;
        color: #324667;
        font-weight: 500;
      }
    }
  }
  .health-program-lower {
    min-height: 89px;

    position: relative;
    .health-program-price-banner {
      position: absolute;
      right: -7px;
      top: 33%;
      .health-program-rectangular-banner {
        position: relative;
        .banner-polygon {
          position: absolute;
          right: 1px;
          bottom: -4px;
        }
        #price {
          position: absolute;
          /* top: 0; */
          bottom: 21%;
          left: 25%;
          font-size: 18px;
          font-weight: 600;
          color: #1f3f77;
        }
        #duration {
          position: absolute;
          /* top: 0; */
          bottom: -11px;
          left: 24px;
          font-size: 12px;
          color: #1f3f77;
        }
      }
    }
    .health-program-lower-list-div {
      ul {
        li {
          max-width: 50%;
          font-size: 16px;
          color: #324667;
          text-align: left;
        }
      }
    }
    .health-program-lower-buy-now-button {
      background-color: #97144D;
      color: white;
      margin-left: 20px;
      margin-bottom: 10px;
      padding: 5px 10px;
      border-radius: 8px;
      width: fit-content;

      span {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .health-program-lower-buy-button-2 {
    padding: 10px 10px;
    background-color: #97144D;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-align: center;
    span {
      font-size: 16px;
      color: white;
      font-weight: 700;
    }
  }
}

.recommended-healthcare-program-container {
  margin-bottom: 20px;
  .recommended-program-message {
    display: flex;
    align-items: flex-start;
    .recommended-health-title {
      width: 75%;
    }
  }
  .personalized-care-message {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin-bottom: 25px;
  }
  .zyla-offers-recommended-program {
    width: 100%;
    overflow: hidden;
    .zyla-offer-recommended-program-slides {
      .swiper-pagination-bullet {
        color: rgb(141, 148, 142) !important;
      }
      .swiper-pagination-bullets {
        bottom: 0 !important;
      }

      .swiper-pagination-bullet-active {
        color: rgb(77, 83, 78) !important;
      }
      .swiper-wrapper {
        margin-bottom: 15px !important;
      }
    }
  }
}

.genralised-black-font {
  font-size: 16px;
  font-weight: 700;
  color: #26284d;
}

.genralised-orange-font {
  font-size: 16px;
  font-weight: 700;
  color: #97144D;
  text-align: end;
}

.freemium-healthplan-container {
  background: linear-gradient(to bottom right, #14b8c0, #94d9d9);
  border-radius: 8px;
  width: 100%;
  height: 320px;
  padding: 20px;
  position: relative;

  .healthplan-desc {
    color: white;
  }
  .freemium-healthplan-container-bottom {
    background-color: white;
    margin: 0 -20px 0 -20px;
    border-top: 2px solid #ffc474;
    border-bottom: 2px solid #ffc474;
  }
  .icon-image {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  .health-option-wrapper {
    display: flex;
    align-items: center;
    width: 50%;
    margin-left: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    span {
      font-weight: 600;
      color: #000000;
    }
  }
  .health-option-container {
    display: flex;
    justify-content: center;
  }
  .health-plan-rectangle {
    position: absolute;
    top: 27%;
    left: -2.3%;
    width: 175px;
  }
  .health-plan-polygon {
    position: absolute;

    top: 40.5%;
    left: -2.48%;
  }
  .healthplan-heading {
    margin-bottom: 68px;
    font-size: 16px;
    font-weight: 700;
    color: white;
  }
  .healthplan-price-tag {
    position: absolute;
    font-weight: 700;
    font-size: 16px;
    color: #1f3f77;
    top: 31%;
    left: 8%;
    z-index: 5;
  }
}
.dental-page-container {
  background-color: #f0f3f8;
  height: 100%;
  display: flex;
  flex-direction: column;

  .dental-page-header {
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: white;
    .dental-page-header-left {
      display: flex;
      align-items: center;
      flex: 1;
      span {
        margin-left: 8px;
        font-size: 20px;
        color: #464646;
        font-weight: 600;
      }
    }
    .dental-page-header-right {
      background-image: linear-gradient(
        94.33deg,
        #a6743b 19.66%,
        #cfa44f 66.8%,
        #e4c780 108.38%
      );
      height: 12px;
      padding: 10px 5px;
      width: max-content;
      display: flex;

      margin-top: 0;
      align-items: center;
      border-radius: 4px;
      span {
        font-size: 10px;
        font-weight: 500;

        color: white;
      }
    }
  }
  .dental-page-content {
    padding: 10px;

    overflow-y: scroll;
    flex: 1;

    .dental-page-content-banner {
      img {
        margin: 10px 0px;
        width: 100%;
      }
    }

    .dental-page-benefits {
      display: flex;
      flex-direction: column;
      margin-top: 5px;

      .dental-page-benefits-heading {
        font-weight: 700;
        font-size: 20px;
      }
      .dental-page-benefit-list {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
        margin-bottom: 10px;
        img {
          width: 42px;
          height: 42px;
          margin: 0px;
        }
        span {
          flex: 1;
          display: inline-block;
          font-size: 16px;
          margin-left: 10px;
          .clover-dental-link {
            color: #143A72;
            font-weight: 700;
            text-decoration: none;
          }
        }
      }
    }
    .dental-page-content-banner-div {
      background: linear-gradient(299.01deg, #9ad9c7 -27.25%, #159f79 96.3%);
      display: flex;
      width: 100%;
      border-radius: 12px;
      position: relative;
      // height: 40vw;
      max-height: 200px;
      margin-bottom: 15px;
      margin-top: 5px;
      .dental-page-content-banner-div-left {
        flex: 0.5;
        img {
          height: 100%;
        }
      }
      .dental-page-content-banner-div-right {
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        flex: 0.5;
        justify-content: center;
        span {
          color: white;
        }
        .dental-page-content-banner-div-right-top {
          .dental-page-content-banner-div-right-top-span-1 {
            // font-size: 3.8vw;
            font-weight: 700;
            display: block;
          }
          .dental-page-content-banner-div-right-top-span-2 {
            // font-size: 4.5vw;
            font-weight: 500;
            display: block;
          }
          .dental-page-content-banner-div-right-top-span-3 {
            // font-size: 4.5vw;
            font-weight: 500;
            display: block;
          }
        }
        .dental-page-content-banner-div-right-bottom {
          display: flex;
          align-items: center;
          margin-top: 7px;
          img {
            width: 50%;
            height: 70%;
          }
          .dental-page-content-banner-div-right-bottom-right {
            margin-left: 5px;
            span {
              font-size: 14px;
              font-weight: 700;
              display: block;
            }
            @media screen and (max-width: 500px) {
              span {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
  .dental-page-button {
    position: relative;

    width: 100%;
    background-color: #97144D;
    padding: 15px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
}

.health-services-heading {
  margin-top: 0;
}
