.navigo-booking-container {
  .navigo-booking-container-back-button {
    padding: 12px 10px 12px 10px;
    background-color: white;
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-black);
      margin: 0 0 4px 12px;
    }
  }
  .navigo-booking-container-top {
    display: flex;
    align-items: center;

    justify-content: space-between;
    padding: 12px 10px;
    .navigo-booking-container-top-banner-image {
      max-height: 280px;
      object-fit: cover;
      width: 100%;
    }
    .navigo-booking-container-top-left {
      font-size: 20px;
      font-weight: 600;
      color: #212529;
      margin: 0;
    }
    .navigo-booking-container-top-right {
      position: relative;
      .navigo-booking-container-top-right-dots {
        display: flex;
        flex-direction: column;
        height: 20px;
        justify-content: space-around;
        padding-right: 8px;
        position: relative;
        cursor: pointer;
        div {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #0d1c2e;
        }
      }
      .navigo-booking-container-top-right-menu {
        background-color: white;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 50;
        right: 10px;
        padding: 0 12px 10px 12px;
        border: 1px solid #f0effe;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        button {
          outline: none;
          border: none;
          color: #97144D;
          font-size: 14px;
          font-weight: 400;
          background-color: transparent;
          padding: 10px 0 0 0;
        }
      }
    }
  }
  .navigo-booking-container-section-1 {
    .navigo-booking-container-section-1-top {
      color: #5c5f66;
      font-weight: 400;
      font-size: 16px;
      padding: 0px 10px 0 10px;
      margin: 0;
      margin-bottom: 12px;
    }
  }
  .navigo-booking-container-section-2-no-service {
    img {
      width: 100%;
      object-fit: cover;
      max-height: 300px;
    }
    p {
      color: #171a21;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }
  }
  .navigo-booking-container-section-2 {
    .navigo-booking-container-section-2-ion-slides {
      width: 100% !important;
      padding-bottom: 24px;
      display: block;
      .swiper-slide {
        display: flex;
        flex-direction: column;
      }
      .swiper-pagination {
        bottom: 10px;
        .swiper-pagination-bullet-active {
          background: #97144D;
        }
      }
    }

    .swiper-zoom-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .navigo-booking-container-section-2-ele-img {
      width: 100%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .navigo-booking-container-section-2-ele-section-2 {
      padding: 12px 10px;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      flex-direction: column;
      width: 100%;
      .navigo-booking-container-section-2-ele-top {
        font-weight: 600;
        font-size: 16px;
        color: #212529;
        margin: 8px 0;
        text-align: left;
      }

      .navigo-booking-container-section-2-ele-price {
        font-size: 16px;
        font-weight: 700;
        margin: 4px 0;
        span {
          text-decoration: line-through;
        }
      }
      .navigo-booking-container-section-2-ele-mid {
        font-size: 12px;
        font-weight: 400;
        color: #212529;
        margin-bottom: 8px;
        margin-top: 0;
        text-align: left;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .navigo-booking-container-section-2-ele-bottom {
        display: flex;
        .navigo-booking-container-section-2-ele-bottom-left {
          background-color: #97144D;
          color: white;
          margin-right: 12px;
          display: flex;
          align-items: center;
          margin-right: 12px;
        }
        .navigo-booking-container-section-2-ele-bottom-right {
          background-color: white;
          color: #141517;
          display: flex;
          align-items: center;
        }

        button {
          font-weight: 700;
          font-size: 12px;
          border-radius: 4px;
          padding: 8px 12px;
          border: 1px solid #f1f3f5;
          img {
            margin-left: 6px;
          }
        }
      }
    }
  }
  .navigo-booking-container-section-3 {
    width: 95%;
    margin-left: 12.5px;
    margin-top: 20px;

    .navigo-booking-container-section-3-heading {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #171a21;
      margin: 0;
      padding: 0 10px 12px 10px;
      // border-bottom: 1px solid #dee2e6;
    }
  }
  .navigo-booking-container-section-4 {
    margin-top: 32px;
  }
}
.navigo-booking-container-section-3-ele {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: inherit;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
  color: #171a21;
  background-color: #ffffff99;
  width: 100%;
  padding: 12px 28px 12px 10px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  .navigo-booking-container-section-3-ele-top {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: #171a21;
  }
  .navigo-booking-container-section-3-ele-mid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
      p {
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .navigo-booking-container-section-3-ele-bottom {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
    }
    .navigo-booking-container-section-3-ele-bottom-reschedule {
      color: #364fc7;
      background-color: #edf2ff;
      padding: 8px 12px;
    }
    .navigo-booking-container-section-3-ele-bottom-cancel {
      color: #e03131;
      background-color: #ffe3e3;
      padding: 8px 12px;
    }
    button,
    a {
      outline: none;
      border: none;
      border-radius: 8px;
      padding: 4px 8px;
      margin-right: 12px;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
  }
}

.navigo-booking-general-drawer {
  animation-name: scale-up;
  animation-duration: 100ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-direction: alternate;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.4);
  .navigo-booking-current-service-know-more {
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 12px 8px;
    .navigo-booking-current-service-know-more-top {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        font-weight: 700;
        font-size: 34px;
        line-height: 1.3;
        margin: 0;

        color: inherit;
      }
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
    .navigo-booking-current-service-know-more-description {
      color: #495057;
      font-size: 16px;
      line-height: 1.55;
      margin-bottom: 0;
      font-weight: 500;
      text-decoration: none;
    }
  }
}
.navigo-booking-general-drawer-white {
  background-color: transparent !important;
}
.general-navigo-downloading-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #d8d8d8;
  p {
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
}

.progress-indicator-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  .progress-indicator-top {
    display: flex;
    align-items: center;
    padding: 0 16px;
    .progress-indicator-left-input {
      border-radius: 50% !important;
      height: 34px;
      width: 34px;
      overflow: hidden;
      background-color: var(--primary-black);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .progress-indicator-left-right {
      height: 34px;
      width: 34px;
      border-radius: 50%;
      background-color: #f1f3f5;
      border: 2px solid var(--primary-black);
      color: #343a40;
      font-size: 12px;
      font-weight: 800;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .progress-indicator-mid {
      flex: 1;
      height: 3px;
      background-color: var(--primary-black);
    }
  }
  .progress-indicator-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
      color: #343a40;
    }
  }
}
.navigo-container-screen-2 {
  padding: 12px 14px 120px 14px;
  .navigo-container-screen-2-header {
    display: flex;
    align-items: center;
    column-gap: 12px;
    p {
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-black);
      margin: 0 0 0px 12px;
    }
    img {
      width: 25px;
      height: 20px;
    }
  }
  .navigo-container-screen-2-main {
    // .navigo-container-screen-2-main-top {
    //   font-weight: 600;
    //   font-size: 16px;
    //   line-height: 28px;
    // }
    .navigo-container-screen-2-main-mid {
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
        0px 1px 3px rgba(16, 24, 40, 0.1);
      padding: 16px 0;
      margin: 12px -14px 12px -14px;
      border-top: 1px solid #dee2e6;
    }
    .navigo-container-screen-2-main-mid-2 {
      // color: #464646;
      font-size: 16px;
      font-weight: 600;
    }
    .navigo-container-screen-2-main-family-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .navigo-container-screen-2-main-family-list-ele {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        img {
          border-radius: 50%;
          object-fit: cover;
          width: 48px;
          height: 48px;
          margin-right: 12px;
        }
        .navigo-container-screen-2-main-family-list-ele-mid {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          flex: 1;
          .navigo-container-screen-2-main-family-list-ele-mid-top {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
            color: #171a21;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow-y: hidden;
          }
          .navigo-container-screen-2-main-family-list-ele-mid-bottom {
            font-weight: 400;
            font-size: 12px;
            color: #171a21;
            margin: 0;
          }
        }
        input {
          min-width: 0;
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .navigo-container-screen-2-bottom {
    display: flex;
    position: fixed;
    bottom: var(--ion-safe-area-bottom);
    left: 0;
    width: 100%;
    padding: 16px 0;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    background-color: white;
    z-index: 50;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
      0px 1px 3px rgba(16, 24, 40, 0.1);
    p {
      color: #97144D;
      font-size: 14px;
      font-weight: 600;
      margin: 0 0 8px 0;
      text-align: center;
      cursor: pointer;
    }
    button {
      border-radius: 24px;
      outline: none;
      border: none;
      background-color: #97144D;
      padding: 10px 0;
      width: 80%;
      text-align: center;
      color: white;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      &:disabled {
        background-color: #d8d8d8;
      }
    }
  }
}

.navigo-booking-screen-3-container {
  padding: 12px 14px 100px 14px;
  .navigo-booking-screen-3-header {
    img {
      width: 25px;
      height: 20px;
    }
  }
  .navigo-booking-screen-3-mid-service-name {
    font-size: 20px;
    font-weight: 600;
    color: #171a21;
    text-align: center;
  }
  .navigo-booking-screen-3-mid {
    .navigo-booking-screen-3-mid-progress {
      margin: 0 -14px;
    }
  }
  .navigo-booking-screen-3-calendar {
    display: flex;
    width: 100%;
    align-items: center;

    overflow-x: scroll;
    margin-top: 12px;

    .navigo-booking-screen-3-calendar-date {
      border-radius: 8px;
      border: 1px solid #e9ecef;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 14px;
        font-weight: 600;
        color: #171a21;
        padding: 16px 16px;
      }
    }
    .navigo-booking-screen-3-calendar-date-selected {
      border: 1px solid var(--primary-black) !important;
      span {
        color: var(--primary-black) !important;
      }
    }
  }
  .navigo-booking-screen-3-bottom-parent {
    position: relative;
    height: calc(100vh - 300px - var(--ion-safe-area-bottom));
    width: 100%;
    overflow: hidden;
  }
  .navigo-booking-screen-3-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    margin-top: 28px;

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: auto;
    z-index: 50;
    width: 100%;
    padding: 20px 10px 120px 0;
    &::-webkit-scrollbar {
      width: 5px;
    }
    .navigo-booking-screen-3-bottom-ele {
      border: 1px solid var(--primary-black);
      border-radius: 8px;
      background-color: white;
      color: var(--primary-black);
      font-weight: 600;
      padding: 10px 0;
      width: 100%;
      padding: 8px 16px;
      max-width: 400px;
      display: flex;
      align-items: center;
      max-height: 38px;
      p {
        margin: 0;
        flex: 1;
      }
      img {
        height: 20px;
        width: 20px;
      }
    }
    .navigo-booking-screen-3-bottom-ele-selected {
      color: var(--primary-black) !important;
    }
    .navigo-booking-screen-3-bottom-no-slots {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        object-fit: cover;
      }
      p {
        color: #777777;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }
    }
  }
  .navigo-booking-screen-3-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    position: fixed;
    bottom: var(--ion-safe-area-bottom) !important;
    left: 0;
    box-shadow: 0 -2px rgba(16, 24, 40, 0.06);
    z-index: 50;
    background-color: white;
    button {
      padding: 10px 0;
      width: 80%;
      color: white;
      font-size: 20px;
      font-weight: 600;
      background-color: #97144D;
      border-radius: 24px;
      &:disabled {
        background-color: #d8d8d8;
      }
    }
  }
}

.navigo-booking-cancel-modal {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 8px;
  padding: 16px 12px 24px 12px;
  width: max-content;
  background-color: white;
  .navigo-booking-cancel-modal-top {
    font-size: 14px;
    font-weight: 600;
    color: #171a21;
    margin-top: 0;
    margin-bottom: 16px;
  }
  .navigo-booking-cancel-modal-bottom {
    .navigo-booking-cancel-modal-bottom-cancel {
      background-color: rgba(224, 49, 49, 0.1);
      font-size: 12px;
      font-weight: 600;
      color: #e03131;
      border-radius: 8px;
      margin-right: 12px;
      padding: 6px 6px;
    }
    .navigo-booking-cancel-modal-bottom-back {
      color: #171a21;
      font-size: 12px;
      font-weight: 600;
      padding: 6px 6px;
      background-color: transparent;
    }
    .navigo-booking-cancel-modal-bottom-general {
      background-color: #97144D;
      font-size: 12px;
      font-weight: 600;
      color: white;
      border-radius: 8px;
      margin: 0 0 0 12px;
      padding: 6px 6px;
    }
    button {
      outline: none;
      border: none;
    }
  }
  .navigo-booking-margin-left-auto {
    margin-left: auto;
  }
}

.navigo-booking-know-more-container {
  padding: 12px 14px 100px 10px;
  .navigo-know-more-header {
    img {
      width: 25px;
      height: 20px;
    }
  }
  .navigo-booking-know-more-title {
    font-size: 20px;
    font-weight: 600;
    color: #171a21;
  }
  .navigo-booking-know-more-main {
    .navigo-booking-know-more-main-ele {
      max-height: 300px;
      margin: 0 -12px;
      img {
        width: 100%;
        height: 100%;
        max-height: inherit;
        object-fit: cover;
      }
    }
    .navigo-booking-container-section-2-ele-mid {
      font-size: 12px;
      font-weight: 400;
      color: #212529;
    }
  }
}

.general-no-appointment-found-div {
  .general-no-appointment-found-h2 {
    padding: 0 10px;
    color: #171a21;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 32px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 6px;
    padding: 0 32px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
  }
}
.navigo-booking-screen-5 {
  width: 100%;
  height: 100%;
  background-color: white;
}

.navigo-booking-error-box {
  background-color: #d0021b;
  color: white;
  font-size: 12px;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  padding: 2px 5px;
  top: 0;
  z-index: 50;
}

.general-reschedule-cancel-icons {
  width: 12px;
  height: 12px;
  margin-right: 6px;
}
.general-download-document-blue-chevron {
  width: 12px;
  height: 12px;
  margin-left: 6px;
}
.general-disabled-color-class {
  P {
    color: #d8d8d8 !important;
  }
}

.general-navigo-add-family-card-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  animation-name: go-up;
  animation-delay: 0ms;
  animation-duration: 100ms;
  animation-iteration-count: 1;
  z-index: 200;
}
.navigo-no-slots-loader {
  width: 80px !important;
  height: 80px !important;
  display: flex !important;

  svg {
    circle {
      stroke-width: 2;
      stroke: #143A72;
    }
  }
}
.no-past-appointment-found-img {
  margin-bottom: 12px;
}

.navigo-general-margin-left-0 {
  margin-left: 0 !important;
}

.navigo-booking-after-pay-modal {
  .modal-wrapper {
    height: 100%;
    .navigo-book-after-payment-container {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      .navigo-book-after-payment-status {
        text-align: center;
      }
      .navigo-book-after-payment-go-back {
        position: absolute;
        bottom: 0;
        background-color: #97144D;
        outline: none;
        border: none;
        color: white;
        padding: 20px;
        text-align: center;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}

.navigo-general-card-container {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  margin: 12px;
  border-radius: 16px;
  background-color: white;
  .navigo-general-card-container-top {
    display: flex;
    align-items: inherit;
    margin-bottom: 16px;
    column-gap: 16px;
    .navigo-general-card-container-top-left {
      border-radius: 16px;
      img {
        width: 80px;
        min-width: 80px;
        height: 80px;
        border-radius: 16px;
      }
    }
    .navigo-general-card-container-top-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        font-size: 12px;
        margin: 0;
        color: var(--primary-black);
      }
      .navigo-general-card-container-top-right-top {
        margin-bottom: 34px;
        .navigo-general-card-container-top-right-top-title {
          font-weight: 700;
          margin-bottom: 6px;
          line-height: 14px;
        }
        .navigo-general-card-container-top-right-top-subtitle {
          font-weight: 500;
          line-height: 17px;
          -webkit-line-clamp: 2;
        }
      }
      .navigo-general-card-container-top-right-bottom {
        .navigo-general-card-container-top-right-bottom-price {
        }
      }
    }
  }
  .navigo-general-card-container-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navigo-general-card-container-bottom-book-now {
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      padding: 6px 8px;
      background-color: #97144D;
      color: white;
      height: fit-content;
      border-radius: 6px;
    }
    .navigo-general-card-container-bottom-know-more {
      font-size: 12px;
      font-weight: 600;
      color: #171a21;
      background-color: transparent;
      height: fit-content;
      display: flex;
      svg {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
    .navigo-booking-container-section-2-ele-bottom-availed-container {
      display: flex;
      align-items: flex-start;
    }
    .navigo-booking-container-section-2-ele-bottom-availed {
      margin-right: 12px;
      background-color: transparent;
      border-radius: 25px;
      outline: none;
      border: 1px solid #f1f3f5;
      padding: 2px 16px;
      margin-bottom: 6px;
      margin-right: 6px;
      span {
        display: flex;
        align-items: center;
        color: #495057;
        font-size: 11px;
        line-height: 18px;
        font-weight: 700;
        &::before {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background-color: #40c057;
          margin-right: 6px;
        }
      }
    }
    .navigo-booking-container-section-2-ele-bottom-availedForFamily {
      outline: none;
      background-color: white;
      border-radius: 50%;
      width: 24px;
      font-size: 11px;
      font-weight: 700;
      height: 24px;
      border: 1px solid #f1f3f5;
      text-align: center;
      color: #97144D;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0, 0);
  }
  50% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes go-up {
  0% {
    top: 200%;
  }

  100% {
    top: 0px;
  }
}
