@import url("colors.scss");

/* font converted using font-converter.net. thank you! */

body {
  font-family: "Montserrat", sans-serif;
  --font-family: "Montserrat", sans-serif;
  --ion-font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--main-text-light);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  // margin-top: env(--ion-safe-area-top) !important;
  // margin-bottom: env(--ion-safe-area-bottom) !important;
}
body,
html {
  position: fixed;
}
div.orange-dash {
  background-color: var(--main-orange);
  height: 4px;
  border-radius: 8px;
}

div.cornflower-dash {
  background-color: var(--main-cornflower);
  height: 4px;
  border-radius: 8px;
}

.disabled-item {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-selection {
  pointer-events: none;
}

.fw-light {
  font-weight: 300;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}
.fw-bold {
  font-weight: 800;
}

.fw-700 {
  font-weight: 700;
}
.fc-black {
  color: #000;
}

.fs16 {
  font-size: 16px;
}

.fs12 {
  font-size: 12px;
}
.fs14 {
  font-size: 14px;
}
.fs15 {
  font-size: 15px;
}

.fs24 {
  font-size: 24px;
}
.fs25 {
  font-size: 25px;
}
.fs28 {
  font-size: 28px;
}
.fs20 {
  font-size: 20px;
}
.fs18 {
  font-size: 18px;
}
.fs40 {
  font-size: 40px;
}
.fs36 {
  font-size: 36px;
}
.grey-text {
  color: #777777;
}
.m-top {
  margin-top: 0.5rem;
}
.ml-05 {
  margin-left: 0.5rem !important;
}
.mh-22 {
  max-height: 22px;
  line-height: 36px;
}
// ion-button.primary-btn.md.button.button-solid.ion-activatable.ion-focusable.hydrated{
//   --background:var(--main-orange);
//   width: 100%;
//   --border-radius: 15px;
//   text-transform: none;
//   height: fit-content;
//   div {
//     padding: 10px;
//   }
// }

button.prime-btn {
  color: var(--main-white);
  padding: 10px 15px;
  border-radius: 16px;
  background: var(--main-orange);
  font-weight: 600;
  outline: none;
  width: 100%;
}

.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
//snack-bar style
div.MuiSnackbarContent-message {
  width: 100%;
  text-align: center;
  font-size: 1.2em;
}

.error-snack-bar
  div.MuiTypography-root.MuiPaper-root.MuiPaper-elevation6.MuiSnackbarContent-root.MuiTypography-body2 {
  background: var(--error-red);
}

.hide-bullet {
  --bullet-background: transparent;
  --bullet-background-active: transparent;
}

// .icon-inner,
// svg {
//   fill: salmon;
// }
.loading-spinner-holder {
  position: absolute;
  top: 45%;
  left: 45%;
}
.ion-page {
  // bottom: var(--ion-safe-area-bottom) !important;
  // top: var(--ion-safe-area-top) !important;
  ion-header ion-toolbar:first-of-type {
    padding-top: 0px;
  }
}
// .ion-page-13 {
//   .ion-page {
//     top: 0 !important;
//   }
// }

.text-center {
  text-align: center;
}

.ReactSwipeableBottomSheet--open {
  padding-top: var(--ion-safe-area-top) !important;
  padding-bottom: var(--ion-safe-area-bottom) !important;
}

.scroll-content {
  -webkit-overflow-scrolling: auto !important;
}
ion-slides,
ion-slide {
  touch-action: none;
}

.br-15 {
  border-radius: 15px;
}

.margin-Top-10 {
  margin-top: 10px;
}

.margin-Top-15 {
  margin-top: 15px;
}

.margin-Bottom-15 {
  margin-bottom: 15px;
}

.padding-Bottom-25 {
  padding-bottom: 25px !important;
}

.font-strikethrough {
  text-decoration: line-through;
}

html {
  // background-image: url("./assets/images/body-background.png");
  // background-color: #fffbf4;
}

.responsive-600 {
  max-width: 560px;
  margin: 0 auto;
}
.responsive-class-ion-router {
  position: fixed;
  bottom: var(--ion-safe-area-bottom) !important;
  top: var(--ion-safe-area-top) !important;
  @media screen and (min-width: 559px) {
    max-width: 560px;
    margin: 0 auto;
    box-shadow: 0px 0px 100px -40px rgba(0, 0, 0, 0.4);
    height: 95vh;
    margin-top: 20px;
    border-radius: 8px;
  }
}

.MuiBackdrop-root {
  max-width: 560px;
  margin: 0 auto;
}
// .MuiPaper-root {
//   max-width: 560px;
//   margin: 0 auto;
//   top: 45px;
// }
.MuiDrawer-paper {
  max-width: 560px;
  margin: 0 auto;
}
.sc-ion-modal-md-h {
  max-width: 560px;
  margin: 0 auto;
}
.MuiDialogContent-root {
  max-width: 560px;
  margin: 0 auto;
}

//this is for global webkit scroller if anything needs to be modified do that on that particular elements's css host.

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-track {
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 100px;
}
.ion-router-circular-progress {
  width: 80px !important;
  height: 80px !important;
  display: flex !important;
  margin: 80px auto;
  svg {
    circle {
      stroke-width: 2;
      stroke: #143a72;
    }
  }
}

.general-central-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .general-central-circular-progress {
    width: 80px !important;
    height: 80px !important;
    display: flex !important;

    svg {
      circle {
        stroke-width: 2;
        stroke: #143A72;
      }
    }
  }
}

.general__card__drawer {
  height: 100% !important;
  top: var(--ion-safe-area-top);
  width: 100%;
  overflow-x: hidden;
  max-width: 560px;
  .MuiBackdrop-root {
    top: var(--ion-safe-area-top);
    bottom: var(--ion-safe-area-bottom);
    overflow-x: hidden;
    max-width: 560px;
  }
  .MuiPaper-root {
    overflow-y: scroll;
    width: 100%;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    top: var(--ion-safe-area-top);
    bottom: var(--ion-safe-area-bottom);
    box-shadow: none;
    overflow-x: hidden;
  }
}

.general-non-filled-svg-class {
  fill: none !important;
}

.rating-show-feedback-dialog-class {
  display: flex !important;
  align-items: center;
  justify-content: center;
  .MuiDialog-paper {
    margin: 10px !important;
  }
  .MuiPaper-elevation24 {
    box-shadow: none;
  }
}
.global-error-component-container {
  position: fixed;
  top: var(--ion-safe-area-top);
  left: 0;
  width: 100%;
  background-color: #d0021b;
  text-align: center;
  z-index: 50;
  padding: 6px;
  transition: all 200ms ease-in-out;
  span {
    font-size: 12px;
    color: white;
  }
}
.global-error-comp-bg-green-class {
  background-color: #51ac95 !important ;
}
.global-error-component-hide {
  transform: scale(0, 0);
  opacity: 0;
}
.global-error-component-show {
  transform: scale(1, 1);
  opacity: 1;
}

.general-toast-component {
  background-color: white;
  border-radius: 24px;
  padding: 8px 20px;
  text-align: center;
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  span {
    font-size: 14px;
    color: #464646;
    font-weight: 400;
  }
}
.fixed-bottom-class {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  top: unset !important;
  height: fit-content;
}

.input-no-steps :placeholder-shown {
  margin-bottom: 0;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #c0c6d1;
  font-weight: 400;
  line-height: 28px;
}
.picker-toolbar-button.sc-ion-picker-ios:first-child > button {
  font-weight: 600;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.clamp-down-para-class {
  display: -webkit-box;
  /* max-width: 200px; */

  -webkit-box-orient: vertical;
  overflow: hidden;
}

.sc-ion-modal-ios-h {
  top: var(--ion-safe-area-top) !important;
  bottom: var(--ion-safe-area-bottom);
}

.MuiDialog-root {
  top: var(--ion-safe-area-top) !important;
  bottom: var(--ion-safe-area-bottom) !important;
  .MuiBackdrop-root {
    top: var(--ion-safe-area-top) !important;
    bottom: var(--ion-safe-area-bottom) !important;
  }
}

.MuiDrawer-root {
  top: var(--ion-safe-area-top) !important;
  bottom: var(--ion-safe-area-bottom) !important;
  .MuiBackdrop-root {
    top: var(--ion-safe-area-top) !important;
    bottom: var(--ion-safe-area-bottom) !important;
  }
}

.test-div-new-chat {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 9878877887;
}
.login-phone-mobile-container {
  margin-left: 0.2rem;
}

.baby-dob-container {
  margin-left: 0.2rem;
  margin-top: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  padding-bottom: 6px;
  .baby-dob-string {
    font-weight: 400;
    font-size: 12px;
    margin: 8px 0 0 0;
    color: #171a21;
  }

  .baby-dob-input {
    outline: none;
    border: none;
    width: 100%;
    padding-top: 6px;
    padding-left: 0px;
    &:focus {
      outline: none;
      border: none !important;
    }
  }
  .baby-dob-container-bottom {
    display: flex;
    align-items: flex-end;

    p {
      font-size: 14px;
      margin-top: 12px;
      flex: 1;
      cursor: pointer;
    }

    .baby-dob-date {
      margin-bottom: 0;

      color: var(--primary-black);
    }
    .baby-dob-placeholder {
      margin-bottom: 0;
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
      color: #c0c6d1;
      line-height: 28px;
    }
  }
  .MuiInputBase-root {
    width: 100%;
    .MuiSvgIcon-root {
      background-image: url("./assets/icons/chevron-down.svg");

      background-repeat: no-repeat;
      fill: none;
      color: white;
    }
  }

  .MuiInput-underline:after {
    border-bottom: none;
    content: "";
  }
  .css-74e2me-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover::before {
    border-bottom: none !important;
  }
  .MuiInput-underline:before {
    border-bottom: none;
    content: "";
  }
  .MuiSelect-select:focus {
    background-color: white !important;
  }
  .MuiInputBase-input {
    font-size: 16px !important;
    font-weight: 300 !important;
    padding-bottom: 0px;
    color: black !important;
  }
}

.ion-modal-general-class {
  height: calc(100% - var(--ion-safe-area-bottom) - var(--ion-safe-area-top));
  width: 100%;
  --backdrop-opacity: 0.4;
  .ion-page {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ion-modal-transparent-background {
  --background: var(--ion-background-color);
}

.ion-modal-content-wrapper {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-picker-column {
  .picker-opts {
    max-width: 100% !important;
  }
}
.swiper-pagination-bullet-active {
  background-color: #97144D !important;
}
.MuiCircularProgress-colorPrimary {
  color: #143A72 !important;
}
ion-tab-button {
  --ripple-color: #143A72;
  --color: #000000;
  --color-selected: #000000;
}