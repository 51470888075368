.offline-page-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  align-items: center;
  justify-content: center;
  height: 100%;
  .offline-page-container-top {
    object-fit: contain;
    width: 100%;
    height: 172px;
    margin-bottom: 60px;
  }
  .offline-page-container-bottom {
    text-align: center;
    .offline-page-container-bottom-top {
      font-size: 20px;
      font-weight: 700;
      color: #26284d;
      margin-bottom: 0px;
    }
    .offline-page-container-bottom-bottom {
      font-size: 12px;
      font-weight: 400;
      color: #26284d;
    }
  }
}
