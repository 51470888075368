ion-icon {
  stroke: gray;
  fill: none;
  font-size: 24px;
}
.normal-ion-icon-class {
  padding-top: 8px;
}

ion-tab-bar {
  // display: flex !important;
  height: 60px;
  box-shadow: 0px -10px 24px -25px rgba(0, 0, 0, 0.34);
  padding: 9px 0 !important;
}
.chat-ion-icon {
  font-size: 35px;
}
.mli-feed-class {
  font-size: 32px;
}

#tab-button-feed {
  img {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
}
.tab-selected {
  stroke: #171a21;
  // ion-icon {
  //   width: 32px;
  //   height: 32px;
  // }

  ion-label {
    color: #171a21 !important;
  }
  .normal-ion-icon-class {
    stroke: #171a21;
    padding-top: 5px;
    width: 24px;
    height: 24px;
  }
  .mli-feed-class {
    width: 30px !important;
    height: 30px !important;
  }
  // ion-icon {
  //   fill: white;
  //   width: 24px;
  //   height: 24px;
  // }
}

.ion-tab-page {
  bottom: 0px !important;
}

.unread-message-indicator {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 12%;
  right: 32%;
  background-color: #bf2828;
  border-radius: 100%;
  color: white;
  text-align: center;
}
.common-global-button {
  outline: none;
  border: none;
  border-radius: 25px;
  padding: 12px;
  color: white;
  font-weight: 600;
  font-size: 20px;
}
.unread-message-indicator-chat {
  height: 20px;
  width: 20px;

  border-radius: 50%;
  // display: inline-block;

  position: absolute;
  padding: 2px;
  top: 12%;
  right: 32%;
  background-color: #bf2828;
  // border-radius: 100%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 12px;
    padding: 2px;
  }
}
