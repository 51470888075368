.reporting-container {
  header {
    width: 100%;
    padding: 20px;
    padding-bottom: 5px;

    .reporting-header {
      display: inline-flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 20px;
        width: 90%;
        font-weight: 600;

        svg {
          margin-left: 10px;
        }
      }
    }

    .subheader {
      margin: 20px 0px 10px 0px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      button {
        outline: none;
        border-radius: 30px;
        border: 1px solid gray;
        background-color: white;
        padding: 1px 20px;
        font-size: 13;
        width: 45%;
        height: 42px;
        font-weight: 500;
        color: #000000;
      }

      .button-active {
        border: 1px solid #143A72;
        background-color: #143A72 !important;
        color: white;
      }

      .small-text {
        font-size: 0.8em;
        line-height: 14px;
      }
    }
  }

  .message-view-container {
    height: 30px;
    margin-bottom: 10px;

    .message-view {
      padding: 5px;
      text-align: center;
      background-color: rgba(0, 201, 211, 0.04);
      color: black;

      label {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .message-error {
      background-color: #d0021b !important;
    }
  }

  .info-icon {
  }

  .close-icon {
  }
  .hide {
    display: none;
  }
  .show {
    display: inline-flex;
  }
  input.small-input {
    width: 50px;
    text-align: center;
  }

  input.medium {
    width: 50px;
    text-align: center;
  }

  div[contentEditable] {
    min-height: 20px;
    height: auto;
    width: 100%;
    overflow: visible;
  }

  input,
  div[contentEditable] {
    box-shadow: none;
    outline: none;
    border: none;
    font-size: 80%;
  }

  input:focus,
  div[contentEditable] :focus {
    box-shadow: none;
    outline: none;
    border: none;
  }

  .reporting-table {
    width: 100%;
  }

  .reporting-table > thead {
    font-size: 12px;
    font-weight: 500;
    color: #97144D;
  }

  .reporting-cell-time {
    padding-left: 10px;
    width: 40%;
    padding-bottom: 5px;
  }

  .reporting-cell-activity {
    width: 60%;
    padding: 0px;
    padding-bottom: 5px;
  }

  .reporting-table tbody > tr {
    border-top: 1px solid #eaeaea;
  }

  .reporting-table tbody > tr:last-child {
    // border-bottom: 1px solid #eaeaea;
  }

  .text-h-m {
    text-align: center;
  }

  .text-v-m {
    display: inline-block;
    vertical-align: middle;
  }

  #activity-text {
    padding: 0;
  }

  textarea {
    resize: none;
  }

  .reporting-new-row {
    color: gray;
  }

  .submit-btn {
    position: fixed;
    bottom: 40px;
    right: 20px;
    height: 53px;
    border-radius: 60px;
    padding: 4px 15px;
    background-color: #97144D;
    color: white;
    outline: none;
    z-index: 100;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
      rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

    .ion-activatable {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 33px;
      }
    }
  }

  textarea {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    outline: none;
    margin: 0px;
    min-height: 30px;
    font-size: 12px;
    border: none;
  }

  .reporting-success-page {
    margin: auto;
    text-align: center;
    width: 250px;

    p {
      margin-top: 20px;
    }
  }
}

.keyboard_img_box {
  position: relative;
}
.keyboard_tail_img {
  position: absolute;
  width: 103px;
  height: 15px;
  bottom: 7%;
  right: 20%;
}
.keyboard_head_img {
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 7%;
  right: 18.5%;
}

.keyboard_main_img {
  width: 100%;
  height: 100%;
}
.reporting_next_line_dialog {
  padding: 0 !important;
}

.bottom__text__content {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #464646;
}
.bottom__text__div {
  background-color: #4cb5ab;
  width: 68px;
  height: 18px;
  border-radius: 2px;
  padding: 4px;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
  }
}

.new_line_text_content {
  padding: 10px 20px;
}

.bottom_buttons_div {
  background-color: #f6f6f6;
  height: 56px;
  width: 100%;
  color: #97144D;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  display: flex;
  P {
    width: 47.5%;
    text-align: center;
  }
}

.reporting__add__new__line {
  border-bottom: 1px solid grey;
  width: max-content;
  font-size: 12px;
  padding-bottom: 1px;
  margin-left: 10px;
  color: #777777;
}

.new-line-text-para {
  font-size: 16px;
  font-weight: 600;
}

.reporting-page-main-section {
  // max-width: 600px;
  // margin: 0 auto;
}
