.signup-container {
  --padding-top: 20px;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-bottom: 20px;
  color: var(--main-text-light);
 

  .health-condition-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75%;
    .health-condition-options-upper {
      .health-condition-bubble-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;
        .health-condition-bubble {
          padding: 5px 10px;
          color: black;
          border-radius: 25px;
          border: 1px solid black;
          margin: 7px 5px 7px 0px;
        }
        .health-condition-background-select {
          background-color: #7478fb;
          color: #fff;
          border: none !important;
        }
      }
    }
    .health-options-submit-button {
      background-color: #97144D;
      padding: 10px 15px;
      color: #fff;
      font-weight: 600;
      border-radius: 25px;
      text-align: center;
    }
  }

  .error-box {
    // height: 25px;

    background-color: #d0021b;
    color: white;
    font-size: 12px;

    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    padding: 15px 5px;
    top: var(--ion-safe-area-top);
    text-decoration: underline;
    animation: FadeAnimation 1s ease-in 3s forwards;
  }

  .title {
    font-weight: 600;
    margin-top: 35px;
    color: var(--main-text-light);
  }
  .mg-top {
    margin-top: 20px;
  }
  .form-group {
    margin-top: 15px;
    display: flex;
    align-items: center;
    ion-label {
      color: #464646;
      font-weight: 500;
    }
    input {
      text-transform: capitalize;
    }
  }
  ion-item {
    margin-left: -10px;
    font-family: 'Montserrat';
    font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  }

  .gender-box-title {
    // flex: 1;
  }

  .gender-box {
    display: inline-flex;
    line-height: 4.5;
    justify-content: center;
    width: 100%;
    flex: 1;
    margin-bottom: 0px;
    @media screen and (min-width: 600px) {
      justify-content: flex-start;
    }

    .gender-box-title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
    }
    .gender-options {
      display: flex;
      flex-direction: column;
      margin: 0 20px;
      justify-content: space-around;
      align-items: center;
      img {
        width: 70px;
        height: 70px;
      }
      label {
        font-weight: 500;
        color: var(--main-text-light);
      }
    }
  }
  .btn-wrapper {
    position: relative;
    margin-bottom: 10px;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    button {
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .buttonprogress {
      color: white;
      margin-left: 5px;
    }
    button:disabled {
      background-color: #d8d8d8 !important;
    }
    button {
     
      height: 50px;
      font-size: 20px;
      padding: 10px 50px 10px 50px;
      border-radius: 35px;
      margin-top: 10px;
      font-weight: 500;
      color: #143A72;
      outline: none;
      border: none;
      width: 100%;
        background-color: #d8d8d8;
border-radius: 24px;
    }
  }

  .doctor-code {
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 500;
    span {
      color: var(--main-text-light);
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        height: 17px;
        margin-right: 10px;
      }
    }
    .whitelisted-number-source {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 15px;
        padding-right: 5px;
      }
      .bold-span {
        padding-left: 5px;
      }
    }
  }
  .doctor-code-link {
    color: rgb(76, 141, 255) !important;
    font-weight: 500;
  }
  .form-group span {
    font-size: 14px;
  }

  .doctor-code-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .close-btn-wrapper {
      display: flex;
      position: fixed;
      top: var(--ion-safe-area-top);
      left: 0;
      right: 0;

      z-index: 2;
      width: 100%;
      color: #fff;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      width: fit-content;
      margin: 0 auto;
      img {
        width: 28px;
        height: 28px;
      }
      span {
        margin-left: 1rem;
      }
    }
    .title {
      font-size: 24px;
    }
    ion-item {
      font-size: 20px;
    }
    button {
      background-color: #97144D;

      font-size: 20px;
      padding: 10px 0;
      border-radius: 24px;
      margin-top: 10px;
      font-weight: 500;
      color: white;
      outline: none;
      border: none;
      margin-bottom: 7%;
      width: 100%;
    }
    .next-button {
      background-color: #d8d8d8;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding-top: 8px;
      text-align: center;
      vertical-align: middle;
      margin-left: 0px;
    }
    .next-button-active {
      background-color: var(--main-orange);
    }
    .doctor-loading {
      margin-top: 20px;
      border: 2px solid #edf1f7;
      border-radius: 4px;
      padding: 12px;
      .doctor-loading-container {
        .doctor-loading-container-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .doctor-loading-container-col-1 {
            width: 86px;

            .doc-profile-image {
              width: 70px;
              height: 70px;
              margin-right: 16px;
            }
          }
          .doctor-loading-container-col-2 {
            display: flex;
            flex-direction: column;
            min-width: 70%;
            .doctor-title {
              font-size: 18px;
              font-weight: 600;
              margin-bottom: 5px;
            }
            .doctor-subtitle {
              font-size: 14px;
              margin-bottom: 5px;
            }
          }
        }
      }
      img {
        width: 100%;
      }
    }
    .code-error {
      margin-top: 15px;
      background-color: #d0021b;

      margin-left: -20px;
      margin-right: -20px;
      text-align: center;
      padding: 10px;
      color: white;
      font-weight: 500;
      .code-error-title {
        font-size: 22px;
        font-weight: 600;
      }
      .code-error-detail {
        font-size: 18px;
        padding-top: 6px;
      }
    }
    footer {
    }
    .dont-have-code {
      margin-top: 10px;
      font-size: 20px;
      text-decoration: underline;
    }
    .color-blue {
      color: #2e66e7;
    }
    .doctor-document-upload-done {
      &:disabled {
        background-color: #d8d8d8;
      }
    }

    .doctor-prescription-section {
      margin-top: 20px;
      border: 2px solid #edf1f7;
      border-radius: 4px;
      padding: 12px;
      .doctor-prescription-container {
        width: 100%;
        .doctor-prescription-row {
          display: flex;
          align-items: center;

          .doctor-prescription-col-1 {
            min-width: 86px;
            text-align: center;

            img {
              width: 40px;
              height: 40px;
            }
          }
          .doctor-prescription-col-2 {
            font-size: 16px;
            font-weight: 400;
            color: #464646;
            margin: 0;
            p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .register-bottom {
    text-align: center;
    margin-top: 50px;
    border: none;
    a {
      font-size: 12px;
      text-decoration: none;
      color: var(--main-text-light);
    }
    span {
      font-size: 12px;
    }
    span.mini-bold {
      font-weight: 600;
    }
  }
}

.menu-partial-medium
  div.MuiPaper-root.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
  height: 88%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.my-custom-class {
  --backdrop-opacity: 0.99;
  div.ion-page {
    overflow: scroll !important;
    padding: 1px !important;
  }

  #wrapper {
    display: flex;
    // height: 100%;
    flex-direction: column;
    // align-items: center;
    padding: 0 15px;
    justify-content: space-evenly;

    .button-wrapper {
      height: 10rem;
      width: 20rem;
      border: 1px solid #dadada;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 1rem;
      position: relative;
      background: #fff;
      display: flex;
      flex-direction: column;
      img {
        width: 5.2rem;
      }
      p {
        width: 65%;
        text-align: left;
      }
      div.check-sign {
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
    }
    .active {
      border: 3px solid #7ac602;
    }
    .app-discovery-options {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 5px 0;
      // padding: 0 15px;
      .app-discovery-option-item {
        display: flex;
        align-items: center;
        padding: 15px 0;
        border: 1px solid #f0f3f8;
        border-radius: 8px;
        justify-content: space-between;
        margin: 5px 0;
        .app-discovery-option-item-icon {
          width: 36px;
          height: 36px;
          margin-left: 5px;
          margin-right: 10px;
        }
        .app-discovery-option-item-title {
          font-size: 16px;
          font-weight: 600;

          text-align: left;
          flex: 1;
        }
        .app-discovery-option-item-tick {
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }
      }
    }
    .source-code-subtitle {
      font-size: 14px;
      font-weight: 600;
      color: #777;
      margin: 0;
    }
  }
  .modal-wrapper {
    height: 95%;
    bottom: 0;
    width: 100%;
    position: absolute;
    border-radius: 8px 8px 0px 0px;
  }
  .ion-button-class {
    display: flex;
    justify-content: center;
    margin-bottom: 7%;
    .source-continue-button {
      color: white;
      padding: 10px;
      border-radius: 24px;
      width: calc(100% - 30px);
      /* bottom: 7%; */
      font-size: 20px;
      font-weight: 600;
      background-color: #97144D;
    }
    .source-continue-button:disabled {
      background-color: #d8d8d8 !important;
    }
  }
}

.register__email__msg {
  font-size: 10px;
  line-height: 20px;
  font-style: italic;
  font-weight: 600;
  color: #97144D;
  padding-left: 6px;
  margin-top: -0.5px;
}

.file__size__error {
  margin-top: 20px;
  border: 1px solid #d0021b;
  border-radius: 4px;
  padding: 12px;
  .file-size-error-container {
    .file-size-error-row {
      display: flex;
      align-items: center;

      .file-size-error-col-1 {
        min-width: 86px;
        text-align: center;

        .error__img {
          width: 40px;
          height: 40px;
          margin-right: 16px;
        }
      }
      .file-size-error-col-2 {
        .error__text {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: #d0021b;
          margin: 0;
        }
      }
    }
  }
}

.form-group.item-has-focus ion-label {
  color: #97144D;
}

.form-group.item-has-focus {
  border-bottom: 0px solid #97144D;
}

.form-group-div ion-item {
  --inner-padding-end: 0px !important;
  --inner-padding-start: 0px !important;
  --padding-start: 0px !important;
  margin-left: 0.3rem;
}
.doctor-code-container ion-item {
  --inner-padding-end: 0px !important;
  --inner-padding-start: 0px !important;
  --padding-start: 0px !important;
  margin-left: 0.3rem;
}

.tick-active {
  position: relative;
}
.gender-tick-img {
  position: absolute;
  width: 25px !important;
  height: 25px !important;
  border: 2px solid white;
  border-radius: 50%;
  bottom: 0;
  right: 0;
}

.prescription-successfull {
  margin-top: 20px;
  border: 2px solid #edf1f7;
  border-radius: 4px;
  padding: 12px;
  .prescription-successfull-container {
    .prescription-successfull-container-row {
      display: flex;
      align-items: center;

      .prescription-successfull-container-col-1 {
        min-width: 86px;
        text-align: center;
        img {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
      }
      .prescription-successfull-container-col-2 {
        .prescription-uploaded-text {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgb(119, 119, 119);
          margin: 5px 5px 5px 0;
        }

        .prescription-uploaded-change {
          font-weight: 600;
          text-decoration: underline;
          color: rgb(46, 102, 231);
          margin: 5px 5px 5px 0;
        }
      }
    }
  }
}

.email-input-cls input {
  text-transform: none !important;
}

.change-prescription-ion-col {
  padding-left: 10px;
}

.change-prescription-ion-row {
  align-items: center;
}

.enable-regs-btn {
  button {
    background-color: #97144D !important;
    &:disabled {
      background-color: #d8d8d8 !important;
    }
  }
}

.organisation-code-component-container {
  padding: 15px;
  height: 100%;
  .organisation-email-container {
    .organisation-email-container-heading {
      margin: 10px 0;
    }
    .organisation-email-input {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 5px;
      input {
        outline: none;
        border: none;
        flex: 1;
        padding: 0;
        font-size: 20px;
        height: 24px;
        font-family: 'Montserrat';

      }
      input:disabled {
        background-color: transparent;
      }
      img {
        width: 32px;
        height: 32px;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    .organisation-email-input:focus-within {
      border-bottom: 1px solid #97144D !important;
    }
  }
  .organisation-email-container-disabled {
    color: #777777 !important;
    .organisation-email-container-heading {
      .organisation-email-container-changes {
        color: #97144D !important;
        font-weight: 700 !important;
        text-decoration: underline !important;
      }
    }
  }
  .organisation-otp-input {
    margin-top: 15px;
    border-bottom: 1px solid #d8d8d8;
    input {
      margin-bottom: 10px;
      color: #97144D;
      outline: none;
      border: none;
      padding: 0;
      font-size: 20px;
      height: 24px;
      width: 80%;
    }
    ion-input {
      --placeholder-color: #d8d8d8;
      --color: #97144D;
      margin-bottom: 10px;
      outline: none;
      border: none;
      font-size: 20px;
      height: 24px;
      width: 80%;
    }
  }
  .organisation-otp-input:focus-within {
    border-bottom: 1px solid #97144D !important;
  }

  .organisation-message-box {
    margin: 20px -15px 15px -15px;
    position: relative;
    // padding: 0 15px;
    .organisation-message-box-error {
      color: white;
      background-color: #d0021b;
      font-size: 16px;
      font-weight: 600;

      text-align: center;
      padding: 12px 0 12px 0;
      p {
        margin: 0;
      }
    }
    .organisation-message-box-green {
      background-color: #51ac95 !important;
    }

    .organisation-not-verified-error {
      color: #2e66e7;
      text-decoration: underline;
      text-align: center;
      font-weight: 600;
    }
    .organisation-message-box-success {
      display: flex;
      align-items: center;
      padding: 25px 20px 25px 20px;
      border: 1px solid #dce4ef;
      border-radius: 4px;
      img {
        width: 36px;
        height: 36px;
        margin: 0 15px;
      }
      span {
        font-size: 16px;
      }
    }
  }

  .organisation-submit-button {
    border-radius: 22px;
    background-color: #97144D;
    width: calc(100% - 30px);
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 0;
    position: absolute;
    bottom: 7%;
    color: white;
    left: 15px;
  }
  .organisation-submit-button:disabled {
    background-color: #d8d8d8;
  }
  .organisation-close {
    display: flex;
    position: fixed;
    top: var(--ion-safe-area-top);
    left: 0;
    right: 0;

    z-index: 2;
    width: 100%;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: fit-content;
    margin: 0 auto;
    img {
      width: 28px;
      height: 28px;
      margin-right: 15px;
    }
    span {
      font-size: 28px;
      color: white;
    }
  }
  .organisation-code-otp-msg {
    font-size: 14px;
    color: #777;
    margin-top: 5px;
    .organisation-code-otp-msg-2 {
      .otp-msg-resend-button {
        color: #97144D;
        font-weight: 700;
        padding-left: 5px;
      }
      .text-underline-class {
        text-decoration: underline;
      }
    }
  }
}

.testing-close-button {
  background-color: red;
  height: 40px;
  position: absolute;
  top: 0;
}

.menu-partial-medium {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.86);
  }
}

.dialog-close-button-height-web {
  height: 10%;
}
.registration-capitalize-input {
  text-transform: capitalize;
}


