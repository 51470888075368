div.intro-image-content {
  display: flex;

  flex-direction: column;
}

img.intro-doc-image {
  padding: 10px 0px;
}

div.first-slide-intro {
  display: flex;
  flex-direction: column;

  align-content: center;
}

.intro-text {
  padding-top: 10px;
  color: var(--main-text-light);
}

div.slide {
  height: calc(
    70vh - 80px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
  );
  display: flex;
  align-items: center;
}

div.intro-images {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

img.hp-intro-icon {
  height: 70px;
}

div.tab-image {
  display: flex;
  align-items: center;
  margin: 12px;
}

div.detail-img {
  text-align: left;
  padding-left: 15px;
}

/* .login-bullets {
  --bullet-background: white;
  --bullet-background-active: var(--main-cornflower) !important;
} */

.login-bullets .swiper-pagination-bullet {
  background-color: gray !important;
}

.login-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--main-cornflower) !important;
}

.carousel-text {
  color: var(--main-text-light);
}

.login-slide-show {
  width: 100%;
  height: 100%;

  position: relative;
}

.login-slide-show .login-slide-show-logo {
  position: absolute;
  top: 4%;
  left: 6%;
  width: 39px;
  height: 39px;
}

.login-slide-show .login-slide-show-main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.login-slide-show .login-slide-show-div {
  position: absolute;
  bottom: 10%;
  color: #464646;
  background-color: white;
  width: 80%;
  padding: 5px 20px;
  text-align: center;
  border-radius: 25px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}
/* @media screen and (min-width: 600px) {
  .login-slide-show .login-slide-show-div {
    max-width: 600px;
    padding: 16px 20px;
    width: 100%;
  }
} */
