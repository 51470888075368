.zyla-wallet-card {
  background-image: url("../../../assets/images/wallet-background.svg");
  // background-color: #f77079;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 200px;
  width: 100%;
  border-radius: 8px;
  color: #26284d;
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .zyla-wallet-card-first {
    .zyla-wallet-card-top {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
        font-size: 8px;
        font-weight: 700;
        color: #fff;
      }
    }
    .zyla-wallet-card-mid {
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
      }
      .zyla-wallet-card-mid-left {
        font-size: 24px;
        font-weight: 700;
      }
      .zyla-wallet-card-mid-right {
        p {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    .zyla-wallet-card-mid-validity {
      p {
        margin: 0;
        font-size: 8px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
  .zyla-wallet-card-mid-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    p {
      font-size: 12px;
      color: #fff;
      font-weight: 700;
    }
  }
}
