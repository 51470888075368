.zyla-pay-dialog-fade-in-class {
  animation-name: zyla-pay-fade-in-anim;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-direction: normal;
}
.zyla-pay-dialog-root-container > .MuiDialog-container > .MuiPaper-root {
  max-width: 400px !important;

  margin: 100px;
}
.zyla-pay-dialog-root-container-100 > .MuiDialog-container > .MuiPaper-root {
  width: 100%;
  height: 100%;
  // margin: 0 10px;
  margin: 0 !important;
  max-height: 100%;
}
// @media screen and (min-width: 480px) {
//   .zyla-pay-dialog-root-container-100 > .MuiDialog-container > .MuiPaper-root {
//     width: 100%;
//     height: 100%;
//     margin: 0 10px;
//     max-width: 400px;
//   }
// }
.zyla-pay-dialog-root-container-div > iframe {
  height: 100% !important;
  width: 100% !important;
}
.zyla-pay-labtest-button-class {
  background: linear-gradient(
    121deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.2) 100%
  );
  border-radius: 4px;
  padding: 4px 12px;
}
.zyla-pay-dialog-root-container-div {
  width: 100%;
  height: 100%;
}
@keyframes zyla-pay-fade-in-anim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.payment-loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;

  .payment-loader-container-progress {
    width: 50px !important;
    height: 50px !important;
    display: flex !important;

    svg {
      circle {
        stroke-width: 3;
        stroke: #6a5ef5;
      }
    }
  }
  .payment-loader-container-cross {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .payment-loader-container-text {
    color: #26284d;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 160px;
    .payment-loader-container-text-timer {
      color: #e44437 !important;
    }
  }
}
.payment-loader-alert-class {
  font-size: 12px;
  font-weight: 500;
  color: #26284d;
  z-index: 10000;
  font-size: 12px;
  font-weight: 500;
  color: #26284d;
  position: absolute;
  top: 0;

  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  .payment-loader-alert-class-box {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    .alert-button-group {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 8px;
      justify-items: center;

      p {
        margin-bottom: 16px;
      }
      button {
        flex: none;
        border: none;
        width: fit-content;
        height: fit-content;

        font-size: 12px;
        font-weight: 600;
        border-radius: 8px;
        min-width: fit-content;
      }
    }
    .payment-loader-alert-cancel {
      background-color: #ffe3e3;
      margin-right: 16px;
      color: #e44437;
      padding: 6px 8px;
    }
    .payment-loader-alert-wait {
      background-color: #f1f3f5;
      padding: 6px 16px;
      color: #777777;
    }
  }
}
