.document-chat-card {
  .doc-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .doc-info-text {
      text-align: left;
      width: fit-content;
      .name-title {
        padding-left: 10px;
        word-break: break-all;
        color: white;
      }
    }

    .doc-info-icon {
      width: fit-content;
      img {
        height: 18px;
        width: 15px;
      }
    }
  }
}

.document-table-card {
  .doc-info-text {
    padding: 2px;
    display: flex;
    flex-direction: column;
    .name-title {
      color: black;
      font-size: 15px;
      font-weight: 600;
      text-decoration: none;
    }
    .xs-footer {
      color: black;
      font-size: 7px;
      text-decoration: none;
    }
    .doc-last-updated {
      border: none;
      color: grey;
      font-size: 0.5rem;
    }
  }
  .doc-info {
    padding: 6px;
    border-top: 1px solid var(--grey-border);
  }
}
