.vitals-container {
  background-color: #f0f3f8;
  header {
    width: 100%;
    padding: 20px;
    .vitals-header {
      display: inline-flex;
      width: 100%;
      height: 45px;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      .title {
        font-size: 20px;
        width: 90%;
        font-weight: 600;
      }
      .vital-chart-icon {
        padding-left: 5px;
        img {
          height: 27px;
          margin-right: 35px;
        }
      }
      .info-icon {
        padding-left: 5px;
        img {
          height: 18px;
          margin-right: 15px;
        }
      }
      .close-icon {
        vertical-align: middle;
      }
    }
    .subheader {
      display: flex;
      flex-direction: row;
      margin-left: -20px;
      margin-right: -20px;
      button {
        outline: none;
        border-radius: 30px;
        border: 1px solid gray;
        background-color: white;
        padding: 1px 17px;
        font-size: 13px;
        width: 30%;
        height: 49px;
        margin-left: 8px;
        font-weight: 500;
        color: #000000;
      }
      .button-active {
        border: 1px solid #143A72;
        background-color: #143A72 !important;
        color: white;
      }
      .small-text {
        font-size: 0.8em;
        padding-top: 1px;
      }
    }
  }
  .error-box {
    height: 30px;
    display: flex;
    align-items: center;
    background-color: #e64545;
    color: white;
    font-size: 14px;
    margin-top: 125px;
    justify-content: center;
    width: 100%;
    z-index: 100;
    position: absolute;
  }

  .vital-top-message {
    text-align: center;
    font-style: italic;
    background-color: #143A72;
    padding: 4px;
    font-weight: 500;
    font-size: 14px;
    color: white;
    height: 24px;
    position: sticky;
    top: 0;
    z-index: 3;
  }
  .vital-top-error {
    background-color: #e64545;
    font-style: normal;
    color: white;
  }
  .vital-table {
    width: 100%;
    // margin-top: 20px;
    margin-bottom: 100px;
    thead {
      color: #000000;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      position: relative;
      background-color: #f0f3f8;
      tr {
        height: 40px;
      }
    }
    .sync-accu-check-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .sync-accu-check-box {
        text-align: center;
        border-radius: 4px;
        border: 1px solid #97144D;
        padding: 5px 10px;
        margin: 5px 15px;
        background-color: white;
        width: 100%;
        span {
          color: #97144D;
          font-size: 12px;
          font-weight: 700;
          text-align: center;
        }
        @media (max-width: 280px) {
          span {
            font-size: 10px;
          }
        }
      }
    }
    tbody {
      background-color: white;
      tr:first {
        margin-top: 20px;
      }
      td {
        color: var(--main-text-light);
      }
    }
  }

  .vital-cell-type.active {
    font-weight: 500;
    text-decoration: underline;
    color: #143A72;
  }
  .vital-cell-type {
    padding-left: 10px;
    width: 56%;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--primary-black);
  }
  .vital-cell-input {
    width: 30%;
    text-align: center;
    color: #000000;
  }
  .vital-table tbody > tr {
    border: 1px solid #eaeaea;
  }
  .vital-table tbody > tr > td {
    border: 1px solid #eaeaea;
  }

  .vital-table tbody > tr:last-child {
    border-bottom: 1px solid #eaeaea;
  }
  .text-h-m {
    text-align: center;
  }
  .text-v-m {
    vertical-align: middle;
  }

  .vital-new-row {
    color: gray;
  }
  .submit-btn {
    position: fixed;
    bottom: 40px;
    right: 20px;
    height: 53px;
    border-radius: 60px;
    padding: 4px 15px;
    background-color: #97144D;
    color: white;
    outline: none;
    z-index: 100;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
      rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    img {
      width: 33px;
    }
  }

  .inactive-submit-btn {
    position: fixed;
    bottom: 40px;
    right: 20px;
    height: 53px;
    border-radius: 60px;
    padding: 4px 15px;
    background-color: #d8d8d8;
    color: white;
    outline: none;
    z-index: 100;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
      rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    img {
      width: 33px;
    }
  }

  .no-vital-required {
    height: 100%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    ion-spinner {
      position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;
    }
  }
  .mt-10 {
    margin-top: 10px;
  }
  input {
    outline: none;
    border: none;
  }
  input:focus {
    outline: none;
    border: none;
  }
}
.param-detail-drawer {
  height: 50%;

  .section {
    padding: 20px;
    h5 {
      font-weight: 600;
    }
    .okay-btn {
      margin-top: 30px;
    }
  }
}

.vital-share-section {
  background-color: #f0f3f8;
}
.okay-btn {
  padding: 10px 15px;
  border-radius: 26px;
  font-size: 17px;
  background-color: #97144D;
  font-weight: 600;
  color: white;
  outline: none;
}

.showInfo-div {
  position: relative;
  background-color: #143A72;
  margin-top: 0.8rem;
  width: 93%;
  margin-left: 0.8rem;
  color: white;
  border-radius: 3px;
  padding: 0.5rem 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 2;
  img {
    width: 18px;
    height: 18px;
  }
  p {
    padding-right: 1rem;
    margin: 0;
  }
}
.showInfo-div-parent {
  position: relative;
  margin-bottom: 20px;
}

.empty-rotated-div {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 2rem;
  bottom: -8px;
  transform: rotate(45deg);
  background-color: #143A72;
  z-index: 1;
}
