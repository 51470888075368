.care-team-container {
  min-height: 100vh;
  padding: 16px;
  color: #171A21;
  @media screen and (min-width: 600px) {
    padding: 20px;
  }
  .care-team-info {
    padding: 10px 0px;
    border-bottom: 1px solid gainsboro;
  }

  .care-team {
    padding: 0;
  }

  div.intro-text-care {
    padding: 0px 0px;
  }

  div.doctor-info {
    padding: 15px 10px 15px 0;
    display: flex;
    // height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid gainsboro;
    .doctor-image-container {
      max-width: 90px;

      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 55px;
        border-radius: 100%;
        object-fit: cover;
        height: 55px;
      }
    }
    .doctor-description {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 62%;
      flex: 1;
      .doc-speciality {
        font-weight: 600;
        color: var(--main-orange);
      }
      .doc-desc-para {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 13px;
        overflow-wrap: break-word;
        word-break: break-word;
      }
      .description {
        font-size: 10px;
      }
    }
  }

  img.next-doc-icon {
    height: 24px;
  }

  div.doc-list {
    max-height: calc(
      100vh - 129px - var(--ion-safe-are-bottom) - var(--ion-safe-area-top)
    );
    overflow: auto;
  }
  div.back-button-wrapper {
    width: 50px;
    height: 40px;
    padding: 0px;
    img.back-arrow {
      padding-left: 10px;
      margin-top: 10px;
      height: 18px;
    }
  }

  div.back-button {
      width: 30px;
      height: 40px;
      padding: 0px;
      img.back-arrow {
        padding-left: 10px;
        margin-top: 10px;
        height: 18px;
      }
    
  }
  .care_team_link_button {
    background-color: transparent;
    outline: none;
    border: none;
    color: #97144D;
    font-weight: 700;
  }
}
.current-doctor-content {
  div.current-doc-details {
    text-align: center;
    .doc-speciality {
      color: var(--main-orange);
      font-weight: 600;
    }
    div.current-doc-img {
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 140px;
        width: 140px;
        border-radius: 100%;
        object-fit: cover;
      }
    }
    div.current-doc-description {
      padding: 10px;
      color: #464646;
      line-height: 20px;
      text-align: center;
    }
  }
  div.back-button-wrapper {
    width: 50px;
    padding: 10px;
    margin-left: 5px;
    img.back-arrow {
      height: 20px;
    }
  }
}

.doctor_code_msg {
  display: flex;
  justify-content: left;
  align-items: center;

  img {
    width: 20px;
    margin-right: 5px;
  }
}

.blue-cta {
  color: #143A72;
}
.document-choose-upload-class {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  h3 {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 4px;
  }
}
