:root {
  --main-text-light: #464646;
  --secondary-color: #143A72;
  --grey-border: #d8d8d8;
  --error-red: #d32f2f;
  --main-cornflower: #143a72;
  --main-orange: #97144D;
  // --main-orange: #97144D;
  --main-white: #fff;
  --primary-violet: #872af3;
  --secondary-violet: #69a4ff;
  --grey-shade: #f1f1fe;
  --grey-light: #777777;
  --text-border: #e6e6e6;
  --main-black: black;
  --secondary-grey: #f6f6f6;
  --main-red: #d0021b;
  --main-green: #7ac602;
  --primary-black: #171a21;
}
:host(.item-interactive.item-has-focus) {
  --highlight-background: var(--main-orange);
}
