.payment-loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .payment-loader-container-progress {
    width: 50px !important;
    height: 50px !important;
    display: flex !important;

    svg {
      circle {
        stroke-width: 3;
        stroke: #6a5ef5;
      }
    }
  }
  .payment-loader-container-cross {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .payment-loader-container-text {
    color: #26284d;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 160px;
    .payment-loader-container-text-timer {
      color: #e44437 !important;
    }
  }
}
.payment-loader-alert-class {
  font-size: 12px;
  font-weight: 500;
  color: #26284d;
  .alert-button-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
    justify-items: center;
    button {
      flex: none;
      border: none;
      width: fit-content;
      height: fit-content;

      font-size: 12px;
      font-weight: 600;
      border-radius: 8px;
      min-width: fit-content;
    }
  }
  .payment-loader-alert-cancel {
    background-color: #ffe3e3;
    margin-right: 16px;
    color: #e44437;
    padding: 6px 8px;
  }
  .payment-loader-alert-wait {
    background-color: #f1f3f5;
    padding: 6px 16px;
    color: #777777;
  }
}
