* {
  font-family: montserrat;
}
.login-container {
  display: flex;
  flex-direction: column;
  // height: 100%;
  // display: flex;
  // height: 100%;
  // flex-direction: column;
  // bottom: 0;
  // height: calc(100vh - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));

  .login-info-carousel {
    flex: 1;
    height: calc(100% - 170px - var(--ion-safe-area-bottom));
    // padding: 0px 10px;

    .swiper-container {
      height: 100%;
    }
  }

  img.sugar-img {
    height: 260px;
  }

  .login-form {
    z-index: 100;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
    height: 170px;

    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;

    h4 {
      margin-top: 10px;
    }

    .login-loading-wrapper {
      width: 50px;
      margin: auto;
      margin-top: 30px;

      .MuiCircularProgress-colorPrimary {
        color: white;
      }
    }
  }

  // .login-form-title {
  //   text-align: center;
  //   color: white;
  // }

  // .form {
  //   margin-top: 20px;
  // }

  .input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 40px;
    font-size: 20px;
    background-color: white;
    padding: 0px 5px 0px 2px;
    line-height: 1.7;
    font-weight: 100;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);

    .country-code {
      padding: 20px 0px 20px 10px;
      outline: none;
      border: none;
      background: white;
      min-width: 35%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      img {
        max-width: 24px;
      }
      div {
        width: 1px;
        top: 0px;
        bottom: 50%;
        left: 0px;
        background-color: #d8d8d8;
        height: 28px;
        overflow: hidden;
      }
    }

    // input {
    //   outline: none;
    //   height: 43px;
    //   width: 100%;
    // }

    img.next-btn-login {
      height: 32px;
      width: 32px;
    }

    #active {
      display: none;
    }
  }

  .bottom-text {
    // color: white;
    font-size: 11px;
    padding-top: 10px;
    font-weight: 600;
    button {
      background: none;
      color: white;
      padding: 2px 0;
      outline: none;
      .text-underline {
        text-decoration: underline;
      }
    }
  }

  .otp-input-wrapper {
    margin-left: 0.13rem;
    font-size: 14px;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.13);

    width: 100%;

   
    padding-right: 5px;

    background-color: white;

    input {
      outline: none;
      padding-left: 0px;
      width: 80%;
    

      background-color: white;
      font-weight: 400;
    
    }

    button {
      background-color: #97144D;
      color: white;
      border-radius: 30px;
      border: none;
      margin-left: 7px;
      outline: none;
      font-size: 15px;

      right: 24px;
      font-weight: 600;
      height: 36px;
      z-index: 2;
      margin-top: 7.5px;
      padding-left: 15px;
      padding-right: 15px;
      &:disabled {
        background-color: #d8d8d8;
      }
    }
  }

  .otp-container main {
    .alert-danger {
      label {
        color: red;
      }
    }

    .desclaimer {
      font-size: 7px;

      margin-top: 12px;
      color: white;

      a {
        text-decoration: none;
        color: white;
      }
    }
    .promotional-consent-otp {
      padding-top: 8px;
      margin-left: -2px;
      .promotional-consent-checkbox {
        padding: 0;
        .MuiIconButton-label {
          .MuiSvgIcon-root {
            color: white !important;
          }
          .PrivateSwitchBase-input-4 {
            padding: 0;
          }
        }
      }
      .promotional-consent-msg {
        color: white;
        font-size: 12px;
        font-weight: 400;
        padding-left: 8px;
      }
    }
  }

  .color-white {
    color: white;
  }

  .otp-header {
    display: inline-flex;

    img {
      height: 16px;
      cursor: pointer;
    }
  }

  .otp-header-title {
    margin-left: 16px;
    font-weight: 600;
    font-size: 16px;
    color: #26284d;
    line-height: 22.4px;
  }

  .back-button {
    padding-top: 2px;
    font-size: 20px;
  }

  .alert {
    padding: 1px !important;
  }

  .alert > label {
    font-weight: normal;
  }

  .alert-success {
    background-color: green;
    color: white;
  }

  .alert-error {
    background-color: lightcoral;
    color: white;
  }
  .error-snackbar-div {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    span {
      color: white;
      font-size: 11px;
      padding: 4px 0;
    }
  }

  .MuiSnackbar-root {
    right: 0;
    left: 0;
    bottom: var(--ion-safe-area-bottom);
    transform: translateX(0);
    .MuiSnackbarContent-root {
      font-size: 11px !important;
      color: #fff;
      display: flex;
      padding: 0px 16px;
      flex-grow: 1;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 2px;

      .MuiSnackbarContent-message {
        padding: 4px 0;

        span#error-id {
          font-size: 11px !important;
        }
      }
    }
  }

  .error-message {
    background-color: rgb(230, 69, 69);
    .MuiSnackbarContent-root {
      background-color: rgb(230, 69, 69);
    }
  }

  .success-message {
    background-color: #51ac95;
    .MuiSnackbarContent-root {
      background-color: #51ac95;
    }
  }

  .transparent-message {
    background-color: transparent !important;
  }

  input.phone-number-login {
    outline: none;
    font-size: 5px;
  }
  .phone-number-login {
    input::placeholder {
      font-size: 1.25rem;
    }
  }
  .login-form-parent {
    height: 170px;
    position: relative;
    max-height: 170px;
    min-height: 170px;
    // background-color: #6a5ef5;
  }
}

.selection-modal {
  --max-height: 55%;
  --max-width: 75%;
  --border-radius: 16px;
  .modal-wrapper {
    .ion-page {
      justify-content: space-evenly;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }
}
#select-country {
  font-size: 20px;
  font-weight: 600;
  padding: 12px 24px;
  padding-bottom: 0;
  margin: 0;
}
.country-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  border: none;

  background-color: white;
  font-size: 18px;
  padding: 12px 24px;
  div {
    display: flex;
    align-items: center;
    img {
      width: 36px;
      height: 24px;
      margin-right: 0.5rem;
    }
  }
  span {
  }
}

.margin-left-13 {
  margin-left: 0.13rem;
}

.ion-alert-login {
  .alert-wrapper  {
    width: 90% !important;
    max-width: 90%;
    .alert-message {
      color: var(--primary-black);
    }
  }
  
  .no_data_alert_ok {
    color: #143A72;
  }
 
}
