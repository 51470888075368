$screen-sm-min: 576px;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
ion-content.content-background {
  background-color: white;
  // --background: url("../../assets/images/bg_pattern.png");
  ion-slides.testimonial-slides {
    height: 50%;
    span,
    p,
    h1 {
      color: #464646;
    }
    ion-slide {
      div {
        height: 100%;
        padding: 0 10% 10%;
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        align-items: center;
        @media screen and (max-height: $screen-sm-min) {
          padding: 0 10%;
        }
        span {
          font-weight: 500;
          text-align: center;
        }
        img.patient-picture {
          display: flex;
          height: 5rem;
          width: 5rem;
          border-radius: 50%;
          border: 1px solid #97144D;
          object-fit: cover;
        }
        p {
          font-weight: normal;
          line-height: 24px;
          color: #777777;
          font-weight: 500;
        }
      }
    }
  }

  #testimonial-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 3.375rem;
    height: 50%;
    text-align: center;
    background-color: white;
    span,
    p,
    h1 {
      color: #464646;
    }
    h1 {
      font-size: 2rem;
    }
    .ion-spinner {
      --color: #143A72;
      height: 100px;
      width: 100px;
    }

    #divider {
      margin: 0 auto;
      background: #d8d8d8;
      border-radius: 1.5px;
      height: 2px;
      width: 2rem;
    }
  }
}
